import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
//
import { useProvided } from '../../stores/index';
import useAssetTheme from '../../stores/useAssetTheme';

const ThreeLayerMenuItem = props => {
  //  1(layer1) --> n(layer2) --> m(layer3)
  //路由長相 /app/:menu/:page/:pvId
  const { solaTheme } = useProvided(useAssetTheme);
  const {
    menu,
    routeMenu,
    routePage,
    routePvId,
    panel,
    onHandlePanel,
    //secondPanel,
    onHandleSecondPanel
  } = props;

  const menuExpandedLayer2Style = {
    backgroundColor: solaTheme.componentColors.MenuL2BgColor,
    borderBottom: `1px solid ${solaTheme.componentColors.MenuL2HrColor}`
  };
  const menu1Style = {
    txColor: solaTheme.componentColors.MenuL1TxColor,
    txSelectColor: solaTheme.componentColors.MenuL1TxSelectColor,
    txHoverColor: solaTheme.componentColors.MenuL1TxHoverColor
  };
  const menu2Style = {
    txColor: solaTheme.componentColors.MenuL2TxColor,
    txSelectColor: solaTheme.componentColors.MenuL2TxSelectColor,
    txHoverColor: solaTheme.componentColors.MenuL2TxHoverColor
  };
  const menu3Style = {
    bgColor: solaTheme.componentColors.MenuL3BgColor,
    bgHoverColor: solaTheme.componentColors.MenuL3BgHoverColor,
    txColor: solaTheme.componentColors.MenuL3TxColor,
    txSelectColor: solaTheme.componentColors.MenuL3TxSelectColor,
    txHoverColor: solaTheme.componentColors.MenuL3TxHoverColor,
    hrColor: solaTheme.componentColors.MenuL3HrColor
  };
  const menuCountStyle = {
    bgColor: '',
    bgSelectColor: solaTheme.componentColors.MenuCountBgSelectColor,
    txColor: solaTheme.componentColors.MenuCountTxColor,
    txSelectColor: solaTheme.componentColors.MenuCountTxSelectColor
  };

  //control layer1
  const isExpanded = panel === menu.menu;
  const isMenuSelected = routeMenu === menu.menu;

  return (
    <ExpansionPanel expanded={isExpanded} onChange={onHandlePanel(menu.menu)}>
      <ExpansionPanelSummary>
        <MenuL1Container selected={isMenuSelected} styles={menu1Style}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <MenuIcon>{menu.icon}</MenuIcon>
            <MenuName>{menu.name}</MenuName>
          </div>
          <MenuL1ExpandIcon>
            {isExpanded ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
          </MenuL1ExpandIcon>
        </MenuL1Container>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        {menu.submenu.map((item, index) => {
          //control layer2
          const isSecondExpanded = true; //secondPanel === item.menu;
          const isPageSelected = routePage === item.menu || routePage === item.page;
          //
          if (item.submenu.length === 0 && item.path !== '') {
            return (
              <ExpansionPanel key={index} expanded={false} onChange={onHandleSecondPanel('')}>
                <ExpansionPanelSummary style={menuExpandedLayer2Style}>
                  <Link to={item.path} style={MenuLinkStyle}>
                    <MenuL2Container selected={isPageSelected} styles={menu2Style}>
                      <MenuItemIcon>{item.icon}</MenuItemIcon>
                      <MenuItemName>{item.name}</MenuItemName>
                    </MenuL2Container>
                  </Link>
                </ExpansionPanelSummary>
              </ExpansionPanel>
            );
          } else {
            return (
              <ExpansionPanel
                key={index}
                expanded={isSecondExpanded}
                onChange={onHandleSecondPanel(item.menu)}
              >
                <ExpansionPanelSummary style={menuExpandedLayer2Style}>
                  <MenuL2Container selected={isPageSelected} styles={menu2Style}>
                    <div style={{ display: 'flex', alignItems: 'center', ...MenuLinkStyle }}>
                      <MenuItemIcon>{item.icon}</MenuItemIcon>
                      <MenuItemName>{item.name}</MenuItemName>
                    </div>
                    <MenuCount selected={isPageSelected} styles={menuCountStyle}>
                      {item.submenu.length}
                    </MenuCount>
                  </MenuL2Container>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  {item.submenu.map((pv, subIndex) => {
                    //control layer3
                    const isPvSelected = routePvId === pv.page;
                    //
                    return (
                      <Link key={subIndex} to={pv.path} style={MenuLinkStyle}>
                        <MenuL3Container selected={isPvSelected} styles={menu3Style}>
                          <MenuItemIcon>{pv.icon}</MenuItemIcon>
                          <MenuItemName>{pv.name}</MenuItemName>
                        </MenuL3Container>
                      </Link>
                    );
                  })}
                </ExpansionPanelDetails>
              </ExpansionPanel>
            );
          }
        })}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

const MenuLinkStyle = {
  width: '100%'
};

const MenuL1Container = styled.div`
  width: 100%;
  height: 70px;
  color: ${p => (p.selected ? p.styles.txSelectColor : p.styles.txColor)};

  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-left: 0px;
  padding-right: 10px;
  &:hover {
    color: ${p => p.styles.txHoverColor};
  }
  &:hover .svgHover {
    fill: ${p => p.styles.txHoverColor};
  }
  .svgSelect {
    fill: ${p => (p.selected ? p.styles.txSelectColor : p.styles.txColor)};
  }
`;
const MenuL1ExpandIcon = styled.div`
  padding-top: 5px;
`;
const MenuL2Container = styled.div`
  width: 75%;
  height: 45px;
  margin-left: 12px;
  padding-top: 6px;

  display: flex;
  align-items: center;

  color: ${p => (p.selected ? p.styles.txSelectColor : p.styles.txColor)};
  &:hover {
    color: ${p => p.styles.txHoverColor};
  }
  &:hover .svgHover {
    fill: ${p => p.styles.txHoverColor};
  }
  .svgSelect {
    fill: ${p => (p.selected ? p.styles.txSelectColor : p.styles.txColor)};
  }
`;
const MenuL3Container = styled.div`
  display: flex;
  align-items: center;
  width: 85%;
  height: 45px;
  padding: 3% 0% 3% 15%;
  border-bottom: 1px solid ${p => p.styles.hrColor};
  color: ${p => (p.selected ? p.styles.txSelectColor : p.styles.txColor)};

  background-color: ${p => p.styles.bgColor};
  &:hover {
    background-color: ${p => p.styles.bgHoverColor};
    color: ${p => p.styles.txHoverColor};
  }
  .svgSelect {
    fill: ${p => (p.selected ? p.styles.txSelectColor : p.styles.txColor)};
  }
`;

const MenuIcon = styled.div`
  width: 15px;
  height: 15px;
  margin-right: 10px;
`;
const MenuName = styled.div`
  font-size: 17px;
`;
const MenuCount = styled.div`
  width: 36px;
  height: 17px;
  font-size: 15px;
  line-height: 17px;
  border-radius: 100px;
  color: ${p => (p.selected ? p.styles.txSelectColor : p.styles.txColor)};
  background-color: ${p => (p.selected ? p.styles.bgSelectColor : p.styles.bgColor)};
  text-align: center;
`;
const MenuItemIcon = styled.div`
  width: 15px;
  height: 15px;
  margin-right: 10px;
`;
const MenuItemName = styled.span`
  font-size: 14px;
`;

export default ThreeLayerMenuItem;
