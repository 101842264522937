import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import TitleContainer from '../Title/TitleContainer';
import TitleWithIcon from '../Title/TitleWithIcon';
import EmptyMsg from '../EmptyMsg';
import { ReactComponent as DocIcon } from '../../images/svg/picture.svg';
//
import { useProvided } from '../../stores/index';
import useAssetTheme from '../../stores/useAssetTheme';

const PvImage = ({ pvImgs }) => {
  const { solaTheme } = useProvided(useAssetTheme);
  const [currentIndx, setCurrentIndex] = useState(0);

  const colors = {
    border: solaTheme.componentColors.PvImageSelectBorderColor
  };

  const handleSubImgClick = index => () => {
    if (currentIndx !== index) {
      setCurrentIndex(index);
    }
  };

  useEffect(() => {
    setCurrentIndex(0);
  }, [pvImgs]);

  //setting sub imgs & view
  let viewDOM = null;
  let subImgsDOM = [];
  if (pvImgs && pvImgs.length > 0) {
    for (let i = 0; i < pvImgs.length; i++) {
      subImgsDOM.push(
        <SubImg
          key={i}
          alt="PV View"
          src={pvImgs[i]}
          className={i === currentIndx ? 'subImg-select' : ''}
          onClick={handleSubImgClick(i)}
        />
      );
    }

    viewDOM = (
      <ViewContainer>
        <MainView>
          <MainImg src={pvImgs[currentIndx]} alt="PV View" />
        </MainView>
        <OtherView colors={colors}>{subImgsDOM}</OtherView>
      </ViewContainer>
    );
  } else {
    viewDOM = <EmptyMsg>建置中</EmptyMsg>;
  }

  return (
    <div>
      <TitleContainer hideTop>
        <TitleWithIcon title="電廠實景" prefixIcon={DocIcon} />
      </TitleContainer>
      <ImgContainer>{viewDOM}</ImgContainer>
    </div>
  );
};

const ImgContainer = styled.div`
  padding: 20px;
`;
const ViewContainer = styled.div`
  width: 100%;
  height: 350px;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;
const MainView = styled.div`
  width: 75%;
  max-width: 465px;
  text-align: center;
`;
const OtherView = styled.div`
  max-width: 25%;
  height: 350px;
  display: flex;
  flex-direction: column
  justify-content: flex-start;
  overflow-y: auto;

  .subImg-select{
    border: 3px solid ${p => p.colors.border};
  }

`;
const MainImg = styled.img`
  max-width: 100%;
  max-height: 350px;
`;
const SubImg = styled.img`
  max-height: 33%;
  max-width: 100%;
  padding-bottom: 5px;
`;

export default PvImage;
