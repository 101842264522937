import React from 'react';
import styled from 'styled-components';
//
import { useProvided } from '../../stores/index';
import useAssetTheme from '../../stores/useAssetTheme';

const BulletinList = ({ bulletins, totalCount, pageSize, pageNumber, switchPageFn }) => {
  const { solaTheme } = useProvided(useAssetTheme);

  const contentStyle = {
    emptyColor: solaTheme.componentColors.DBLEmptyTxColor,
    hrColor: solaTheme.componentColors.DBLHrColor,
    txColor: solaTheme.componentColors.DBLTxColor
  };

  const paggingStyle = {
    pgColor: solaTheme.componentColors.DBLPgColor,
    pgSelectColor: solaTheme.componentColors.DBLPgSelectColor
  };

  //draw bulletin list.
  let bulletinDOM = null;
  if (bulletins && bulletins.length > 0) {
    bulletinDOM = [];
    bulletins.forEach((b, index) => {
      bulletinDOM.push(
        <Row key={index} className="row">
          <LeftColumn>
            <a href={b.url} target="_blank" rel="noopener noreferrer">
              {b.title}
            </a>
          </LeftColumn>
          <RightColumn>{b.date}</RightColumn>
        </Row>
      );
    });
  } else {
    bulletinDOM = <EmptyPanel className="empty">暫無公告資料</EmptyPanel>;
  }

  //draw pagging dot.
  let dotDOM = null;
  if (totalCount && totalCount > 0) {
    const dotNumber = totalCount % pageSize === 0 ? totalCount / pageSize : totalCount / pageSize + 1;
    dotDOM = [];
    for (let i = 1; i <= dotNumber; i++) {
      if (i === pageNumber) {
        dotDOM.push(<YellowDot key={i} className="yellowDot" />);
      } else {
        dotDOM.push(<GrayDot key={i} className="grayDot" onClick={switchPageFn(i)} />);
      }
    }
  } else {
    dotDOM = <YellowDot className="yellowDot" />;
  }

  return (
    <TwoColumnContainer>
      <ContentRow styles={contentStyle}>{bulletinDOM}</ContentRow>
      <PageRow styles={paggingStyle}>{dotDOM}</PageRow>
    </TwoColumnContainer>
  );
};

const TwoColumnContainer = styled.div`
  width: 100%;
  height: 420px;
`;
const ContentRow = styled.div`
  width: 100%;
  min-height: 370px;
  .empty {
    color: ${p => p.styles.emptyColor};
  }
  .row {
    border-bottom: 1px solid ${p => p.styles.hrColor};
    color: ${p => p.styles.txColor};
  }
`;
const EmptyPanel = styled.div`
  font-size: 20px;
  padding-left: 20px;
  padding-top: 20px;
`;
const Row = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;
const LeftColumn = styled.div`
  width: 70%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
const RightColumn = styled.div`
  width: 18%;
`;
const PageRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;

  .yellowDot {
    background-color: ${p => p.styles.pgSelectColor};
  }
  .grayDot {
    background-color: ${p => p.styles.pgColor};
  }
`;
const YellowDot = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 7px;
  margin: 10px;
  cursor: pointer;
`;
const GrayDot = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 7px;
  margin: 10px;
  cursor: pointer;
`;

export default BulletinList;
