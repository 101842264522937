import React, { useState, useCallback, useEffect } from 'react';
import axios from 'axios';
import bn from 'bignumber.js';
import styled from 'styled-components';
import moment from 'moment';

//common
import { NumberFormat, IntegerFormat, DisplayFormat } from '../../commons/utils';
//component
import TitleContainer from '../../components/Title/TitleContainer';
import TitleWithIcon from '../../components/Title/TitleWithIcon';
import JTable from '../../components/Table/JTable';
import BarChart from '../../components/Chart/BarChart';
import PieChart from '../../components/Chart/PieChart';
import ChartTooltip from '../../components/Tooltip/ChartTooltip';
//icon
import { ReactComponent as CoinsIcon } from '../../images/svg/coins.svg';
import { ReactComponent as GraphicIcon } from '../../images/svg/graphic.svg';
import { ReactComponent as PieChart2Icon } from '../../images/svg/pie_chart_v2.svg';
//
import { useProvided } from '../../stores/index';
import useAuth from '../../stores/useAuth';
import useAssetTheme from '../../stores/useAssetTheme';

const tableColumns = [
  {
    title: '電廠',
    field: 'pvId',
    render: row => (
      <span>
        {row.pvId && row.pvId + '-'}
        {row.pvName}
      </span>
    ),
    cellStyle: {
      width: '200px'
    }
  },
  {
    title: '發電起始日',
    field: 'validityDateBegin',
    cellStyle: {
      width: '60px'
    }
  },
  {
    title: '裝置總容量(kWp)',
    field: 'capacity',
    render: row => <span>{DisplayFormat(row.capacity, '', '')}</span>,
    cellStyle: {
      width: '120px'
    }
  },
  {
    title: '總發行量(個)',
    field: 'totalAmount',
    render: row => <span>{DisplayFormat(row.totalAmount, '', '')}</span>,
    cellStyle: {
      width: '100px'
    }
  },
  {
    title: '持有量(個)',
    field: 'amount',
    render: row => <span>{DisplayFormat(row.amount, '', '')}</span>,
    cellStyle: {
      width: '100px'
    }
  },
  {
    title: '價格(元)',
    field: 'price',
    render: row => <span>{DisplayFormat(row.price, '', '')}</span>,
    cellStyle: {
      width: '100px'
    }
  },
  {
    title: '現值(元)',
    field: 'nowValue',
    render: row => <span>{NumberFormat(row.nowValue, '', '')}</span>,
    cellStyle: {
      width: '100px'
    }
  }
];
const RangeBegin = moment()
  .subtract(1, 'year')
  .add(1, 'month')
  .format('YYYY-MM-01');
const RangeEnd = moment()
  .add(1, 'month')
  .format('YYYY-MM-01');

const CancelFnList = [];
const UserAssets = () => {
  const { currentSPV } = useProvided(useAuth);
  const { solaTheme } = useProvided(useAssetTheme);
  const [assetsData, setAssetsData] = useState([]);
  const [ownerRatioData, setOwnerRatioData] = useState([]);
  const [profitRatioData, setProfitRatioData] = useState([]);
  const [barData, setBarData] = useState([]);
  const [barKeys, setBarKeys] = useState([]);

  const loadAssetData = useCallback(() => {
    const source = axios.CancelToken.source();
    CancelFnList.push(source.cancel);
    axios
      .get(`/api/v1/assets`, {
        headers: { Authorization: currentSPV.token },
        cancelToken: source.token
      })
      .then(res => {
        if (res && res.data) {
          setAssetsData([...res.data.result.assets]);
        }
      })
      .catch(err => {
        if (!axios.isCancel(err)) {
          console.error(err);
        }
      });
  }, [currentSPV]);

  const loadDistributionData = useCallback(() => {
    const source = axios.CancelToken.source();
    CancelFnList.push(source.cancel);
    axios
      .get(`/api/v1/assets/distribution?begin=2016-01-01&end=${RangeEnd}`, {
        headers: { Authorization: currentSPV.token },
        cancelToken: source.token
      })
      .then(res => {
        if (res && res.data) {
          //
          const distData = res.data.result.distribution;
          //
          const tempOwnerRatioData = [];
          const tempProfitRatioData = [];
          distData.forEach(item => {
            const pvTitle = `${item.pvId}  ${item.pvName}`;
            //
            tempOwnerRatioData.push({
              id: item.pvId,
              name: item.pvName,
              label: pvTitle,
              value: item.investRatio
                ? bn(item.investRatio)
                    .multipliedBy(100)
                    .dp(0, bn.ROUND_HALF_CEIL)
                    .toNumber()
                : 0,
              number: item.invest,
              numberTotal: item.investTotal
            });

            //
            tempProfitRatioData.push({
              id: item.pvId,
              name: item.pvName,
              label: pvTitle,
              value: item.profitRatio
                ? bn(item.profitRatio)
                    .multipliedBy(100)
                    .dp(0, bn.ROUND_HALF_CEIL)
                    .toNumber()
                : 0,
              number: item.profit,
              numberTotal: item.profitTotal
            });
          });

          //sort data.
          tempOwnerRatioData.sort((a, b) => {
            if (a.value > b.value) {
              return -1;
            } else {
              return 1;
            }
          });
          tempProfitRatioData.sort((a, b) => {
            if (a.value > b.value) {
              return -1;
            } else {
              return 1;
            }
          });

          setOwnerRatioData(tempOwnerRatioData);
          setProfitRatioData(tempProfitRatioData);
        }
      })
      .catch(err => {
        if (!axios.isCancel(err)) {
          console.error(err);
        }
      });
  }, [currentSPV]);

  const loadProfitByMonthData = useCallback(() => {
    const source = axios.CancelToken.source();
    CancelFnList.push(source.cancel);
    axios
      .get(`/api/v1/assets/profitByMonth?begin=${RangeBegin}&end=${RangeEnd}`, {
        headers: { Authorization: currentSPV.token },
        cancelToken: source.token
      })
      .then(res => {
        if (res && res.data) {
          //
          const profitRealData = res.data.result.profitReal;
          const profitUnrealData = res.data.result.profitUnreal;
          //
          const barData = [];
          let barKeys = [];
          const tempBarData = {};
          const tempBarKeys = {};
          profitRealData.forEach(v => {
            const period = moment(v.period).format('YYYY-MM-DD');

            //for bar chart use.
            tempBarKeys[v.pvName] = true;
            if (!tempBarData[period]) {
              tempBarData[period] = { peroid: period };
            }
            tempBarData[period][v.pvName] = bn(v.profit).toNumber();
          });

          profitUnrealData.forEach(v => {
            if (!tempBarData[v.period]) {
              tempBarData[v.period] = { peroid: v.period };
            }
            tempBarData[v.period]['未實現收益'] = bn(v.profit).toNumber();
          });

          //re-compose data and keys for bar chart.
          Object.keys(tempBarData).forEach(key => {
            barData.push(tempBarData[key]);
          });
          barKeys = Object.keys(tempBarKeys);
          barKeys.push('未實現收益');

          setBarData(barData);
          setBarKeys(barKeys);
        }
      })
      .catch(err => {
        if (!axios.isCancel(err)) {
          console.error(err);
        }
      });
  }, [currentSPV]);

  useEffect(() => {
    loadAssetData();
    loadDistributionData();
    loadProfitByMonthData();

    return () => {
      if (CancelFnList && CancelFnList.length > 0) {
        CancelFnList.forEach(fn => {
          fn();
        });
      }
    };
  }, [loadAssetData, loadDistributionData, loadProfitByMonthData]);

  return (
    <div>
      <div>
        <TitleContainer>
          <TitleWithIcon title="您的資產清單" prefixIcon={CoinsIcon} />
        </TitleContainer>
        <TablePanel>
          <JTable columns={tableColumns} data={assetsData} scrollY={false} />
        </TablePanel>
      </div>
      <div>
        <PanelTwoColumnInfo>
          <PanelTwoColumnInfoInner>
            <TitleContainer hideTop>
              <TitleWithIcon title="您的資產分布" prefixIcon={PieChart2Icon} />
            </TitleContainer>
            <ChartContainer>
              <PieChart tipTitle="資產占比" data={ownerRatioData} />
            </ChartContainer>
          </PanelTwoColumnInfoInner>
          <PanelTwoColumnInfoInner>
            <TitleContainer hideTop>
              <TitleWithIcon title="收益分布" prefixIcon={PieChart2Icon} />
            </TitleContainer>
            <ChartContainer>
              <PieChart tipTitle="收益占比" data={profitRatioData} />
            </ChartContainer>
          </PanelTwoColumnInfoInner>
        </PanelTwoColumnInfo>
      </div>
      <div>
        <TitleContainer>
          <TitleWithIcon title="近一年累積收益" prefixIcon={GraphicIcon} />
        </TitleContainer>
        <div>
          {barData && barData.length > 0 ? (
            <ChartContainer>
              <BarChart
                data={barData}
                keys={barKeys}
                indexBy="peroid"
                axisLeft={{
                  tickSize: 0,
                  format: e => NumberFormat(e, '$', '')
                }}
                enableLabel={true}
                labelFormat={v => {
                  if (v > 12000) {
                    return IntegerFormat(v, '$', '');
                  } else {
                    return '';
                  }
                }}
                labelTextColor={solaTheme.componentColors.UABarChartTxColor}
                tooltip={e => (
                  <ChartTooltip
                    width="180px"
                    iconColor={e.color}
                    header={e.id}
                    contents={[{ title: '', value: IntegerFormat(e.value), unit: '元' }]}
                  />
                )}
              />
            </ChartContainer>
          ) : null}
        </div>
      </div>
    </div>
  );
};

const PanelTwoColumnInfo = styled.div`
  width: 100%;
  height: ${props => props.height || '500px'};
  display: inline-flex;
`;
const PanelTwoColumnInfoInner = styled.div`
  width: ${props => props.width || '50%'};
  height: ${props => props.height || '500px'};
`;
const TablePanel = styled.div`
  margin-bottom: 10px;
`;
const ChartContainer = styled.div`
  width: 100%;
  height: 450px;
`;

export default UserAssets;
