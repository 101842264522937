import React from 'react';
import Typography from '@material-ui/core/Typography';

function InnerError() {
  return (
    <div>
      <Typography variant="h1" color="primary">
        404
      </Typography>
      <Typography variant="h5" color="primary">
        喔喔！您所瀏覽的頁面似乎已不存在。
      </Typography>
    </div>
  );
}

export default InnerError;
