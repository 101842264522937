import React from 'react';
import axios from 'axios';
import styled from 'styled-components';
//
import { useProvided } from '../../stores/index';
import useAuth from '../../stores/useAuth';
import useAssetTheme from '../../stores/useAssetTheme';

const ChangeSpv = () => {
  const { currentSPV, changeCurrentSPV } = useProvided(useAuth);
  const { solaTheme } = useProvided(useAssetTheme);
  const { userId, userList, managerEmail } = currentSPV;

  const handleSpvClick = newUserId => () => {
    if (newUserId !== userId) {
      axios
        .post(
          '/api/v1/auth/selectUser',
          { userId: newUserId },
          { headers: { Authorization: currentSPV.token } }
        )
        .then(res => {
          if (res && res.data) {
            const { manager, users, currentUser, token } = res.data.result;

            //
            const tUserId = currentUser;
            const tUserName = users.find(u => u.id === tUserId).name;
            const tUserList = users;
            const tManagerId = manager;
            const tManagerEmail = managerEmail;
            const tToken = `Bearer ${token}`;

            //use this when user refresh page, but do not logout system.
            window.sessionStorage.setItem('token', tToken);
            window.sessionStorage.setItem('userId', tUserId);
            window.sessionStorage.setItem('userName', tUserName);
            window.sessionStorage.setItem('managerId', tManagerId);
            window.sessionStorage.setItem('managerEmail', tManagerEmail);
            window.sessionStorage.setItem('userList', JSON.stringify(tUserList));

            //global variable update.
            changeCurrentSPV({
              userId: tUserId,
              userName: tUserName,
              userList: tUserList,
              managerId: tManagerId,
              managerEmail: tManagerEmail,
              token: tToken
            });
          }
        })
        .catch(err => {
          console.error(err);
        });
    }
  };

  const colors = {
    title: solaTheme.componentColors.CSTitleTxColor,
    itemBg: solaTheme.componentColors.CSItemBgColor,
    itemTx: solaTheme.componentColors.CSItemTxColor,
    itemSelectBg: solaTheme.componentColors.CSItemSelectBgColor,
    itemSelectTx: solaTheme.componentColors.CSItemSelectTxColor
  };

  return (
    <MainContainer colors={colors}>
      <Title className="title">請點擊您要切換至的 SPV 公司</Title>
      <UserPanel>
        {userList && userList.length > 0
          ? userList.map((user, index) => {
              const currentId = typeof userId === 'string' ? parseInt(userId, 10) : userId;
              const isCurrent = user.id === currentId;
              return (
                <UserItem
                  key={index}
                  className={isCurrent ? 'item-select' : 'item'}
                  onClick={handleSpvClick(user.id)}
                >
                  <div>{user.name}</div>
                  <div>{user.role}</div>
                </UserItem>
              );
            })
          : null}
      </UserPanel>
    </MainContainer>
  );
};

const MainContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding-top: 30px;

  .title {
    color: ${p => p.colors.title};
  }
  .item {
    background-color: ${p => p.colors.itemBg};
    color: ${p => p.colors.itemTx};
  }
  .item-select {
    background-color: ${p => p.colors.itemSelectBg};
    color: ${p => p.colors.itemSelectTx};
  }
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: 600;
`;

const UserPanel = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const UserItem = styled.div`
  width: 150px;
  height: 100px;
  margin: 30px;
  border-radius: 20px;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export default ChangeSpv;
