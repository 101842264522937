import React from 'react';
import { ResponsiveLine } from '@nivo/line';
import { useProvided } from '../../../stores/index';
import useAssetTheme from '../../../stores/useAssetTheme';

const LineChart = ({ data, ...restProps }) => {
  const { solaTheme } = useProvided(useAssetTheme);

  return (
    <ResponsiveLine
      data={data}
      colors={{ scheme: 'nivo' }}
      theme={solaTheme.nivoTheme}
      margin={{ top: 50, right: 150, bottom: 50, left: 90 }}
      animate={false}
      xScale={{ type: 'point' }}
      yScale={{ type: 'linear', stacked: false, min: 'auto', max: 'auto' }}
      legends={[
        {
          anchor: 'bottom-right',
          direction: 'column',
          justify: false,
          translateX: 120,
          translateY: 0,
          itemsSpacing: 2,
          itemWidth: 100,
          itemHeight: 25,
          itemDirection: 'left-to-right',
          itemOpacity: 0.85,
          itemTextColor: solaTheme.nivoTheme.legends.text.fill,
          symbolSize: 20
        }
      ]}
      {...restProps}
    />
  );
};

export default LineChart;
