import React from 'react';
import styled from 'styled-components';
//
import { useProvided } from '../../../stores/index';
import useAssetTheme from '../../../stores/useAssetTheme';

const Pagination = ({ props, pageRows }) => {
  const { solaTheme } = useProvided(useAssetTheme);
  const { count, page, rowsPerPage, onChangePage, onChangeRowsPerPage } = props;

  const handlePageChange = index => e => {
    onChangePage(e, index);
  };

  const pageNumberDOM = [];
  const totalPage = Math.ceil(count / rowsPerPage);

  if (page > totalPage) {
    onChangePage(null, 0);
  }

  if (pageRows) {
    onChangeRowsPerPage({ target: { value: pageRows } });
  }

  if (totalPage === 0) {
    pageNumberDOM.push(
      <PageNumber key={1} disabled select={true} className="number-select">
        {1}
      </PageNumber>
    );
  } else {
    const overDisplay = totalPage > 10;
    const innerPageSize = 10;
    const innerPageNumber = Math.floor(page / innerPageSize);
    const innerTotalPageNumber = Math.floor(totalPage / innerPageSize);
    const innerShowMaxPageNumber =
      innerPageNumber === innerTotalPageNumber ? totalPage : (innerPageNumber + 1) * innerPageSize;

    //previous page numbers.
    if (overDisplay && innerPageNumber > 0) {
      pageNumberDOM.push(
        <PageNumber
          key="prev"
          onClick={handlePageChange(innerPageNumber * innerPageSize - 1)}
          className="number"
        >
          ...
        </PageNumber>
      );
    }

    //page numbers.
    for (let i = innerPageNumber * innerPageSize; i < innerShowMaxPageNumber; i++) {
      const isSelect = i === page;

      pageNumberDOM.push(
        <PageNumber
          key={i}
          onClick={handlePageChange(i)}
          select={isSelect}
          className={isSelect ? 'number-select' : 'number'}
        >
          {i + 1}
        </PageNumber>
      );
    }

    //next page numbers.
    if (overDisplay && innerPageNumber < innerTotalPageNumber) {
      pageNumberDOM.push(
        <PageNumber
          key="next"
          onClick={handlePageChange((innerPageNumber + 1) * innerPageSize)}
          className="number"
        >
          ...
        </PageNumber>
      );
    }
  }

  const paggingStyle = {
    btnBorderColor: solaTheme.componentColors.MTPaggingBtnBorderColor,
    btnTxColor: solaTheme.componentColors.MTPaggingBtnTxColor,
    bgColor: solaTheme.componentColors.MTPaggingBgColor,
    txColor: solaTheme.componentColors.MTPaggingTxColor,
    selectTxColor: solaTheme.componentColors.MTPaggingSelectTxColor
  };

  return (
    <PagingContainer styles={paggingStyle}>
      <PageButton disabled={page === 0} onClick={handlePageChange(page - 1)} className="btn">
        上一頁
      </PageButton>
      {pageNumberDOM}
      <PageButton
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        onClick={handlePageChange(page + 1)}
        className="btn"
      >
        下一頁
      </PageButton>
    </PagingContainer>
  );
};

const PagingContainer = styled.td`
  background-color: ${p => p.styles.bgColor};
  padding: 10px 0px;

  display: flex;
  justify-content: center;
  align-items: cetner;

  .btn {
    background-color: ${p => p.styles.bgColor};
    color: ${p => p.styles.btnTxColor};
    border: 1px solid ${p => p.styles.btnBorderColor};
  }
  .number {
    color: ${p => p.styles.txColor};
    background-color: ${p => p.styles.bgColor};
  }
  .number-select {
    color: ${p => p.styles.selectTxColor};
    background-color: ${p => p.styles.bgColor};
  }
`;
const PageNumber = styled.button`
  width: 30px;
  border: 0px;
  text-align: center;
  cursor: pointer;
  margin: 5px 10px;
`;
const PageButton = styled.button`
  width: 80px;
  border-radius: 100px;
  text-align: center;
  cursor: pointer;
  margin: 5px 20px;
`;

export default Pagination;
