import React, { useState, useCallback, useEffect } from 'react';
import axios from 'axios';
import bn from 'bignumber.js';
import styled from 'styled-components';
//common
import { IntegerFormat, PercentFormat } from '../../../commons/utils';
//component
import ChartTooltip from '../../../components/Tooltip/ChartTooltip';
import DropDownList from '../../../components/Selector/dropdownlist';
import EmptyMsg from '../../../components/EmptyMsg';
import LineChart from '../../../components/Chart/LineChart';
import TitleContainer from '../../../components/Title/TitleContainer';
import TitleWithIcon from '../../../components/Title/TitleWithIcon';
//icon
import { ReactComponent as WalletIcon } from '../../../images/svg/wallet.svg';
//
import { useProvided } from '../../../stores/index';
import useAuth from '../../../stores/useAuth';
import useAssetTheme from '../../../stores/useAssetTheme';

const CancelFnList = [];
const CumulativeIncome = () => {
  const { currentSPV } = useProvided(useAuth);
  const { solaTheme } = useProvided(useAssetTheme);
  const [searchPvId, setSearchPvId] = useState('');
  const [pvList, setPvList] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [chartDataMinValue, setChartDataMinValue] = useState(0);

  const chartColors = [
    solaTheme.componentColors.PCILineChartBillColor,
    solaTheme.componentColors.PCILineChartSimulateColor,
    solaTheme.componentColors.PCILineChartBillColor
  ];

  const handlePvIdChange = event => {
    if (event.target.value !== searchPvId) {
      setSearchPvId(event.target.value);
    }
  };

  //Data Loading.
  const loadPvList = useCallback(() => {
    const source = axios.CancelToken.source();
    CancelFnList.push(source.cancel);
    axios
      .get(`/api/v1/assets/userPv`, {
        headers: { Authorization: currentSPV.token },
        cancelToken: source.token
      })
      .then(res => {
        if (res && res.data && res.data.status) {
          const list = [];
          res.data.result.userPv.forEach(p => {
            list.push({
              key: p.pvId,
              name: p.pvName
            });
          });

          if (list.length > 0) {
            setSearchPvId(pvId => pvId || list[0].key);
          }
          setPvList(list);
        }
      })
      .catch(err => {
        if (!axios.isCancel(err)) {
          console.error(err);
        }
      });
  }, [currentSPV]);
  const loadData = useCallback(() => {
    if (searchPvId) {
      const source = axios.CancelToken.source();
      CancelFnList.push(source.cancel);
      axios
        .get(`/api/v1/assets/accumulateProfit/${searchPvId}`, {
          headers: { Authorization: currentSPV.token },
          cancelToken: source.token
        })
        .then(res => {
          if (res && res.data && res.data.status) {
            const lineData = [
              { id: 'bill', data: [] },
              { id: 'simulate', data: [] },
              { id: 'fakePoint', data: [] }
            ];
            const { invest, profit } = res.data.result;

            //data type convert
            let sum = 0;
            let minValue = profit && profit.length > 0 && profit[0].profit;
            let maxDate = '';
            let firstSimulate = true;

            for (let i = 0; i < profit.length; i++) {
              const p = profit[i];

              if (p.kind === 'bill') {
                sum = bn(sum)
                  .plus(p.profit)
                  .toNumber();

                lineData[0].data.push({
                  x: p.period,
                  y: bn(sum)
                    .dividedBy(invest)
                    .toNumber(),
                  invest: invest,
                  accumulate: sum
                });

                maxDate = p.period;

                if (minValue > sum) {
                  minValue = sum;
                }
              }

              if (p.kind === 'simulate') {
                if (firstSimulate && i > 0) {
                  let tmpItem = null;
                  if (maxDate === p.period) {
                    const tmpProfit = bn(sum)
                      .minus(profit[i - 1].profit)
                      .toNumber();
                    tmpItem = {
                      x: profit[i - 2].period,
                      y: bn(tmpProfit)
                        .dividedBy(invest)
                        .toNumber(),
                      invest: invest,
                      accumulate: tmpProfit
                    };
                  } else {
                    tmpItem = {
                      x: profit[i - 1].period,
                      y: bn(sum)
                        .dividedBy(invest)
                        .toNumber(),
                      invest: invest,
                      accumulate: sum
                    };
                  }

                  lineData[1].data.push(tmpItem);
                  lineData[2].data.push(tmpItem);
                }

                sum = bn(sum)
                  .plus(p.profit)
                  .toNumber();

                lineData[1].data.push({
                  x: p.period,
                  y: bn(sum)
                    .dividedBy(invest)
                    .toNumber(),
                  invest: invest,
                  accumulate: sum
                });

                if (minValue > sum) {
                  minValue = sum;
                }

                firstSimulate = false;
              }
            }

            minValue = bn(minValue)
              .dividedBy(invest)
              .dp(3, bn.ROUND_FLOOR)
              .toNumber();

            setChartDataMinValue(minValue);
            setChartData(lineData);
          }
        })
        .catch(err => {
          if (!axios.isCancel(err)) {
            console.error(err);
          }
        });
    }
  }, [currentSPV, searchPvId]);

  useEffect(() => {
    loadPvList();
    loadData();

    return () => {
      if (CancelFnList && CancelFnList.length > 0) {
        CancelFnList.forEach(fn => {
          fn();
        });
      }
    };
  }, [loadPvList, loadData]);

  return (
    <div>
      <TitleContainer>
        <TitleWithIcon title="累積報酬" prefixIcon={WalletIcon} />
      </TitleContainer>
      <ConditionContainer>
        <DropDownList
          label="選擇電廠"
          value={searchPvId}
          options={pvList}
          width="300px"
          changeFn={handlePvIdChange}
        />
      </ConditionContainer>
      <div>
        {chartData ? (
          <ChartContainer>
            <LineChart
              data={chartData}
              margin={{ top: 50, right: 90, bottom: 50, left: 90 }}
              axisLeft={{
                orient: 'right',
                tickSize: 5,
                tickPadding: 5,
                format: value => PercentFormat(value)
              }}
              axisBottom={{
                orient: 'bottom',
                tickSize: 5,
                tickPadding: 3,
                tickRotation: -60
              }}
              lineWidth={3}
              enableArea={true}
              areaBaselineValue={chartDataMinValue}
              enableGridX={false}
              enableSlices="x"
              sliceTooltip={({ slice }) => GenerateToolTipDom(slice)}
              colors={chartColors}
              legends={[]}
            />
          </ChartContainer>
        ) : (
          <EmptyMsg msg="查無資料" />
        )}
      </div>
    </div>
  );
};

const ConditionContainer = styled.div`
  display: flex;
  align-items: center;
`;
const ChartContainer = styled.div`
  width: 100%;
  height: 550px;

  @media (max-height: 800px) {
    height: 450px;
  }
`;

const GenerateToolTipDom = slice => {
  const date = slice.points[0].data.xFormatted;
  const invest = IntegerFormat(slice.points[0].data.invest);
  let billValue = '---';
  let billRoi = '---';
  let simulateValue = '---';
  let simulateRoi = '---';

  const billItem = slice.points.find(p => p.serieId === 'bill');
  if (billItem) {
    billValue = IntegerFormat(billItem.data.accumulate);
    billRoi = PercentFormat(billItem.data.y);
  }

  const simulateItem = slice.points.find(p => p.serieId === 'simulate');
  if (simulateItem) {
    simulateValue = IntegerFormat(simulateItem.data.accumulate);
    simulateRoi = PercentFormat(simulateItem.data.y);
  }

  return (
    <ChartTooltip
      width="200px"
      center={slice.x}
      iconColor={null}
      header={date}
      contents={[
        { title: '投資金額', value: invest, unit: '元' },
        { title: '實際累積收益', value: billValue, unit: '元' },
        { title: '實際累積報酬率', value: billRoi, unit: '' },
        { title: '推估累積收益', value: simulateValue, unit: '元' },
        { title: '推估累積報酬率', value: simulateRoi, unit: '' }
      ]}
    />
  );
};

export default CumulativeIncome;
