import React from 'react';
import styled from 'styled-components';
import { useProvided } from '../../stores/index';
import useAssetTheme from '../../stores/useAssetTheme';

const TimeFilter = ({ currentFilter, filterFn }) => {
  const { solaTheme } = useProvided(useAssetTheme);
  const filterStyle = {
    bgColor: solaTheme.componentColors.TFilterBgColor,
    txColor: solaTheme.componentColors.TFilterTxColor,
    borderColor: solaTheme.componentColors.TFilterBorderColor,
    selectBgColor: solaTheme.componentColors.TFilterSelectBgColor,
    selectTxColor: solaTheme.componentColors.TFilterSelectTxColor,
    selectBorderColor: solaTheme.componentColors.TFilterSelectBorderColor
  };
  return (
    <FilterContainer styles={filterStyle}>
      <FilterButton
        className={currentFilter === 'week' ? 'btn-select' : 'btn'}
        // select={currentFilter === 'week'}
        onClick={filterFn('week')}
      >
        週檢視
      </FilterButton>
      <FilterButton
        className={currentFilter === 'month' ? 'btn-select' : 'btn'}
        // select={currentFilter === 'month'}
        onClick={filterFn('month')}
      >
        月檢視
      </FilterButton>
      <FilterButton
        className={currentFilter === 'quarter' ? 'btn-select' : 'btn'}
        // select={currentFilter === 'quarter'}
        onClick={filterFn('quarter')}
      >
        季檢視
      </FilterButton>
      <FilterButton
        className={currentFilter === 'year' ? 'btn-select' : 'btn'}
        // select={currentFilter === 'year'}
        onClick={filterFn('year')}
      >
        年檢視
      </FilterButton>
    </FilterContainer>
  );
};

const FilterContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-left: 6px;
  padding-right: 6px;
  .btn {
    color: ${p => p.styles.txColor};
    border: 1px solid ${p => p.styles.borderColor};
    background-color: ${p => p.styles.bgColor};
  }
  .btn-select {
    color: ${p => p.styles.selectTxColor};
    border: 1px solid ${p => p.styles.selectBorderColor};
    background-color: ${p => p.styles.selectBgColor};
  }
`;
const FilterButton = styled.div`
  width: 80px;
  border-radius: 100px;
  margin: 6px;
  text-align: center;
  cursor: pointer;
`;

export default TimeFilter;
