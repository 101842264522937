import React from 'react';
import ReactDOM from 'react-dom';
import App from './pages/App';

import { Provider } from './stores/index';
import useAuth from './stores/useAuth';
import useAssetTheme from './stores/useAssetTheme';

ReactDOM.render(
  <Provider inject={[useAuth, useAssetTheme]}>
    <App />
  </Provider>,
  document.getElementById('root')
);
