import React from 'react';
//
import { useProvided } from '../../stores/index';
import useAssetTheme from '../../stores/useAssetTheme';

const EmptyMsg = ({ msg }) => {
  const { solaTheme } = useProvided(useAssetTheme);

  const txColor = solaTheme.componentColors.EmptyTxColor;

  return <div style={{ padding: '20px', color: txColor }}>{msg}</div>;
};

export default EmptyMsg;
