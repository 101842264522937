import React from 'react';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import useAssetTheme from '../../stores/useAssetTheme';
import { useProvided } from '../../stores/index';

const SwitchSelector = ({ label, value, changeFn }) => {
  const { solaTheme } = useProvided(useAssetTheme);
  const colors = {
    switchOffColor: solaTheme.componentColors.SwitchOffColor,
    switchOnColor: solaTheme.componentColors.SwitchOnColor,
    switchOnTrackColor: solaTheme.componentColors.SwitchOnTrackColor,
    labelColor: solaTheme.componentColors.SwitchLabelColor
  };
  const CustomSwitch = withStyles({
    switchBase: {
      color: colors.switchOffColor,
      '&$checked': {
        color: colors.switchOnColor
      },
      '&$checked + $track': {
        backgroundColor: colors.switchOnTrackColor
      }
    },
    checked: {},
    track: {}
  })(Switch);
  return (
    <SelectorContainer>
      <SelectorLabel color={colors.labelColor}>{label}</SelectorLabel>
      <SelectorItem>
        <CustomSwitch checked={value} onChange={changeFn} />
      </SelectorItem>
    </SelectorContainer>
  );
};

const SelectorContainer = styled.div`
  margin: 10px;
  display: flex;
  align-items: center;
`;
const SelectorLabel = styled.div`
  margin-right: 5px;
  width: 80px;
  color: ${p => p.color};
  text-align: right;
`;
const SelectorItem = styled.div`
  padding-top: 3px;
`;

export default SwitchSelector;
