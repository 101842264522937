import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
//
import { useProvided } from '../../stores/index';
import useAssetTheme from '../../stores/useAssetTheme';

const TwoLayerMenuItem = props => {
  const { solaTheme } = useProvided(useAssetTheme);
  const { menu, routeMenu, routePage, panel, onHandlePanel } = props;
  const isExpanded = panel === menu.menu;
  const isMenuSelected = routeMenu === menu.menu;

  const menu1Style = {
    txColor: solaTheme.componentColors.MenuL1TxColor,
    txSelectColor: solaTheme.componentColors.MenuL1TxSelectColor,
    txHoverColor: solaTheme.componentColors.MenuL1TxHoverColor
  };
  const menu2Style = {
    bgColor: solaTheme.componentColors.MenuL2BgColor,
    bgHoverColor: solaTheme.componentColors.MenuL2BgHoverColor,
    txColor: solaTheme.componentColors.MenuL2TxColor,
    txSelectColor: solaTheme.componentColors.MenuL2TxSelectColor,
    txHoverColor: solaTheme.componentColors.MenuL2TxHoverColor,
    hrColor: solaTheme.componentColors.MenuL2HrColor
  };

  const submenuDom = [];
  for (let i = 0; i < menu.submenu.length; i++) {
    const item = menu.submenu[i];
    const isPageSelected = routePage === item.page;
    submenuDom.push(
      <Link key={i} to={item.path} style={{ width: '100%' }}>
        <MenuL2Container selected={isPageSelected} styles={menu2Style}>
          <MenuL2Icon>{item.icon /*isPageSelected ? item.icon_select : item.icon*/}</MenuL2Icon>
          <MenuL2Name>{item.name}</MenuL2Name>
        </MenuL2Container>
      </Link>
    );
  }

  return (
    <ExpansionPanel expanded={isExpanded} onChange={onHandlePanel(menu.menu)}>
      <ExpansionPanelSummary>
        <MenuL1Container selected={isMenuSelected} styles={menu1Style}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <MenuL1Icon>{menu.icon}</MenuL1Icon>
            <MenuL1Name>{menu.name}</MenuL1Name>
          </div>
          <MenuL1ExpandIcon>
            {isExpanded ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
          </MenuL1ExpandIcon>
        </MenuL1Container>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>{submenuDom}</ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

const MenuL1Container = styled.div`
  width: 100%;
  height: 70px;
  color: ${p => (p.selected ? p.styles.txSelectColor : p.styles.txColor)};

  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-left: 0px;
  padding-right: 10px;
  &:hover {
    color: ${p => p.styles.txHoverColor};
  }
  &:hover .svgHover {
    fill: ${p => p.styles.txHoverColor};
  }
  .svgSelect {
    fill: ${p => (p.selected ? p.styles.txSelectColor : p.styles.txColor)};
  }
`;
const MenuL1Icon = styled.div`
  width: 15px;
  height: 15px;
  margin-right: 10px;
`;
const MenuL1Name = styled.div`
  font-size: 17px;
`;
const MenuL1ExpandIcon = styled.div`
  padding-top: 5px;
`;

const MenuL2Container = styled.div`
  display: flex;
  align-items: center;
  width: 85%;
  height: 45px;
  padding: 3% 0% 3% 15%;
  border-bottom: 1px solid ${p => p.styles.hrColor};
  color: ${p => (p.selected ? p.styles.txSelectColor : p.styles.txColor)};

  background-color: ${p => p.styles.bgColor};
  &:hover {
    background-color: ${p => p.styles.bgHoverColor};
    color: ${p => p.styles.txHoverColor};
  }
  &:hover .svgHover {
    fill: ${p => p.styles.txHoverColor};
  }
  .svgSelect {
    fill: ${p => (p.selected ? p.styles.txSelectColor : p.styles.txColor)};
  }
`;
const MenuL2Icon = styled.div`
  width: 15px;
  height: 15px;
  margin-right: 10px;
`;
const MenuL2Name = styled.span`
  font-size: 14px;
`;

export default TwoLayerMenuItem;
