import bn from 'bignumber.js';
import moment from 'moment';

export const DisplayFormat = (value, prefix, postfix) => {
  if (value || value === 0) {
    let content = Number(value).toLocaleString(undefined, {
      maximumFractionDigits: 8
    });

    return `${prefix || ''}${content} ${postfix || ''}`;
  } else {
    return 'N/A';
  }
};

export const NumberFormat = (value, prefix, postfix) => {
  if (value || value === 0) {
    let content = Number(value).toLocaleString(undefined, {
      maximumFractionDigits: 2
    });

    return `${prefix || ''}${content} ${postfix || ''}`;
  } else {
    return 'N/A';
  }
};

export const IntegerFormat = (value, prefix, postfix) => {
  if (value || value === 0) {
    let content = Number(value).toLocaleString(undefined, {
      maximumFractionDigits: 0
    });

    return `${prefix || ''}${content} ${postfix || ''}`;
  } else {
    return 'N/A';
  }
};

export const PercentFormat = value => {
  if (value || value === 0) {
    return Number(value).toLocaleString(undefined, {
      style: 'percent',
      maximumFractionDigits: 2
    });
  } else {
    return 'N/A';
  }
};

export const PercentFormatFixedOne = value => {
  if (value || value === 0) {
    return Number(value).toLocaleString(undefined, {
      style: 'percent',
      maximumFractionDigits: 1,
      minimumFractionDigits: 1
    });
  } else {
    return 'N/A';
  }
};

export const FixNumber = (value, dpNumber) => {
  if (value) {
    if (dpNumber) {
      return bn(value)
        .dp(dpNumber)
        .toNumber();
    } else if (dpNumber === 0) {
      return bn(value)
        .dp(0)
        .toNumber();
    } else {
      return bn(value).toNumber();
    }
  } else {
    return NaN;
  }
};

export const GetColors = (mainColor, colors, selectColor, selectId, colorNumber) => {
  let rtnVal = [mainColor];
  const Colors = colors || [];
  colorNumber = colorNumber - 1; //do not count the main color.

  //copy all color in colors set.
  if (colorNumber > Colors.length) {
    const times = Math.floor(colorNumber / Colors.length);
    for (let i = 0; i < times; i++) {
      rtnVal = [...rtnVal, ...Colors];
    }
    colorNumber = colorNumber - Colors.length * times;
  }

  //setting remained color from colors set.
  for (let j = 0; j < colorNumber; j++) {
    rtnVal.push(Colors[j]);
  }

  //make color of the picked item to be "select color".
  rtnVal[selectId] = selectColor;

  return rtnVal;
};

export const GenerateYearSelector = () => {
  const rtnVal = [];
  let year = '';
  for (let i = 0; i < 10; i++) {
    year = moment()
      .subtract(i, 'year')
      .format('YYYY');
    rtnVal.push({
      key: year,
      name: year
    });
  }

  return rtnVal;
};
