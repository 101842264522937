import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import useAssetTheme from '../../../stores/useAssetTheme';
import { useProvided } from '../../../stores/index';
import ColHeader from './colheader';
const HEAD_ROW_HEIGHT = 40;
const HEAD_BIG_ROW_HEIGHT = 60;
const CONTENT_ROW_HEIGHT = 51;
const GenerateTd = (
  columns,
  row,
  rowIndex,
  handleSelectedRow,
  selectedRow,
  prefix,
  colors,
  borderColors,
  fontColors
) => {
  //convert data to pre-display result.
  const preDataToDom = [];
  columns.forEach((c, cIndex) => {
    if (c.subColumns && c.subColumns.length > 0) {
      c.subColumns.forEach((sub, subIndex) => {
        if (sub.render) {
          preDataToDom.push({
            key: `${prefix}-${cIndex}-${subIndex}`,
            style: sub.cellStyle,
            content: sub.render(row),
            rowIndex: rowIndex
          });
        } else {
          preDataToDom.push({
            key: `${prefix}-${cIndex}-${subIndex}`,
            style: sub.cellStyle,
            content: row[sub.field],
            rowIndex: rowIndex
          });
        }
      });
    } else {
      if (c.render) {
        preDataToDom.push({
          key: `${prefix}-${cIndex}`,
          style: c.cellStyle,
          content: c.render(row),
          rowIndex: rowIndex
        });
      } else {
        preDataToDom.push({
          key: `${prefix}-${cIndex}`,
          style: c.cellStyle,
          content: row[c.field],
          rowIndex: rowIndex
        });
      }
    }
  });

  //convert pre-data to DOM
  const rtnDOM = [];
  if (prefix === 'body') {
    preDataToDom.forEach((p, pIndex) => {
      if (pIndex === 0) {
        rtnDOM.push(
          <BodyTdFix
            key={p.key}
            color={colors}
            borderColor={borderColors}
            select={selectedRow === p.rowIndex}
            fontColor={fontColors}
            {...p.style}
          >
            {p.content}
          </BodyTdFix>
        );
      } else {
        rtnDOM.push(
          <BodyTd
            key={p.key}
            color={colors}
            borderColor={borderColors}
            select={selectedRow === p.rowIndex}
            fontColor={fontColors}
            onClick={handleSelectedRow(p.rowIndex)}
            paddingRight={'29px'}
            {...p.style}
          >
            {p.content}
          </BodyTd>
        );
      }
    });
  } else {
    preDataToDom.forEach((p, pIndex) => {
      if (pIndex === 0) {
        rtnDOM.push(
          <FootTdFix
            key={p.key}
            color={colors.footTdFix}
            borderColor={borderColors.borderColor}
            fontColor={fontColors}
            {...p.style}
          >
            {p.content}
          </FootTdFix>
        );
      } else {
        rtnDOM.push(
          <FootTd key={p.key} color={colors.footTd} fontColor={fontColors} paddingRight={'29px'} {...p.style}>
            {p.content}
          </FootTd>
        );
      }
    });
  }

  return rtnDOM;
};

const JTable = ({ columns, data, summaryData, mainWidth, scrollY = true }) => {
  let tableDom = null;
  const { solaTheme } = useProvided(useAssetTheme);
  const [sortColumn, setSortColumn] = useState(columns[0].field);
  const [sortDirection, setSortDirection] = useState('asc');
  const [selectedRow, setSelectedRow] = useState(-1);
  const colors = {
    headThFix: solaTheme.componentColors.JTHeadThFix,
    headTh: solaTheme.componentColors.JTHeadTh,
    headThFirst: solaTheme.componentColors.JTHeadThFirst,
    headThSecond: solaTheme.componentColors.JTHeadThSecond,
    bodyTdFix: solaTheme.componentColors.JTBodyTdFix,
    bodyTd: solaTheme.componentColors.JTBodyTd,
    footTdFix: solaTheme.componentColors.JTFootTdFix,
    footTd: solaTheme.componentColors.JTFootTd
  };
  const fontColors = {
    fontColor: solaTheme.componentColors.JTTextColor,
    fontSelectedColor: solaTheme.componentColors.JTTextSelectedColor
  };
  const borderColors = {
    borderColor: solaTheme.componentColors.JTBorder,
    borderSelectedColor: solaTheme.componentColors.JTBorderSelected
  };
  const handleSort = col => () => {
    if (sortColumn !== col) {
      setSortDirection('asc');
      setSortColumn(col);
    } else {
      setSortDirection(s => (s === 'asc' ? 'desc' : 'asc'));
    }
  };
  const handleSelectedRow = row => () => {
    if (selectedRow !== row) {
      setSelectedRow(row);
    } else {
      setSelectedRow(-1);
    }
  };
  const jData = useMemo(() => {
    let rtnVal = [];
    if (data) {
      if (sortColumn) {
        data.sort((a, b) => {
          const aField = a[sortColumn];
          const bField = b[sortColumn];

          if (sortDirection === 'asc') {
            if (aField < bField) {
              return -1;
            } else {
              return 1;
            }
          } else {
            if (aField < bField) {
              return 1;
            } else {
              return -1;
            }
          }
        });

        rtnVal = [...data];
      }
    }

    return rtnVal;
  }, [data, sortDirection, sortColumn]);

  try {
    //generate head
    const tHeadDom = [];
    const tHeadSubDom = [];
    if (columns) {
      columns.forEach((c, cIndex) => {
        if (c.subColumns && c.subColumns.length > 0) {
          tHeadDom.push(
            <HeadThFirst
              key={`head-${cIndex}`}
              rowSpan="1"
              colSpan={c.subColumns.length}
              color={colors.headThFirst}
              borderColor={borderColors.borderColor}
              fontColor={fontColors}
              {...c.headerStyle}
            >
              {c.title}
            </HeadThFirst>
          );

          c.subColumns.forEach((sub, subIndex) => {
            tHeadSubDom.push(
              <HeadThSecond
                key={`head-${cIndex}-${subIndex}`}
                select={sub.field === sortColumn}
                onClick={handleSort(sub.field)}
                color={colors.headThSecond}
                fontColor={fontColors}
                borderColor={borderColors}
                {...sub.headerStyle}
              >
                {/* {sub.title} */}
                <ColHeader
                  title={sub.title}
                  selected={sub.field === sortColumn}
                  sortDirection={sortDirection === 'asc' ? true : false}
                />
              </HeadThSecond>
            );
          });
        } else {
          if (cIndex === 0) {
            tHeadDom.push(
              <HeadThFix
                key={`head-${cIndex}`}
                rowSpan="2"
                select={c.field === sortColumn}
                onClick={handleSort(c.field)}
                color={colors.headThFix}
                fontColor={fontColors}
                borderColor={borderColors}
                {...c.headerStyle}
              >
                <ColHeader
                  title={c.title}
                  selected={c.field === sortColumn}
                  sortDirection={sortDirection === 'asc' ? true : false}
                  fix={true}
                />
              </HeadThFix>
            );
          } else {
            tHeadDom.push(
              <HeadTh
                key={`head-${cIndex}`}
                rowSpan="2"
                select={c.field === sortColumn}
                onClick={handleSort(c.field)}
                color={colors.headTh}
                fontColor={fontColors}
                borderColor={borderColors}
                {...c.headerStyle}
              >
                <ColHeader
                  title={c.title}
                  selected={c.field === sortColumn}
                  sortDirection={sortDirection === 'asc' ? true : false}
                />
              </HeadTh>
            );
          }
        }
      });
    }

    //generate body.
    const tBodyRowDom = [];
    jData.forEach((d, dIndex) => {
      const tmpBodyDom = GenerateTd(
        columns,
        d,
        dIndex,
        handleSelectedRow,
        selectedRow,
        'body',
        colors,
        borderColors,
        fontColors
      );
      if (tmpBodyDom && tmpBodyDom.length > 0) {
        tBodyRowDom.push(<BodyTr key={`body-${dIndex}`}>{tmpBodyDom}</BodyTr>);
      }
    });

    //generate summary body.
    const s = summaryData ? summaryData[0] : null;
    let tBodySummaryDom = null;
    if (s) {
      tBodySummaryDom = [];
      const tmpSummaryDom = GenerateTd(
        columns,
        s,
        jData.length,
        handleSelectedRow,
        selectedRow,
        'summary',
        colors,
        borderColors,
        fontColors
      );
      if (tmpSummaryDom && tmpSummaryDom.length > 0) {
        tBodySummaryDom.push(<FootTr key="summary-total">{tmpSummaryDom}</FootTr>);
      }
    }

    //calculate table height.
    let rowCount = 0;
    if (tHeadDom) {
      rowCount += HEAD_BIG_ROW_HEIGHT;
    }
    if (tBodyRowDom) {
      rowCount += tBodyRowDom.length * CONTENT_ROW_HEIGHT + 18;
    }
    if (tBodySummaryDom) {
      rowCount += HEAD_ROW_HEIGHT;
    }

    //draw table
    tableDom = (
      <TableConatiner height={rowCount} scrollY={scrollY}>
        <MainTable cellPadding="0" cellSpacing="0" mainWidth={mainWidth}>
          <MainThead>
            {tHeadDom && tHeadDom.length > 0 ? <HeadTr>{tHeadDom}</HeadTr> : null}
            {tHeadSubDom && tHeadSubDom.length > 0 ? <HeadTr>{tHeadSubDom}</HeadTr> : null}
          </MainThead>
          <MainTbody>{tBodyRowDom}</MainTbody>
          <MainTfoot>{tBodySummaryDom}</MainTfoot>
        </MainTable>
      </TableConatiner>
    );
  } catch (err) {
    console.error('Draw JTable Error:', err);
  }
  return tableDom;
};

const TableConatiner = styled.div`
  overflow: auto;
  width: 98%;
  height: ${props => (props.height ? +props.height + 'px' : '70vh')};
  max-height: ${props => (props.scrollY ? '70vh' : '')};
  margin-left: 1%;
  margin-right: 1%;
  margin-top: 10px;

  @media (max-height: 800px) {
    max-height: ${props => (props.scrollY ? '65vh' : '')};
  }
`;
const MainTable = styled.table`
  font-size: 14px;
  font-family: 'Noto Sans TC' !important;

  min-width: ${props => props.mainWidth || '100%'};
  table-layout: fixed;
`;
//
const MainThead = styled.thead``;
const HeadTr = styled.tr``;
const HeadThFix = styled.th`
  width: ${props => props.width || '150px'};
  height: ${HEAD_BIG_ROW_HEIGHT}px;
  background-color: ${props => props.color};
  border-bottom: ${props => (props.select ? 3 : 1)}px solid ${props =>
  props.select ? props.borderColor.borderSelectedColor : props.borderColor.borderColor}
  color: ${props => (props.select ? props.fontColor.fontSelectedColor : props.fontColor.fontColor)};
  text-align: left;
  padding-left: 10px;
  position: sticky;
  top: 0px;
  left: 0px;
  z-index: 3;

  cursor: pointer;
`;
const HeadTh = styled.th`
  width: ${props => props.width || '100px'};
  height: ${HEAD_BIG_ROW_HEIGHT}px;
  background-color: ${props => props.color};
  border-bottom: ${props => (props.select ? 3 : 1)}px solid ${props =>
  props.select ? props.borderColor.borderSelectedColor : props.borderColor.borderColor}
  color: ${props => (props.select ? props.fontColor.fontSelectedColor : props.fontColor.fontColor)};
  text-align: right;
  padding-right: ${p => p.paddingRight || '10'}px;

  position: sticky;
  top: 0px;
  z-index: 1;

  cursor: pointer;
`;
const HeadThFirst = styled.th`
  width: ${props => props.width || '100px'};
  height: ${HEAD_ROW_HEIGHT}px;
  background-color: ${props => props.color};
  border-bottom: 1px solid ${props => props.borderColor};
  color: ${props => props.fontColor.fontColor};
  position: sticky;
  top: 0px;
  z-index: 1;
`;
const HeadThSecond = styled.th`
  width: ${props => props.width || '100px'};
  height: ${HEAD_ROW_HEIGHT}px;
  background-color: ${props => props.color};

  border-bottom: ${props => (props.select ? 3 : 1)}px solid ${props =>
  props.select ? props.borderColor.borderSelectedColor : props.borderColor.borderColor}
  color: ${props => (props.select ? props.fontColor.fontSelectedColor : props.fontColor.fontColor)};
  text-align: right;
  padding-right: 10px;

  position: sticky;
  top: ${HEAD_ROW_HEIGHT + 1}px;
  z-index: 1;

  cursor: pointer;
`;
//
const MainTbody = styled.tbody``;
const BodyTr = styled.tr``;
const BodyTdFix = styled.td`
  width: ${props => props.width || '150px'};
  height: ${CONTENT_ROW_HEIGHT}px;

  background-color: ${props => (props.select ? props.color.footTdFix : props.color.bodyTdFix)};
  border-bottom: ${props => (props.select ? 3 : 1)}px solid ${props =>
  props.select ? props.borderColor.borderSelectedColor : props.borderColor.borderColor}
  color: ${props => props.fontColor.fontColor};
  text-align: left;
  padding-left: 10px;

  position: sticky;
  left: 0;
  z-index: 2;
`;
const BodyTd = styled.td`
  width: ${props => props.width || '100px'};
  height: ${CONTENT_ROW_HEIGHT}px;
  background-color: ${props => (props.select ? props.color.footTdFix : props.color.bodyTdFix)};
  border-bottom: ${props => (props.select ? 3 : 1)}px solid ${props =>
  props.select ? props.borderColor.borderSelectedColor : props.borderColor.borderColor}
  color: ${props => props.fontColor.fontColor};
  text-align: right;
  padding-right: ${p => p.paddingRight || '10px'};
`;
//
const MainTfoot = styled.tfoot``;
const FootTr = styled.tr``;
const FootTdFix = styled.td`
  width: ${props => props.width || '150px'};
  height: ${HEAD_ROW_HEIGHT}px;

  background-color: ${props => props.color};

  text-align: left;
  padding-left: 10px;
  color: ${props => props.fontColor.fontColor};
  position: sticky;
  left: 0px;
  bottom: 0px;
  z-index: 2;
`;
const FootTd = styled.td`
  width: ${props => props.width || '100px'};
  height: ${HEAD_ROW_HEIGHT}px;

  background-color: ${props => props.color};
  text-align: right;
  padding-right: ${p => p.paddingRight || '10px'};
  color: ${props => props.fontColor.fontColor};
  position: sticky;
  bottom: 0px;
  z-index: 1;
`;
export default JTable;
