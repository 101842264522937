import React from 'react';
import styled from 'styled-components';
//
import { useProvided } from '../../../stores/index';
import useAssetTheme from '../../../stores/useAssetTheme';

const ChartTooltip = ({ width, center, iconColor, header, contents }) => {
  const { solaTheme } = useProvided(useAssetTheme);

  const tooltipColors = {
    bgColor: solaTheme.componentColors.TipBgColor,
    hrColor: solaTheme.componentColors.TipHrColor,
    txColor: solaTheme.componentColors.TipTxColor,
    titleColor: solaTheme.componentColors.TipTitleColor,
  };

  let contentDOM = null;
  if (Array.isArray(contents)) {
    contentDOM = [];
    contents.forEach((c, index) => {
      contentDOM.push(
        <TooltipRow key={index}>
          <div style={{ display: 'flex' }}>
            {c.color && <span style={{ backgroundColor: c.color }}>　</span>}
            <div className="title">{c.title}</div>
          </div>
          <TooltipValue>
            <div className="value">{c.value}</div>
            <div className="unit">{c.unit}</div>
          </TooltipValue>
        </TooltipRow>
      );
    });
  }

  return (
    <TooltipContainer {...tooltipColors} width={width} center={center || 0}>
      <TooltipRow className="header">
        {iconColor ? <TooltipIcon color={iconColor} /> : null}
        <div>{header}</div>
      </TooltipRow>
      {contentDOM}
    </TooltipContainer>
  );
};

const TooltipContainer = styled.div`
  min-width: ${(p) => p.width || '180px'};
  background-color: ${(p) => p.bgColor};
  padding-left: 3px;
  padding-right: 3px;
  position: relative;
  left: ${(p) => (p.center > 55 ? '-100px' : '0px')};

  .header {
    border-bottom: 1px solid ${(p) => p.hrColor};
    color: ${(p) => p.titleColor};
    font-size: 14px;
  }
  .title {
    color: ${(p) => p.titleColor};
    font-size: 14px;
    margin-right: 10px;
  }
  .value {
    color: ${(p) => p.txColor};
    font-size: 18px;
    font-weight: bold;
    margin-right: 8px;
  }
  .unit {
    color: ${(p) => p.titleColor};
    font-size: 14px;
  }
`;
const TooltipRow = styled.div`
  min-height: 35px;
  padding-left: 10px;
  padding-right: 10px;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const TooltipValue = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const TooltipIcon = styled.div`
  width: 10px;
  height: 10px;
  background-color: ${(p) => p.color};
`;

export default ChartTooltip;
