import React, { useState, useCallback, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import styled from 'styled-components';
//common
import { IntegerFormat, FixNumber, GenerateYearSelector } from '../../../commons/utils';
import useAssetTheme from '../../../stores/useAssetTheme';
//component
import TitleContainer from '../../../components/Title/TitleContainer';
import TitleWithIcon from '../../../components/Title/TitleWithIcon';
import TimeFilter from '../../../components/TimeFilter';
import DropDownList from '../../../components/Selector/dropdownlist';
import SwitchSelector from '../../../components/Selector/switchSelector';
import BarChart from '../../../components/Chart/BarChart';
import EmptyMsg from '../../../components/EmptyMsg';
import ChartTooltip from '../../../components/Tooltip/ChartTooltip';
//icon
import { ReactComponent as PieChartIcon } from '../../../images/svg/pie_chart.svg';
//
import { useProvided } from '../../../stores/index';
import useAuth from '../../../stores/useAuth';

const GenerateToolTipDom = (searchPeriod, block) => {
  const dateTitle = GenerateAxisX(searchPeriod, block.data.period);
  let tooltipDOM = null;
  switch (block.id) {
    case '收益':
      tooltipDOM = (
        <ChartTooltip
          width="200px"
          center={null}
          iconColor={null}
          header={dateTitle + '-收益'}
          contents={[{ title: '淨利', value: IntegerFormat(block.data['收益']), unit: '元' }]}
        />
      );
      break;
    case '成本':
      tooltipDOM = (
        <ChartTooltip
          width="200px"
          center={null}
          iconColor={null}
          header={dateTitle + '-成本'}
          contents={[
            { title: '租金費用', value: IntegerFormat(block.data.rentCost), unit: '元' },
            { title: '電錶租費', value: IntegerFormat(block.data.meterRentCost), unit: '元' },
            { title: '保險費用', value: IntegerFormat(block.data.insuranceCost), unit: '元' },
            { title: '營運費用', value: IntegerFormat(block.data.operateCost), unit: '元' },
            { title: '服務費用', value: IntegerFormat(block.data.serviceCost), unit: '元' },
            { title: '貸款費用', value: IntegerFormat(block.data.loanCost), unit: '元' },
            { title: '貸款手續費', value: IntegerFormat(block.data.loanFeeCost), unit: '元' },
            { title: '營業稅', value: IntegerFormat(block.data.businessTax), unit: '元' },
            { title: '合計', value: IntegerFormat(block.data['成本']), unit: '元' }
          ]}
        />
      );
      break;
    default:
      break;
  }

  return tooltipDOM;
};
const GenerateAxisX = (period, value) => {
  let rtnVal = '';
  const mDate = moment(value);

  rtnVal = mDate.format('YYYY');
  switch (period) {
    case 'week':
      let currentWeek = mDate.format('w');

      if (currentWeek === '1') {
        const theFirstDayOfLastWeek = moment(value)
          .endOf('year')
          .add(-1, 'week');

        if (mDate.isSameOrAfter(theFirstDayOfLastWeek)) {
          currentWeek = '53';
        }
      }

      rtnVal += '-W' + currentWeek;
      break;
    case 'month':
      rtnVal += '-' + mDate.format('M') + '月';
      break;
    case 'quarter':
      rtnVal += '-Q' + mDate.format('Q');
      break;
    case 'year':
      rtnVal += '年';
      break;
    default:
      rtnVal = value;
      break;
  }

  return rtnVal;
};
const CancelFnList = [];
const IncomeAnalysis = () => {
  const { currentSPV } = useProvided(useAuth);
  //view control
  const [searchPeriod, setSearchPeriod] = useState('week');
  const [searchPvId, setSearchPvId] = useState('');
  const [searchYear, setSearchYear] = useState(moment().format('YYYY'));
  const [searchSim, setSearchSim] = useState(true);
  const [profitEnd, setProfitEnd] = useState(true);
  const [barChartKey, setBarChartKey] = useState(['收益', '成本']);
  //data
  const [yearList, setYearList] = useState([]);
  const [pvList, setPvList] = useState([]);
  const [pvProfit, setPvProfit] = useState([]);
  // theme
  const { solaTheme } = useProvided(useAssetTheme);

  //Data Loading.
  const loadYearList = useCallback(() => {
    setYearList(GenerateYearSelector());
  }, []);
  const loadPvList = useCallback(() => {
    const source = axios.CancelToken.source();
    CancelFnList.push(source.cancel);
    axios
      .get(`/api/v1/assets/userPv`, {
        headers: { Authorization: currentSPV.token },
        cancelToken: source.token
      })
      .then(res => {
        if (res && res.data && res.data.status) {
          const list = [];
          res.data.result.userPv.forEach(p => {
            list.push({
              key: p.pvId,
              name: p.pvName
            });
          });

          if (list.length > 0) {
            setSearchPvId(pvId => pvId || list[0].key);
          }
          setPvList(list);
        }
      })
      .catch(err => {
        if (!axios.isCancel(err)) {
          console.error(err);
        }
      });
  }, [currentSPV]);
  const loadData = useCallback(() => {
    if (searchPvId) {
      const source = axios.CancelToken.source();
      CancelFnList.push(source.cancel);
      axios
        .get(
          `/api/v1/assets/incomeAnalysis?` +
            `viewType=${searchPeriod || ''}&year=${searchYear || ''}` +
            `&pvId=${searchPvId || ''}&sim=${searchSim ? 'Y' : 'N'}`,
          { headers: { Authorization: currentSPV.token }, cancelToken: source.token }
        )
        .then(res => {
          if (res && res.data && res.data.status) {
            const barData = [];

            //data type convert
            res.data.result.pvProfit.forEach(p => {
              barData.push({
                period: p.period,
                收益: FixNumber(p.profit, 0),
                成本: FixNumber(p.cost, 0),
                rentCost: FixNumber(p.rentCost, 0),
                meterRentCost: FixNumber(p.meterRentCost, 0),
                insuranceCost: FixNumber(p.insuranceCost, 0),
                operateCost: FixNumber(p.operateCost, 0),
                serviceCost: FixNumber(p.serviceCost, 0),
                loanCost: FixNumber(p.loanCost, 0),
                loanFeeCost: FixNumber(p.loanFeeCost, 0),
                businessTax: FixNumber(p.businessTax, 0)
              });
            });

            setPvProfit(barData);
          }
        })
        .catch(err => {
          if (!axios.isCancel(err)) {
            console.error(err);
          }
        });
    }
  }, [currentSPV, searchPeriod, searchYear, searchPvId, searchSim]);

  useEffect(() => {
    loadYearList();
    loadPvList();
    loadData();

    return () => {
      if (CancelFnList && CancelFnList.length > 0) {
        CancelFnList.forEach(fn => {
          fn();
        });
      }
    };
  }, [loadYearList, loadPvList, loadData]);

  //handle interaction.
  const handlePeriodClick = view => () => {
    if (view !== searchPeriod) {
      setSearchPeriod(view);
    }
  };
  const handlePvIdChange = event => {
    if (event.target.value !== searchPvId) {
      setSearchPvId(event.target.value);
    }
  };
  const handleYearChange = event => {
    if (event.target.value !== searchYear) {
      setSearchYear(event.target.value);
    }
  };
  const handleSimChange = () => {
    setSearchSim(s => !s);
  };
  const handleProfitEndChange = () => {
    setProfitEnd(p => !p);
    setBarChartKey(key => [key[1], key[0]]);
  };
  const colors = {
    conditionContainerBgColor: solaTheme.componentColors.ConditionContainerBgColor,
    hrColor: solaTheme.componentColors.TitleContainerHrColor
  };
  return (
    <div>
      <TitleContainer>
        <TitleWithIcon title="收益分析" prefixIcon={PieChartIcon} />
      </TitleContainer>
      <ConditionContainer conditionContainerBgColor={colors.conditionContainerBgColor}>
        <TimeFilter currentFilter={searchPeriod} filterFn={handlePeriodClick} />
        <DropDownList
          label="選擇電廠"
          value={searchPvId}
          options={pvList}
          width="300px"
          changeFn={handlePvIdChange}
        />
        <DropDownList label="選擇年份" value={searchYear} options={yearList} changeFn={handleYearChange} />
      </ConditionContainer>
      <ConditionContainerRight {...colors}>
        <SwitchSelector label="含推估收益" value={searchSim} changeFn={handleSimChange} />
        <SwitchSelector label="收益置底" value={profitEnd} changeFn={handleProfitEndChange} />
      </ConditionContainerRight>
      <div>
        {pvProfit && pvProfit.length > 0 ? (
          <ChartContainer>
            <BarChart
              data={pvProfit}
              keys={barChartKey}
              indexBy="period"
              margin={{ top: 50, right: 150, bottom: 70, left: 90 }}
              axisLeft={{ format: value => IntegerFormat(value, '$') }}
              axisBottom={{
                format: value => GenerateAxisX(searchPeriod, value),
                tickPadding: 10,
                tickRotation: -40
              }}
              tooltip={item => GenerateToolTipDom(searchPeriod, item)}
            />
          </ChartContainer>
        ) : (
          <EmptyMsg msg="查無資料" />
        )}
      </div>
    </div>
  );
};

const ConditionContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: ${p => p.conditionContainerBgColor};
`;
const ConditionContainerRight = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-left: 15px;
  padding-right: 7px;
  background-color: ${p => p.conditionContainerBgColor};
  border-bottom: ${p => (p.transparent ? '0px' : `1px solid ${p.hrColor}`)};
`;

const ChartContainer = styled.div`
  width: 100%;
  height: 600px;

  @media (max-height: 800px) {
    height: 400px;
  }
`;

export default IncomeAnalysis;
