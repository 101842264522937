import React from 'react';
import styled from 'styled-components';
import MuiTooltip from '@material-ui/core/Tooltip';
import { ReactComponent as SolaHintIcon } from '../../../images/svg/sola_sign.svg';
//
import { useProvided } from '../../../stores/index';
import useAssetTheme from '../../../stores/useAssetTheme';

/**
 * desc = [ {colName: '', colDesc: '' }, {...} ]
 */
const JTooltip = ({ desc }) => {
  const { solaTheme } = useProvided(useAssetTheme);

  if (desc && Array.isArray(desc) && desc.length > 0) {
    const colors = {
      icon: solaTheme.componentColors.TipIconColor,
      bg: solaTheme.componentColors.TipBgColor,
      title: solaTheme.componentColors.TipTitleColor,
      desc: solaTheme.componentColors.TipTxColor
    };

    const tipDOM = [];
    desc.forEach((d, index) => {
      tipDOM.push(
        <TipRow key={index}>
          <TipTitle className="title">{d.colName}</TipTitle>
          <TipDesc className="desc">{d.colDesc}</TipDesc>
        </TipRow>
      );
    });

    return (
      <MuiTooltip
        disableFocusListener
        disableTouchListener
        placement="bottom-end"
        title={<TipContainer colors={colors}>{tipDOM}</TipContainer>}
      >
        <Div color={colors.icon}>
          <IconSvg>{React.createElement(SolaHintIcon)}</IconSvg>
          <div class="note">欄位說明</div>
        </Div>
      </MuiTooltip>
    );
  } else {
    return null;
  }
};

const TipContainer = styled.div`
  width: 100%;
  font-size: 14px;
  background-color: ${p => p.colors.bg};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;

  .title {
    color: ${p => p.colors.title};
  }
  .desc {
    color: ${p => p.colors.desc};
  }
`;
const TipRow = styled.div`
  width: 90%;
  min-width: 400px;
  margin-top: 10px;
  margin-bottom: 10px;
  display: inline-flex;
`;
const TipTitle = styled.div`
  width: 25%;
  margin-right: 10px;
`;
const TipDesc = styled.div`
  width: 75%;
`;
const IconSvg = styled.div`
  margin-right: 10px;
  padding-top: 6px;
  width: ${props => props.size || 18}px;
`;
const Div = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;

  .svgBase {
    fill: ${p => p.color || '#fff'};
  }
  .note {
    color: ${p => p.color || '#fff'};
    width: 100px;
  }
`;
export default JTooltip;
