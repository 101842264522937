import React, { useEffect, useState } from 'react';
import moment from 'moment';

import TimeSvg from '../../images/svg/time.svg';
//
import { useProvided } from '../../stores/index';
import useAssetTheme from '../../stores/useAssetTheme';

const Clock = props => {
  const { solaTheme } = useProvided(useAssetTheme);
  const [currentTime, setCurrentTime] = useState(moment().format('HH:mm'));

  useEffect(() => {
    const count = setInterval(() => {
      setCurrentTime(moment().format('HH:mm'));
    }, 60000);
    return function cleanup() {
      clearInterval(count);
    };
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        height: '48px',
        marginRight: '10px'
      }}
    >
      <img alt="clock" src={TimeSvg} width="15px" style={{ marginRight: '8px' }} />
      <div style={{ color: solaTheme.componentColors.ProfitCardClockTxColor, fontSize: '24px' }}>
        {currentTime}
      </div>
    </div>
  );
};

export default Clock;
