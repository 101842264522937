import React, { useMemo, useState, useEffect } from 'react';
import styled from 'styled-components';
import { ResponsivePie } from '@nivo/pie';
import { GetColors, IntegerFormat } from '../../../commons/utils';
import { ReactComponent as PowerIcon } from '../../../images/svg/power.svg';
import EmptyMsg from '../../EmptyMsg';
import ChartTooltip from '../../Tooltip/ChartTooltip';
//
import { useProvided } from '../../../stores/index';
import useAssetTheme from '../../../stores/useAssetTheme';

const PieChart = ({ data, tipTitle, ...restProps }) => {
  const { solaTheme } = useProvided(useAssetTheme);
  const [selectItem, setSelectItem] = useState(null);
  const [selectIndex, setSelectIndex] = useState(0);

  const handleDetailClick = id => () => {
    if (id) {
      let index = data.findIndex(d => d.id === id);
      if (index < 0) {
        index = 0;
      }
      setSelectItem(data[index]);
      setSelectIndex(index);
    }
  };

  const uiColors = useMemo(() => {
    //chart color
    const pieLabel = solaTheme.componentColors.UAPieChartTxColor;
    const tmpColors = GetColors(
      solaTheme.componentColors.UAPieChartMainColor,
      solaTheme.componentColors.UAPieChartColors,
      solaTheme.componentColors.UAPieChartSelectColor,
      selectIndex,
      data.length
    );
    const pieColors = {};
    data.forEach((d, index) => {
      pieColors[d.id] = tmpColors[index];
    });

    //list color
    const list = {
      text: solaTheme.componentColors.UAPieListTxColor,
      border: solaTheme.componentColors.UAPieListBorderColor,
      hoverBg: solaTheme.componentColors.UAPieListHoverBgColor,
      selectBg: solaTheme.componentColors.UAPieListSelectBgColor,
      selectBorder: solaTheme.componentColors.UAPieListSelectBorderColor
    };

    //detail color
    const detail = {
      title: solaTheme.componentColors.UAPieDetailTitleColor,
      pv: solaTheme.componentColors.UAPieDetailPvTxColor,
      value: solaTheme.componentColors.UAPieDetailValueTxColor,
      total: solaTheme.componentColors.UAPieDetailTotalTxColor
    };

    return {
      pieLabel,
      chart: pieColors,
      list,
      detail
    };
  }, [data, solaTheme.componentColors, selectIndex]);

  useEffect(() => {
    if (data && data.length > 0) {
      setSelectItem(data[0]);
    }
  }, [data]);

  let innerDom = null;
  if (data) {
    innerDom = (
      <MainContainer>
        <LeftSideContainer>
          <ChartPanel>
            <ResponsivePie
              data={data}
              colors={Object.keys(uiColors.chart).map(k => {
                return uiColors.chart[k];
              })}
              innerRadius={0.5}
              enableRadialLabels={false}
              enableSlicesLabels={true}
              sliceLabel={d => {
                if (d.value && d.value > 2) {
                  return d.value + '%';
                } else {
                  return '';
                }
              }}
              theme={solaTheme.nivoTheme}
              slicesLabelsTextColor={uiColors.pieLabel}
              borderWidth={0}
              tooltip={e => (
                <ChartTooltip
                  width="180px"
                  iconColor={e.color}
                  header={e.id}
                  contents={[{ title: e.name, value: e.value, unit: '%' }]}
                />
              )}
              {...restProps}
            />
          </ChartPanel>
          <DetailPanel colors={uiColors.detail}>
            {selectItem ? (
              <div>
                <div className="title">{tipTitle}</div>
                <div>
                  <DetailIcon>
                    <PowerIcon />
                  </DetailIcon>
                  <span className="pv">{selectItem.label}</span>
                </div>
                <div>
                  <span className="value">{IntegerFormat(selectItem.number, '', '')}</span>
                  <span className="total">/ {IntegerFormat(selectItem.numberTotal, '', '元')}</span>
                </div>
              </div>
            ) : null}
          </DetailPanel>
        </LeftSideContainer>
        <RightSideContainer>
          {data && data.length > 0
            ? data.map((d, index) => {
                const isSelected = selectItem && selectItem.id === d.id;
                return (
                  <ListItemContainer key={index} select={isSelected} colors={uiColors.list}>
                    <ListItemInnerContainer onClick={handleDetailClick(d.id)}>
                      <ListItem className="item">
                        <div>{d.label}</div>
                        <div>{d.value}%</div>
                      </ListItem>
                    </ListItemInnerContainer>
                  </ListItemContainer>
                );
              })
            : null}
        </RightSideContainer>
      </MainContainer>
    );
  } else {
    innerDom = <EmptyMsg msg="查無資料" />;
  }

  return <Div>{innerDom}</Div>;
};

//layout
const Div = styled.div`
  width: 100%;
  height: 430px;
`;
const MainContainer = styled.div`
  width: 95%;
  height: 100%;
  margin-left: 2%;
  margin-right: 2%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;
const LeftSideContainer = styled.div`
  width: 48%;
  height: 98%;
`;
const RightSideContainer = styled.div`
  width: 48%;
  height: 96%;
  overflow-y: auto;
  margin-top: 2%;

  &::-webkit-scrollbar {
    width: 3px;
    height: 5px;
  }
`;

const ChartPanel = styled.div`
  height: 330px;
`;

const DetailPanel = styled.div`
  width: 100%;
  height: 90px;
  text-align: center;

  .title {
    font-weight: bold;
    color: ${p => p.colors.title};
  }
  .pv {
    color: ${p => p.colors.pv};
  }
  .svgBase {
    fill: ${p => p.colors.pv};
  }
  .value {
    color: ${p => p.colors.value};
  }
  .total {
    color: ${p => p.colors.total};
  }
`;
const DetailIcon = styled.span`
  margin-right: 10px;
`;

const ListItemContainer = styled.div`
  margin: 5px 20px;
  background-color: ${p => (p.select ? p.colors.selectBg : '')};
  border-bottom: 2px solid ${p => (p.select ? p.colors.selectBorder : p.colors.border)};

  .item {
    color: ${p => p.colors.text};
  }

  &:hover {
    background-color: ${p => p.colors.hoverBg};
  }
`;
const ListItemInnerContainer = styled.div`
  cursor: pointer;
  padding: 10px 3px;
`;
const ListItem = styled.div`
  width: 100%;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
`;

export default PieChart;
