import React, { useState, useCallback, useEffect, useMemo } from 'react';
import axios from 'axios';
import styled from 'styled-components';

//common
import { PercentFormat, NumberFormat, IntegerFormat } from '../../../commons/utils';
import TitleContainer from '../../../components/Title/TitleContainer';
import TitleWithIcon from '../../../components/Title/TitleWithIcon';
import useAssetTheme from '../../../stores/useAssetTheme';
//component
import JTable from '../../../components/Table/JTable';
import JTooltip from '../../../components/Tooltip/JTooltip';
import SwitchSelector from '../../../components/Selector/switchSelector';
//icon
import { ReactComponent as BarChartIcon } from '../../../images/svg/bar_chart.svg';

import { useProvided } from '../../../stores/index';
import useAuth from '../../../stores/useAuth';

const tableDesc = [
  { colName: '今日電費(元)', colDesc: '今日成本 + 今日收益。' },
  { colName: '今日成本(元)', colDesc: '當日推估試算所計算出的成本項目總和。' },
  { colName: '今日淨收益(元)', colDesc: '當日推估試算所計算出的淨收益。' },
  { colName: '平均淨日收益(元)', colDesc: '統計30天內的每日淨收益平均。' },
  { colName: '今日電費/kWp (元/kWp)', colDesc: '今日電費 ÷ 電廠規模。' },
  { colName: '今日成本/kWp (元/kWp)', colDesc: '今日成本 ÷ 電廠規模。' },
  { colName: '今日淨收益/kWp (元/kWp)', colDesc: '今日淨收益 ÷ 電廠規模。' },
  { colName: '平均淨日收益/kWp (元/kWp)', colDesc: '平均淨日收益 ÷ 電廠規模。' },
  { colName: '發電達成率', colDesc: '當日發電量 ÷ 當日保證發電量 × 100%。' },
  { colName: '狀態', colDesc: '電廠目前的狀態，有「發電中」、「建置中」、「維修中」。' },
  { colName: '天氣', colDesc: '電廠當地的預報天氣情況。' }
];
const GenerateColumns = unitize => {
  const rtnVal = [];
  const subTitles = ['今日電費', '今日成本', '今日淨收益', '平均淨日收益'];
  const subFields = ['todayPowerBill', 'todayCost', 'todayProfit', 'avgProfit'];

  rtnVal.push({
    title: '電廠',
    field: 'pvId',
    render: row => (
      <div>
        <div>{row.pvId && row.pvId + '-'}</div>
        <div>{row.pvName}</div>
      </div>
    ),
    cellStyle: {
      width: '200px'
    }
  });
  for (let i = 0; i < subTitles.length; i++) {
    const titleName = unitize ? subTitles[i] + '/kWp' : subTitles[i];
    const fieldName = unitize ? subFields[i] + '_scale' : subFields[i];
    rtnVal.push({
      title: titleName,
      field: fieldName,
      render: row => (
        <span>
          {unitize ? NumberFormat(row[fieldName], '', '元') : IntegerFormat(row[fieldName], '', '元')}
        </span>
      ),
      cellStyle: {
        width: '130px'
      }
    });
  }
  rtnVal.push({
    title: '發電達成率',
    field: 'todayAchievingRate',
    render: row => <span>{PercentFormat(row.todayAchievingRate)}</span>,
    cellStyle: {
      width: '80px'
    }
  });
  rtnVal.push({
    title: '狀態',
    field: 'pvStatus',
    cellStyle: {
      width: '60px'
    }
  });

  rtnVal.push({
    title: '天氣',
    field: 'weather',
    cellStyle: {
      width: '80px'
    }
  });

  return rtnVal;
};
const CancelFnList = [];
const TodayAnalysis = () => {
  const { currentSPV } = useProvided(useAuth);
  const [unitize, setUnitize] = useState(true);
  const [todayData, setTodayData] = useState([]);
  const { solaTheme } = useProvided(useAssetTheme);

  const handleSimulateSwitch = () => {
    setUnitize(u => !u);
  };
  const tableColumns = useMemo(() => {
    return GenerateColumns(unitize);
  }, [unitize]);

  const loadData = useCallback(() => {
    const source = axios.CancelToken.source();
    CancelFnList.push(source.cancel);
    axios
      .get(`/api/v1/assets/todayAnalysis`, {
        headers: { Authorization: currentSPV.token },
        cancelToken: source.token
      })
      .then(res => {
        if (res && res.data) {
          setTodayData(res.data.result.todayAnalysis);
        }
      })
      .catch(err => {
        if (!axios.isCancel(err)) {
          console.error(err);
        }
      });
  }, [currentSPV]);

  const colors = {
    conditionContainerBgColor: solaTheme.componentColors.ConditionContainerBgColor,
    hrColor: solaTheme.componentColors.TitleContainerHrColor
  };
  useEffect(() => {
    loadData();

    return () => {
      if (CancelFnList && CancelFnList.length > 0) {
        CancelFnList.forEach(fn => {
          fn();
        });
      }
    };
  }, [loadData]);

  return (
    <div>
      <TitleContainer>
        <TitleWithIcon title="今日分析" prefixIcon={BarChartIcon} />
      </TitleContainer>
      <ConditionContainer {...colors}>
        <JTooltip desc={tableDesc} />
        <SwitchSelector label="單位化" value={unitize} changeFn={handleSimulateSwitch} />
      </ConditionContainer>
      <JTable columns={tableColumns} data={todayData} scrollY={false} />
    </div>
  );
};

const ConditionContainer = styled.div`
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 15px;
  padding-right: 7px;
  background-color: ${p => p.conditionContainerBgColor};
  border-bottom: ${p => (p.transparent ? '0px' : `1px solid ${p.hrColor}`)};
`;

export default TodayAnalysis;
