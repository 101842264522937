import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Chart from './chart';
import Clock from './clock';
import Note from './note';
import Dallar from './dollar';
import Weather from './weather';
import { ReactComponent as PowerIcon } from '../../images/svg/power.svg';
//
import { useProvided } from '../../stores/index';
import useAssetTheme from '../../stores/useAssetTheme';

const GetPvId = title => {
  if (title) {
    return title.substr(0, 5);
  } else {
    return '';
  }
};

/**
 * @param {JSON} data 近期發電資訊方塊
 * @param {String} data.title 電廠名稱
 * @param {Number} data.profit 電廠今日推估收益
 * @param {Array} data.lastSeven 最近七天的推估收益
 * @param {JSON} data.noteInfo 登入者持有該電廠的 SOLA note 數量與總數
 * @param {Number} data.noteInfo.owner 該電廠被登入者持有的 SOLA note 數量
 * @param {Number} data.noteInfo.total 該電廠的 SOLA note 總數
 * @param {Number} data.weather 所在地點的溫度
 * @param {Boolean} isSummary 是否為總覽型的方塊(只有第一個方塊為true，其餘則false)
 */
const ProfitCard = ({ data, isSummary = false }) => {
  const { solaTheme } = useProvided(useAssetTheme);

  const squareStyle = {
    bgColor: solaTheme.componentColors.ProfitCardBgColor,
    bgImg: solaTheme.componentColors.ProfitCardBgImg
  };
  const titleStyle = {
    txColor: solaTheme.componentColors.ProfitCardTxColor
  };

  let titleDom = null;
  if (isSummary) {
    titleDom = GetTilteRow(data.title, titleStyle);
  } else {
    titleDom = <Link to={'/app/pvdata/myPvs/' + GetPvId(data.title)}>{GetTilteRow(data.title, titleStyle)}</Link>;
  }

  return (
    <Container>
      <SquareContainer styles={squareStyle}>
        <HeaderContainer>
          <Dallar dallars={data.profit} />
        </HeaderContainer>
        <ChartContainer>
          <Chart lastSeven={data.lastSeven} />
        </ChartContainer>
        <FooterContainer>
          <Note ownNotes={data.noteInfo.owner} totalNotes={data.noteInfo.total} />
          {isSummary ? <Clock /> : <Weather wxinfo={data.wxinfo} />}
        </FooterContainer>
      </SquareContainer>
      {titleDom}
    </Container>
  );
};

const Container = styled.div`
  width: 200px;
  height: 230px;
  margin: 20px 5px;
`;
const SquareContainer = styled.div`
  width: 200px;
  height: 200px;
  overflow: hidden;
  background-color: ${p => p.styles.bgColor};
  background-image: url(${p => p.styles.bgImg});
  background-repeat: no-repeat;
`;
const HeaderContainer = styled.div`
  height: 50px;
  margin: 3px 10px;
  text-align: right;
`;
const ChartContainer = styled.div`
  height: 95px;
`;
const FooterContainer = styled.div`
  margin: 5px 0px 0px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  padding-top: 6px;
  color: ${p => p.styles.txColor};
  font-size: 14px;
  font-weight: 600;

  color: ${p => p.styles.txColor};
  .svgBase {
    fill: ${p => p.styles.txColor};
  }
`;
const TitleIcon = styled.div`
  margin-right: 5px;
  padding-top: 6px;
`;
const GetTilteRow = (title, style) => {
  return (
    <TitleContainer styles={style}>
      <TitleIcon>
        <PowerIcon />
      </TitleIcon>
      <span>{title || '無'}</span>
    </TitleContainer>
  );
};

export default ProfitCard;
