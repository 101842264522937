import img1 from '../../images/ngo/台灣國際專案管理師協會.jpg';
import img2 from '../../images/ngo/中華R軟體學會.jpg';
import img3 from '../../images/ngo/臺灣資料科學與商業應用協會.jpg';
import img4 from '../../images/ngo/台灣量化交易協會.jpg';
import img5 from '../../images/ngo/數位外交行動計畫.jpg';

const Ngos = [
  {
    userId: 901,
    label: '台灣國際專案管理師協會',
    web: `https://www.itpm.org.tw/`,
    img: img1,
    logo: null,
    solar: 0,
    power: 0,
    income: 0,
  },
  {
    userId: 902,
    label: '中華R軟體學會',
    web: `http://www.r-software.org/`,
    img: img2,
    logo: null,
    solar: 0,
    power: 0,
    income: 0,
  },
  {
    userId: 903,
    label: '臺灣資料科學與商業應用協會',
    web: `http://www.twdatascience.org/`,
    img: img3,
    logo: null,
    solar: 0,
    power: 0,
    income: 0,
  },
  {
    userId: 904,
    label: '台灣量化交易協會',
    web: `https://tiqt.org.tw/`,
    img: img4,
    logo: null,
    solar: 0,
    power: 0,
    income: 0,
  },
  {
    userId: 905,
    label: '數位外交行動計畫',
    web: `https://www.facebook.com/TaiwanDigitalDiplomacy/about/`,
    img: img5,
    logo: null,
    solar: 0,
    power: 0,
    income: 0,
  },
];

export default Ngos;
