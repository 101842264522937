import React, { useState, useCallback, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import axios from 'axios';
import styled from 'styled-components';
import { toast } from 'react-toastify';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { VpnKey, Lock } from '@material-ui/icons';

import { useProvided } from '../../stores/index';
import useAssetTheme from '../../stores/useAssetTheme';

const CancelFnList = [];
const ResetPassword = ({ otp }) => {
  const { solaTheme } = useProvided(useAssetTheme);
  const [newPwd, setNewPwd] = useState('');
  const [confirmPwd, setConfirmPwd] = useState('');
  const [shouldRedirect, setShouldRedirect] = useState(false);

  const handleModifyPwd = () => {
    if (newPwd && confirmPwd) {
      if (newPwd === confirmPwd) {
        axios
          .post(`/api/v1/auth/resetPwd/${otp}`, { newPwd: newPwd })
          .then(res => {
            if (res && res.data) {
              if (res.data.status) {
                toast.info('修改密碼成功，請重新登入');
                setShouldRedirect(true);
              } else {
                toast.error('修改密碼失敗，請重新操作');
              }
            } else {
              toast.error('修改密碼失敗，請重新操作');
            }
          })
          .catch(err => {
            console.error(err);
            toast.error('修改密碼異常，請重新使用忘記密碼功能！');
            setShouldRedirect(true);
          });
      } else {
        toast.warn('新密碼與確認密碼不符，請重新填寫');
        setConfirmPwd('');
      }
    } else {
      toast.warn('請填寫新密碼、確認密碼欄位');
    }
  };

  const verifyOtp = useCallback(() => {
    const source = axios.CancelToken.source();
    CancelFnList.push(source.cancel);
    axios
      .get(`/api/v1/auth/resetPwd/${otp}`, { cancelToken: source.token })
      .then(res => {
        if (res && res.data) {
          const { status, msg } = res.data;

          if (!status) {
            toast.error(msg);
            setShouldRedirect(true);
          }
        } else {
          toast.error('驗證OTP異常，請重新整理頁面！');
          setShouldRedirect(true);
        }
      })
      .catch(err => {
        if (!axios.isCancel(err)) {
          console.error(err);
          toast.error('驗證OTP異常，請重新使用忘記密碼功能！');
          setShouldRedirect(true);
        }
      });
  }, [otp]);

  useEffect(() => {
    verifyOtp();
    return () => {
      if (CancelFnList && CancelFnList.length > 0) {
        CancelFnList.forEach(fn => {
          fn();
        });
      }
    };
  }, [verifyOtp]);

  const colors = {
    bg: solaTheme.componentColors.RPBgColor,
    tx: solaTheme.componentColors.RPTxColor,
    title: solaTheme.componentColors.RPTitleTxColor
  };

  return (
    <ResetPwdPanel bgColor={colors.bg} txColor={colors.tx}>
      {shouldRedirect ? <Redirect to="/login" /> : null}
      <TitlePanel txColor={colors.title}>
        <Lock style={{ marginRight: '10px' }} />
        <span>重設密碼</span>
      </TitlePanel>
      <ContentPanel>
        <div>
          <InputRow>
            <TextField
              id="newPwd"
              type="password"
              fullWidth
              label="新密碼"
              value={newPwd}
              onChange={e => setNewPwd(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <VpnKey />
                  </InputAdornment>
                )
              }}
            />
          </InputRow>
          <InputRow>
            <TextField
              id="confirmPwd"
              type="password"
              fullWidth
              label="確認新密碼"
              value={confirmPwd}
              onChange={e => setConfirmPwd(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <VpnKey />
                  </InputAdornment>
                )
              }}
            />
          </InputRow>
          <ButtonPanel>
            <ButtonRow>
              <Button variant="contained" color="primary" size="large" fullWidth onClick={handleModifyPwd}>
                確認
              </Button>
            </ButtonRow>
            <ButtonRow>
              <Link to="/login">
                <Button>回登入頁</Button>
              </Link>
            </ButtonRow>
          </ButtonPanel>
        </div>
      </ContentPanel>
    </ResetPwdPanel>
  );
};

const ResetPwdPanel = styled.div`
  width: 60vw;
  min-width: 350px;
  max-width: 436px;
  height: 494px;
  opacity: 0.85;
  border-radius: 2px;
  background-color: ${p => p.bgColor};
  color: ${p => p.txColor};
`;
const TitlePanel = styled.div`
  width: 100%;
  height: 90px;
  padding-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${p => p.txColor};
  font-size: 32px;
`;
const ContentPanel = styled.div`
  height: 300px;
  max-height: 300px;
  padding: 0px 33px;
`;
const InputRow = styled.div`
  margin-bottom: 20px;
`;
const ButtonPanel = styled.div`
  margin-top: 30px;
`;
const ButtonRow = styled.div`
  text-align: right;
  margin-top: 10px;
`;

export default ResetPassword;
