import React, { useMemo } from 'react';
import styled from 'styled-components';

import IconTooltip from '../Tooltip/IconTooltip';
import { PercentFormat } from '../../commons/utils';
import { ReactComponent as SolaSignIcon } from '../../images/svg/sola_sign.svg';
//
import { useProvided } from '../../stores/index';
import useAssetTheme from '../../stores/useAssetTheme';

const Note = ({ ownNotes, totalNotes }) => {
  const { solaTheme } = useProvided(useAssetTheme);

  const nItem = useMemo(() => {
    const rtnVal = {
      tipItem: {
        text: '',
        dom: null,
        styles: {
          width: '150px',
          top: '-75px',
          left: '-5px',
          bgColor: solaTheme.componentColors.TipBgColor
        }
      },
      iconItem: {
        icon: SolaSignIcon,
        iconImg: null,
        alt: 'note-icon',
        width: '18px',
        height: '18px',
        useCustomColor: true,
        baseColor: solaTheme.componentColors.ProfitCardNoteIconBase,
        hoverColor: solaTheme.componentColors.ProfitCardNoteIconHover
      }
    };

    const tableStyle = {
      thTxColor: solaTheme.componentColors.TipTitleColor,
      tdTxColor: solaTheme.componentColors.TipTxColor
    };

    //setting dom
    rtnVal.tipItem.dom = (
      <TipDiv>
        <TipTable styles={tableStyle}>
          <tbody>
            <tr>
              <TipTh className="tipTh">持有比例：</TipTh>
              <TipTd className="tipTd">{PercentFormat(ownNotes / totalNotes)}</TipTd>
            </tr>
          </tbody>
        </TipTable>
      </TipDiv>
    );

    return rtnVal;
  }, [ownNotes, totalNotes, solaTheme.componentColors]);

  return <IconTooltip iconItem={nItem.iconItem} tipItem={nItem.tipItem} />;
};

const TipDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;
const TipTable = styled.table`
  border: 0;
  margin: 0;
  padding: 0;
  width: 100%;

  .tipTh {
    color: ${p => p.styles.thTxColor};
  }
  .tipTd {
    color: ${p => p.styles.tdTxColor};
  }
`;
const TipTh = styled.th`
  text-align: left;
`;
const TipTd = styled.td`
  text-align: right;
`;
export default Note;
