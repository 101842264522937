import React from 'react';
import styled from 'styled-components';
import { useProvided } from '../../stores/index';
import useAssetTheme from '../../stores/useAssetTheme';

/**
 * @param {String} current 目前選擇的分頁ID
 * @param {Array} pages 有哪些分頁
 * @param {Boolean} seperate 是否出現右側的分隔線
 * @example 參數 pages 的內容範例
 * //[
 * //  {
 * //    id: '',
 * //    title: '',
 * //    icon: <SVG_PATH>,
 * //    icon_select: <SVG_PATH>
 * //  },
 * //  {...}
 * //]
 */
const TabPages = ({ current, pages, secondSelectColor, switchTabFn }) => {
  const dom = [];
  const { solaTheme } = useProvided(useAssetTheme);

  const tabStyle = {
    txColor: solaTheme.componentColors.TabTxColor,
    txSelectColor: secondSelectColor
      ? solaTheme.componentColors.TabTxSelectSecondColor
      : solaTheme.componentColors.TabTxSelectColor
  };
  const seperateStyle = {
    hrColor: solaTheme.componentColors.TabHrColor
  };

  if (pages && Array.isArray(pages)) {
    pages.forEach((p, index) => {
      dom.push(
        <TabContainer key={index} isSelected={current === p.id} onClick={switchTabFn(p.id)} styles={tabStyle}>
          <TabIcon>{React.createElement(p.icon)}</TabIcon>
          <div>{p.title}</div>
        </TabContainer>
      );

      if (index + 1 < pages.length) {
        dom.push(<TabSeperater key={'s_' + index} styles={seperateStyle} />);
      }
    });
  }

  return <Div>{dom}</Div>;
};

const Div = styled.div`
  display: flex;
  align-items: center;
`;

const TabContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  color: ${p => (p.isSelected ? p.selectedColor || p.styles.txSelectColor : p.styles.txColor)};
  margin-left: 15px;
  padding: 0px;
  cursor: pointer;

  .svgBase {
    fill: ${p => (p.isSelected ? p.selectedColor || p.styles.txSelectColor : p.styles.txColor)};
  }
`;
const TabIcon = styled.div`
  margin-right: 10px;
  padding-top: 4px;
  width: 16px;
`;
const TabSeperater = styled.div`
  width: 25px;
  height: 30px;
  border-right: 1px solid ${p => p.styles.hrColor || ''};
`;

export default TabPages;
