import React from 'react';
import styled from 'styled-components';

import { IntegerFormat } from '../../commons/utils';
import { ReactComponent as NTDollarMarkIcon } from '../../images/svg/nt_dollar_mark.svg';
import { useProvided } from '../../stores/index';
import useAssetTheme from '../../stores/useAssetTheme';

const Dollar = ({ dallars }) => {
  const { solaTheme } = useProvided(useAssetTheme);

  const dollarStyle = {
    txColor: solaTheme.componentColors.ProfitCardDollarTxColor,
    iconColor: solaTheme.componentColors.ProfitCardDollarIconColor
  };

  return (
    <DollarContainer styles={dollarStyle}>
      <DollarSvg>
        <NTDollarMarkIcon />
      </DollarSvg>
      <DollarValue>{dallars ? IntegerFormat(dallars, '', '') : '--'}</DollarValue>
    </DollarContainer>
  );
};

const DollarContainer = styled.div`
  display: fles;
  justify-content: flex-end;

  color: ${p => p.styles.txColor};
  .svgBase {
    fill: ${p => p.styles.iconColor};
  }
`;
const DollarSvg = styled.div`
  margin-top: 6px;
  margin-right: 6px;
`;
const DollarValue = styled.div`
  font-size: 36px;
`;

export default Dollar;
