import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { ResponsiveLine } from '@nivo/line';
import { IntegerFormat } from '../../commons/utils';
import EmptyMsg from '../EmptyMsg';
//
import { useProvided } from '../../stores/index';
import useAssetTheme from '../../stores/useAssetTheme';

const GetChartData = points => {
  let min = 0;
  let max = 0;
  const canvasData = [
    { id: 'normal', data: [] },
    { id: 'today', data: [] },
    { id: 'recover', data: [] },
    { id: 'error', data: [] }
  ];

  for (let i = 0; i < points.length; i++) {
    const date = points[i].date;
    const value = points[i].value;

    if (i <= 5) {
      //normal
      canvasData[0].data.push({ x: date, y: value });
      if (i === 5) {
        canvasData[2].data.push({ x: date, y: value });
      }
    }

    if (i > 4) {
      //last
      canvasData[1].data.push({ x: date, y: value });
    }

    //get min and max value.
    if (i === 0) {
      min = value;
      max = value;
    } else {
      if (min > value) {
        min = value;
      }

      if (max < value) {
        max = value;
      }
    }
  }

  //check min and max.
  if (min < 0) {
    const t = min * -1;
    if (t > max) {
      max = t;
    }
  }
  min = max * -1;

  return { min, max, data: canvasData };
};
const GenerateSliceTooltip = (slice, styles) => {
  return (
    <SliceTooltipContainer x={slice.x} {...styles}>
      <div className="title">{slice.points[0].data.xFormatted || slice.points[1].data.xFormatted}</div>
      <div className="value">
        {slice.points.length > 1 ? slice.points[1].data.yFormatted : slice.points[0].data.yFormatted}
      </div>
    </SliceTooltipContainer>
  );
};

const Chart = ({ lastSeven }) => {
  let chartDOM = null;
  const { solaTheme } = useProvided(useAssetTheme);
  if (lastSeven) {
    const { min, max, data } = GetChartData(lastSeven);

    const chartColors = [
      solaTheme.componentColors.ProfitCardChartLinePast,
      solaTheme.componentColors.ProfitCardChartLineNow,
      solaTheme.componentColors.ProfitCardChartLinePast,
      solaTheme.componentColors.ProfitCardChartLineError
    ];
    const tooltipStyle = {
      bgColor: solaTheme.componentColors.TipBgColor,
      titleColor: solaTheme.componentColors.TipTitleColor,
      txColor: solaTheme.componentColors.TipTxColor
    };
    chartDOM = (
      <ResponsiveLine
        animate={false}
        axisBottom={null}
        axisLeft={null}
        colors={chartColors}
        data={data}
        enableGridX={false}
        enableGridY={false}
        markers={[
          {
            axis: 'y',
            value: 0,
            lineStyle: { stroke: solaTheme.componentColors.ProfitCardChartLineBase, strokeWidth: 1 }
          }
        ]}
        enableCrosshair={false}
        enableSlices="x"
        margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
        xScale={{ type: 'point' }}
        yScale={{ type: 'linear', stacked: false, min: min, max: max }}
        xFormat={value => moment(value).format('YYYY-MM-DD')}
        yFormat={value => IntegerFormat(value, '收益 ', '元')}
        sliceTooltip={({ slice }) => GenerateSliceTooltip(slice, tooltipStyle)}
      />
    );
  } else {
    chartDOM = <EmptyMsg msg="尚未發電" />;
  }

  return chartDOM;
};

const SliceTooltipContainer = styled.div`
  position: relative;
  left: ${p => (p.x > 55 ? '-100px' : '0px')}
  padding: 8px;
  border-radius: 10px;

  background-color: ${p => p.bgColor};
  .title {
    color: ${p => p.titleColor};
  }
  .value {
    color: ${p => p.txColor}
  }  
`;

export default Chart;
