import React, { useState, useCallback, useMemo, useEffect } from 'react';
import axios from 'axios';
import BN from 'bignumber.js';
import styled from 'styled-components';

//common
import { IntegerFormat, PercentFormat } from '../../../commons/utils';
import TitleContainer from '../../../components/Title/TitleContainer';
import TitleWithIcon from '../../../components/Title/TitleWithIcon';
import useAssetTheme from '../../../stores/useAssetTheme';
//component
import PvCount from '../../../components/PvInfo/PvCount';
import JTable from '../../../components/Table/JTable';
import JTooltip from '../../../components/Tooltip/JTooltip';
import SwitchSelector from '../../../components/Selector/switchSelector';
//icon
import { ReactComponent as BookmarkIcon } from '../../../images/svg/bookmark.svg';

import { useProvided } from '../../../stores/index';
import useAuth from '../../../stores/useAuth';

const tableDesc = [
  { colName: '投資金額', colDesc: '投入該電廠的資金金額。' },
  { colName: '資產占比', colDesc: '投入該電廠的金額 ÷ 投入所有電廠的金額 × 100%。' },
  { colName: '總收益', colDesc: '該電廠的實際電費收入總和。' },
  { colName: '總報酬率', colDesc: '總收益 ÷ 投資金額 × 100%。' },
  {
    colName: '總收益(推估)',
    colDesc: '該電廠的實際電費收入總和 + 已發電但未收取之電費收入的推估試算總和。'
  },
  { colName: '總報酬率(推估)', colDesc: '總收益(推估) ÷ 投資金額 × 100%。' }
];
const GenerateColumns = simulate => {
  const rtnVal = [];
  const subTitles = ['總收益', '總報酬率'];
  const subFields = ['bill', 'billRoi'];

  rtnVal.push({
    title: '電廠',
    field: 'pvId',
    render: row => (
      <PvIdName>
        <div>{row.pvId && row.pvId + '-'}</div>
        <div>{row.pvName}</div>
      </PvIdName>
    ),
    cellStyle: {
      width: '150px'
    }
  });
  rtnVal.push({
    title: '投資金額',
    field: 'invest',
    render: row => <span>{IntegerFormat(row.invest, '', '元')}</span>,
    cellStyle: {
      width: '100px'
    }
  });
  rtnVal.push({
    title: '資產占比',
    field: 'weight',
    render: row => <span>{PercentFormat(row.weight)}</span>,
    cellStyle: {
      width: '100px'
    }
  });

  const field0 = simulate ? subFields[0] + '_sim' : subFields[0];
  rtnVal.push({
    title: subTitles[0],
    field: field0,
    render: row => <span>{IntegerFormat(row[field0], '', '元')}</span>,
    cellStyle: {
      width: '100px'
    }
  });

  const field1 = simulate ? subFields[1] + '_sim' : subFields[1];
  rtnVal.push({
    title: subTitles[1],
    field: field1,
    render: row => <span>{PercentFormat(row[field1])}</span>,
    cellStyle: {
      width: '100px'
    }
  });
  return rtnVal;
};

const CancelFnList = [];
const Abstract = () => {
  const { currentSPV } = useProvided(useAuth);
  const [pvCount, setPvCount] = useState(0);
  const [assets, setAssets] = useState([]);
  const [summary, setSummary] = useState([]);
  const [simulate, setSimulate] = useState(true);
  const { solaTheme } = useProvided(useAssetTheme);

  const handleSimulateSwitch = () => {
    setSimulate(s => !s);
  };

  const tableColumns = useMemo(() => {
    return GenerateColumns(simulate);
  }, [simulate]);

  //Data Loading.
  const loadAbstracts = useCallback(() => {
    const source = axios.CancelToken.source();
    CancelFnList.push(source.cancel);
    axios
      .get(`/api/v1/assets/abstract`, {
        headers: { Authorization: currentSPV.token },
        cancelToken: source.token
      })
      .then(res => {
        if (res && res.data && res.data.status) {
          setPvCount(res.data.result.pvCount);
          setAssets([...res.data.result.assets]);

          //
          const data = [];
          let sumInvest = 0;
          let sumWeight = 0;
          let sumBill = 0;
          let sumBillWithSim = 0;
          res.data.result.assets.forEach(asset => {
            sumInvest = BN(asset.invest)
              .plus(sumInvest)
              .toNumber();
            sumWeight = BN(asset.weight)
              .plus(sumWeight)
              .toNumber();
            sumBill = BN(asset.bill)
              .plus(sumBill)
              .toNumber();
            sumBillWithSim = BN(asset.bill_sim)
              .plus(sumBillWithSim)
              .toNumber();
          });
          //
          data.push({
            pvId: '',
            pvName: '合計',
            invest: sumInvest,
            weight: sumWeight,
            bill: sumBill,
            billRoi: BN(sumBill)
              .dividedBy(sumInvest)
              .toNumber(),
            bill_sim: sumBillWithSim,
            billRoi_sim: BN(sumBillWithSim)
              .dividedBy(sumInvest)
              .toNumber()
          });
          setSummary(data);
        }
      })
      .catch(err => {
        if (!axios.isCancel(err)) {
          console.error(err);
        }
      });
  }, [currentSPV]);

  const colors = {
    conditionContainerBgColor: solaTheme.componentColors.ConditionContainerBgColor,
    hrColor: solaTheme.componentColors.TitleContainerHrColor
  };

  //僅載入乙次
  useEffect(() => {
    loadAbstracts();

    return () => {
      if (CancelFnList && CancelFnList.length > 0) {
        CancelFnList.forEach(fn => {
          fn();
        });
      }
    };
  }, [loadAbstracts]);

  return (
    <div>
      <TitleContainer>
        <TitleWithIcon title="摘要" prefixIcon={BookmarkIcon} />
        <PvCount count={pvCount} />
      </TitleContainer>
      <ConditionContainer {...colors}>
        <JTooltip desc={tableDesc} />
        <SwitchSelector label="含推估收益" value={simulate} changeFn={handleSimulateSwitch} />
      </ConditionContainer>
      <JTable columns={tableColumns} data={assets} summaryData={summary} />
    </div>
  );
};

const ConditionContainer = styled.div`
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 15px;
  padding-right: 7px;
  background-color: ${p => p.conditionContainerBgColor};
  border-bottom: ${p => (p.transparent ? '0px' : `1px solid ${p.hrColor}`)};
`;
const PvIdName = styled.div`
  display: flex;
`;

export default Abstract;
