import React, { useState, useMemo, useCallback, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
//common
import { IntegerFormat } from '../../../commons/utils';
//component
import TitleContainer from '../../../components/Title/TitleContainer';
import TitleWithIcon from '../../../components/Title/TitleWithIcon';
import Table from '../../../components/Table/MTable';
//icon
import { ReactComponent as DocumentIcon } from '../../../images/svg/document.svg';

import { useProvided } from '../../../stores/index';
import useAuth from '../../../stores/useAuth';
import useAssetTheme from '../../../stores/useAssetTheme';

const cashoutStatusCodes = status => {
  switch (status) {
    case 0:
      return '處理中';
    case 1:
      return '已完成';
    default:
      return '未知';
  }
};

const CancelFnList = [];
const WithdrawRecord = () => {
  const { currentSPV } = useProvided(useAuth);
  const { solaTheme } = useProvided(useAssetTheme);
  const [pageSize, setPageSize] = useState(10);
  const [withdrawData, setWithdrawData] = useState([]);

  const handlePageSizeChange = size => () => {
    if (pageSize !== size) {
      setPageSize(size);
    }
  };

  const tableColumns = useMemo(() => {
    return [
      {
        title: '時間',
        field: 'date',
        headerStyle: { textAlign: 'center' },
        cellStyle: {
          width: '15%',
          textAlign: 'center'
        }
      },
      {
        title: '金額',
        field: 'amount',
        render: row => <span>{IntegerFormat(row.amount, '', '元')}</span>,
        headerStyle: { textAlign: 'right' },
        cellStyle: {
          width: '15%',
          textAlign: 'right'
        }
      },
      {
        title: '狀態',
        field: 'status',
        render: row => <span>{cashoutStatusCodes(row.status)}</span>,
        headerStyle: { textAlign: 'center' },
        cellStyle: {
          width: '15%',
          textAlign: 'center'
        }
      },
      {
        title: '備註',
        field: 'comment',
        headerStyle: { textAlign: 'left' },
        cellStyle: {
          textAlign: 'left'
        }
      }
    ];
  }, []);

  const loadData = useCallback(() => {
    const source = axios.CancelToken.source();
    CancelFnList.push(source.cancel);
    axios
      .get(`/api/v1/assets/cashout`, {
        headers: { Authorization: currentSPV.token },
        cancelToken: source.token
      })
      .then(res => {
        if (res && res.data) {
          setWithdrawData([...res.data.result.cashoutList]);
        }
      })
      .catch(err => {
        if (!axios.isCancel(err)) {
          console.error(err);
        }
      });
  }, [currentSPV]);

  useEffect(() => {
    loadData();

    return () => {
      if (CancelFnList && CancelFnList.length > 0) {
        CancelFnList.forEach(fn => {
          fn();
        });
      }
    };
  }, [loadData]);

  const filterStyle = {
    bgColor: solaTheme.componentColors.FilterBgColor,
    txColor: solaTheme.componentColors.FilterTxColor,
    borderColor: solaTheme.componentColors.FilterBorderColor,
    selectBgColor: solaTheme.componentColors.FilterSelectBgColor,
    selectTxColor: solaTheme.componentColors.FilterSelectTxColor,
    selectBorderColor: solaTheme.componentColors.FilterSelectBorderColor
  };

  return (
    <div>
      <TitleContainer>
        <TitleWithIcon title="提款紀錄" prefixIcon={DocumentIcon} />
      </TitleContainer>
      <TitleContainer hideTop>
        <PageSizeContainer styles={filterStyle}>
          <PageSizeButton
            className={pageSize === 10 ? 'btn-select' : 'btn'}
            onClick={handlePageSizeChange(10)}
          >
            10筆
          </PageSizeButton>
          <PageSizeButton
            className={pageSize === 15 ? 'btn-select' : 'btn'}
            onClick={handlePageSizeChange(15)}
          >
            15筆
          </PageSizeButton>
          <PageSizeButton
            className={pageSize === 20 ? 'btn-select' : 'btn'}
            onClick={handlePageSizeChange(20)}
          >
            20筆
          </PageSizeButton>
          <PageSizeButton
            className={pageSize === 25 ? 'btn-select' : 'btn'}
            onClick={handlePageSizeChange(25)}
          >
            25筆
          </PageSizeButton>
        </PageSizeContainer>
      </TitleContainer>
      <TablePanel>
        <Table columns={tableColumns} data={withdrawData} pageSize={pageSize} />
      </TablePanel>
    </div>
  );
};

const PageSizeContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 15px;

  .btn {
    color: ${p => p.styles.txColor};
    border: 1px solid ${p => p.styles.borderColor};
    background-color: ${p => p.styles.bgColor};
  }
  .btn-select {
    color: ${p => p.styles.selectTxColor};
    border: 1px solid ${p => p.styles.selectBorderColor};
    background-color: ${p => p.styles.selectBgColor};
  }
`;
const PageSizeButton = styled.div`
  width: 60px;
  border-radius: 100px;
  margin: 0px 16px 0px 0px;
  text-align: center;
  cursor: pointer;
`;
const TablePanel = styled.div`
  padding-top: 30px;
`;

export default WithdrawRecord;
