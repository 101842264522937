import React from 'react';
import styled from 'styled-components';

import { useProvided } from '../../stores/index';
import useAssetTheme from '../../stores/useAssetTheme';

const AvatarTitle = props => {
  const { solaTheme } = useProvided(useAssetTheme);

  const colors = {
    iconBg: solaTheme.componentColors.AvatarIconBgColor,
    iconTx: solaTheme.componentColors.AvatarIconTxColor,
    name: solaTheme.componentColors.AvatarNameTxColor,
    email: solaTheme.componentColors.AvatarEmailTxColor
  };

  return (
    <AvatarTitleContainer colors={colors}>
      <AvatarHeadIcon className="icon">{props.userName.substring(0, 1)}</AvatarHeadIcon>
      <div>
        <AvatarName className="name">{props.userName}</AvatarName>
        <AvatarEmail className="email">{props.email}</AvatarEmail>
      </div>
    </AvatarTitleContainer>
  );
};

const AvatarTitleContainer = styled.div`
  height: 70px;
  display: flex;
  align-items: center;
  margin: 10px 0px;

  .icon {
    background-color: ${p => p.colors.iconBg};
    color: ${p => p.colors.iconTx};
  }
  .name {
    color: ${p => p.colors.name};
  }
  .email {
    color: ${p => p.colors.email};
  }
`;
const AvatarHeadIcon = styled.div`
  width: 40px;
  height: 40px;

  font-size: 20px;
  border-radius: 20px;
  margin-right: 7px;
  padding-bottom: 1px;
  padding-right: 1px;

  display: flex;
  justify-content: center;
  align-items: center;
`;
const AvatarName = styled.div``;
const AvatarEmail = styled.div`
  font-size: 12px;
`;

export default AvatarTitle;
