import React from 'react';
import styled from 'styled-components';
import { NumberFormat, PercentFormat } from '../../commons/utils';

//
import { useProvided } from '../../stores/index';
import useAssetTheme from '../../stores/useAssetTheme';

const AssetInfo = ({ data }) => {
  const { solaTheme } = useProvided(useAssetTheme);

  const infoStyle = {
    hrColor: solaTheme.componentColors.DAIHrColor,
    titleColor: solaTheme.componentColors.DAITitleTxColor,
    valueColor: solaTheme.componentColors.DAITxColor,
  };

  return (
    <InfoContainer styles={infoStyle}>
      <InfoRow className="infoRow">
        <InfoTitleColumn className="infoTitle">總資產</InfoTitleColumn>
        <InfoValueColumn className="infoValue">{NumberFormat(data.totalSttw, '', '元')}</InfoValueColumn>
        <InfoTitleColumn className="infoTitle">資金餘額</InfoTitleColumn>
        <InfoValueColumn className="infoValue">{NumberFormat(data.remainedSttw, '', '元')}</InfoValueColumn>
      </InfoRow>
      <InfoRow className="infoRow">
        <InfoTitleColumn className="infoTitle">今年收益</InfoTitleColumn>
        <InfoValueColumn className="infoValue">
          {NumberFormat(data.thisYearBillProfit, '', '元')}
        </InfoValueColumn>
        <InfoTitleColumn className="infoTitle">今年收益報酬率</InfoTitleColumn>
        <InfoValueColumn className="infoValue">{PercentFormat(data.thisYearRoi)}</InfoValueColumn>
      </InfoRow>
      <InfoRow className="infoRow">
        <InfoTitleColumn className="infoTitle">累積收益</InfoTitleColumn>
        <InfoValueColumn className="infoValue">
          {NumberFormat(data.totalBillProfit, '', '元')}
        </InfoValueColumn>
        <InfoTitleColumn className="infoTitle">累積收益報酬率</InfoTitleColumn>
        <InfoValueColumn className="infoValue">{PercentFormat(data.totalRoi)}</InfoValueColumn>
      </InfoRow>
    </InfoContainer>
  );
};

const InfoContainer = styled.div`
  width: 95%;
  .infoRow {
    border-bottom: 1px solid ${(p) => p.styles.hrColor};
  }
  .infoTitle {
    color: ${(p) => p.styles.titleColor};
  }
  .infoValue {
    color: ${(p) => p.styles.valueColor};
  }
`;
const InfoRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 15px;
  padding-bottom: 15px;
`;
const InfoTitleColumn = styled.div`
  width: 25%;
  text-align: left;
  padding-left: 15px;
`;
const InfoValueColumn = styled.div`
  width: 25%;
  text-align: right;
`;
/*
const InfoTotalColumn = styled.div`
  width: 50%;
  display: flex;
  justify-content: flex-end;
`;
const InfoProfitIcon = styled.div`
  margin-right: 10px;
  .svgBase {
    fill: ${(p) => p.iconColor};
  }
`;
const InfoProfitValue = styled.div`
  font-size: 36px;
  color: ${(p) => p.txColor};
`;
const InfoProfitUnit = styled.div`
  margin-top: 20px;
  margin-left: 10px;
  color: ${(p) => p.txColor};
`;
*/

export default AssetInfo;
