import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import Day from './day';
import EventDetail from './detail';
//
import { useProvided } from '../../stores/index';
import useAssetTheme from '../../stores/useAssetTheme';

const GetWeekTitleDOM = (color) => {
  return (
    <WeekContainer txColor={color}>
      <WeekItem>週一</WeekItem>
      <WeekItem>週二</WeekItem>
      <WeekItem>週三</WeekItem>
      <WeekItem>週四</WeekItem>
      <WeekItem>週五</WeekItem>
      <WeekItem>週六</WeekItem>
      <WeekItem>週日</WeekItem>
    </WeekContainer>
  );
};

const Calendar = ({
  selectDate,
  anchorDate,
  rangeBeginDate,
  rangeEndDate,
  dataPv,
  dataSimulate,
  dataBill,
  dataSimulateAndBillDetail,
  dateChangeFn,
}) => {
  const { solaTheme } = useProvided(useAssetTheme);
  const weekTitleDOM = GetWeekTitleDOM(solaTheme.componentColors.CalendarHeaderTxColor);
  const weekDOM = [];
  let dayDOM = [];
  let index = 0;
  let currentWeek = 0;
  let lastWeek = -1;
  const currentDate = moment(rangeBeginDate, 'YYYY-MM-DD');

  while (currentDate.isSameOrBefore(rangeEndDate)) {
    //
    currentWeek = Math.floor(index / 7);
    if (currentWeek !== lastWeek) {
      lastWeek = currentWeek;
      dayDOM = [];
      weekDOM.push(<WeekDayContainer key={currentWeek}>{dayDOM}</WeekDayContainer>);
    }
    //
    const currentDateText = currentDate.format('YYYY-MM-DD');
    const isSelected = moment(selectDate).isSame(currentDate);
    const isSameMonth = moment(anchorDate).month() === moment(currentDate).month();
    //
    const thisDayPvData = [];
    if (dataPv && dataPv.length > 0) {
      dataPv.forEach((d, index) => {
        if (d.date === currentDateText) {
          thisDayPvData.push(d);
        }
      });
    }

    const thisDaySimulateData = [];
    if (dataSimulate && dataSimulate.length > 0) {
      dataSimulate.forEach((d, index) => {
        if (d.date === currentDateText) {
          thisDaySimulateData.push(d);
        }
      });
    }

    const thisDayBillData = [];
    if (dataBill && dataBill.length > 0) {
      dataBill.forEach((d, index) => {
        if (d.date === currentDateText) {
          thisDayBillData.push(d);
        }
      });
    }

    //
    if (isSelected) {
      weekDOM.push(
        <WeekDayContainer key="event-detail">
          <EventDetail
            dataPv={thisDayPvData}
            dataSimulateAndBillDetail={dataSimulateAndBillDetail}
            selectDate={selectDate}
          />
        </WeekDayContainer>
      );
    }
    //
    dayDOM.push(
      <Day
        key={index}
        date={currentDateText}
        isSelected={isSelected}
        isSameMonth={isSameMonth}
        dataPv={thisDayPvData}
        dataSimulate={thisDaySimulateData}
        dataBill={thisDayBillData}
        dateChangeFn={dateChangeFn}
      />
    );

    currentDate.add(1, 'days');
    index++;
  }

  return (
    <div>
      {weekTitleDOM}
      {weekDOM}
    </div>
  );
};

const WeekContainer = styled.div`
  width: 98%;
  height: 50px;
  margin: 1%;
  color: ${(p) => p.txColor};

  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const WeekItem = styled.div`
  width: 140px;
  text-align: center;
`;
const WeekDayContainer = styled.div`
  width: 98%;
  margin: 1%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export default Calendar;
