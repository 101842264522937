import { useState } from 'react';
import { Container } from './index';
import { DarkTheme } from '../themes/dark';
import { WhiteTheme } from '../themes/white';

let initialItem = {};
let theme = window.localStorage.getItem('theme');
switch (theme) {
  case 'dark':
    initialItem = { ...DarkTheme };
    break;
  default:
    initialItem = { ...WhiteTheme };
    window.localStorage.setItem('theme', 'white');
    break;
}

//custome hook for shared theme.
const useAssetTheme = () => {
  const [solaTheme, setSolaTheme] = useState(initialItem);

  const changeTheme = () => {
    const t = window.localStorage.getItem('theme');
    if (t === 'dark') {
      window.localStorage.setItem('theme', 'white');
      setSolaTheme({ ...WhiteTheme });
    } else {
      window.localStorage.setItem('theme', 'dark');
      setSolaTheme({ ...DarkTheme });
    }
  };

  //global shared variables or functions.
  return { solaTheme, changeTheme };
};

//remenber use Container to wrap
export default Container(useAssetTheme);
