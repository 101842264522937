import React from 'react';
import styled from 'styled-components';
import { useProvided } from '../../stores/index';
import useAssetTheme from '../../stores/useAssetTheme';

const HintWithIcon = ({ icon, text, styles = {} }) => {
  const { solaTheme } = useProvided(useAssetTheme);
  if (icon && text) {
    return (
      <HintContainer color={solaTheme.componentColors.HintTxColor} {...styles}>
        {icon ? <IconSvg>{React.createElement(icon)}</IconSvg> : null}
        <div>{text}</div>
      </HintContainer>
    );
  } else {
    return null;
  }
};

const HintContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${p => p.align || 'flex-start'};

  width: ${p => p.width || ''};
  font-size: 14px;
  color: ${p => p.color};
  padding-left: 20px;
  padding-right: 20px;

  .svgBase {
    fill: ${p => p.color};
  }
`;

const IconSvg = styled.div`
  margin-right: 10px;
  padding-top: 3px;
`;

export default HintWithIcon;
