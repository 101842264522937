import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useProvided } from '../../stores/index';
import useAssetTheme from '../../stores/useAssetTheme';

const AvatarDetail = props => {
  const { solaTheme } = useProvided(useAssetTheme);

  const containerStyle = {
    bgColor: solaTheme.componentColors.AvatarDetailBgColor,
    bgHoverColor: solaTheme.componentColors.AvatarDetailBgHoverColor,
    hrColor: solaTheme.componentColors.AvatarDetailHrColor,
    txColor: solaTheme.componentColors.AvatarDetailTxColor,
    txHoverColor: solaTheme.componentColors.AvatarDetailTxHoverColor
  };

  if (props.to) {
    return (
      <div>
        <Link to={props.to}>
          <AvatarDetailContainer onClick={props.action || null} styles={containerStyle}>
            <ActionIcon>{React.createElement(props.icon)}</ActionIcon>
            <ActionName>{props.name}</ActionName>
          </AvatarDetailContainer>
        </Link>
      </div>
    );
  } else {
    return (
      <div>
        <AvatarDetailContainer onClick={props.action || null} styles={containerStyle}>
          <ActionIcon>{React.createElement(props.icon)}</ActionIcon>
          <ActionName>{props.name}</ActionName>
        </AvatarDetailContainer>
      </div>
    );
  }
};

const AvatarDetailContainer = styled.div`
  width: 85%;
  height: 45px;
  padding: 3% 0% 3% 15%;
  background-color: ${p => p.styles.bgColor};
  border-bottom: 1px solid ${p => p.styles.hrColor};
  color: ${p => p.styles.txColor};

  display: flex;
  align-items: center;

  cursor: pointer;

  &:hover {
    background-color: ${p => p.styles.bgHoverColor};
    color: ${p => p.styles.txHoverColor};
  }

  &:hover .svgHover {
    fill: ${p => p.styles.txHoverColor};
  }
`;

const ActionIcon = styled.div`
  margin-right: 10px;
`;

const ActionName = styled.span`
  font-size: 14px;
`;

export default AvatarDetail;
