import React from 'react';
import styled from 'styled-components';
import { useProvided } from '../../stores/index';
import useAssetTheme from '../../stores/useAssetTheme';

const TitleContainer = props => {
  const { solaTheme } = useProvided(useAssetTheme);
  const titleBarStyles = {
    transparent: props.transparent,
    hideTop: props.hideTop,
    hrColor: solaTheme.componentColors.TitleContainerHrColor,
    bgColor: solaTheme.componentColors.TitleContainerBgColor
  };

  return <Div {...titleBarStyles}>{props.children}</Div>;
};

const Div = styled.div`
  height: 50px;
  padding: 6px 0px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  background-color: ${p => (p.transparent ? '' : p.bgColor)};
  border-top: ${p => (p.hideTop ? '0px' : p.transparent ? '0px' : `1px solid ${p.hrColor}`)};
  border-bottom: ${p => (p.transparent ? '0px' : `1px solid ${p.hrColor}`)};
`;
export default TitleContainer;
