import React, { useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { DatePicker } from '@material-ui/pickers';
import { ReactComponent as Calendar2Icon } from '../../images/svg/calendar_v2.svg';
//
import { useProvided } from '../../stores/index';
import useAssetTheme from '../../stores/useAssetTheme';

const DateSelector = ({ selectDate, dateChangeFn, navButton = true }) => {
  const { solaTheme } = useProvided(useAssetTheme);
  const [isOpen, setIsOpen] = useState(false);

  const handlePrevMonthClick = () => {
    const newDate = moment(selectDate)
      .subtract(1, 'month')
      .startOf('month')
      .format('YYYY-MM-DD');

    dateChangeFn(newDate);
  };

  const handleNextMonthClick = () => {
    const newDate = moment(selectDate)
      .add(1, 'month')
      .startOf('month')
      .format('YYYY-MM-DD');

    dateChangeFn(newDate);
  };

  const handleDateChange = date => {
    const newDate = moment(date).format('YYYY-MM-DD');
    if (newDate !== selectDate) {
      dateChangeFn(newDate);
    }
  };
  const handleClose = () => {
    setIsOpen(false);
  };

  const dateStyles = {
    txColor: solaTheme.componentColors.DateSelectorTxColor,
    bgColor: solaTheme.componentColors.DateSelectorBgColor
  };

  return (
    <DateSelectorContainer {...dateStyles}>
      {navButton ? (
        <MonthNavButton className="btn" onClick={handlePrevMonthClick}>
          上個月
        </MonthNavButton>
      ) : null}
      <DateContainer onClick={() => setIsOpen(true)}>
        <DateIcon>
          <Calendar2Icon />
        </DateIcon>
        <div className="label">{selectDate}</div>
      </DateContainer>
      <DateHidden>
        <DatePicker
          open={isOpen}
          format="YYYY-MM-DD"
          value={selectDate}
          onChange={handleDateChange}
          onClose={handleClose}
          showTodayButton
          cancelLabel="關閉"
          okLabel="確認"
          todayLabel="今日"
        />
      </DateHidden>
      {navButton ? (
        <MonthNavButton className="btn" onClick={handleNextMonthClick}>
          下個月
        </MonthNavButton>
      ) : null}
    </DateSelectorContainer>
  );
};

const DateSelectorContainer = styled.div`
  min-width: 300px;

  display: flex;
  justify-content: center;
  align-items: center;

  .btn {
    background-color: ${p => p.bgColor};
    color: ${p => p.txColor};
    border: 1px solid ${p => p.txColor};
  }
  .label {
    color: ${p => p.txColor};
  }

  .svgBase {
    fill: ${p => p.txColor};
  }
`;

const MonthNavButton = styled.button`
  width: 80px;
  border-radius: 100px;
  text-align: center;
  cursor: pointer;
  margin: 5px 20px;
`;

const DateContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
const DateIcon = styled.div`
  width: 28px;
  height: 28px;
  margin-right: 5px;
`;
const DateHidden = styled.div`
  display: none;
`;

export default DateSelector;
