import React from 'react';
import styled from 'styled-components';
import LogoSvg from '../../images/svg/logo.svg';
//
import { useProvided } from '../../stores/index';
import useAssetTheme from '../../stores/useAssetTheme';

function Header() {
  const { solaTheme } = useProvided(useAssetTheme);

  return (
    <HeaderBar bgColor={solaTheme.componentColors.HeaderBgColor}>
      <Logo id="logo" src={LogoSvg} />
    </HeaderBar>
  );
}

const HeaderBar = styled.div`
  width: 100vw;
  height: 5vh;
  max-height: 55px;

  position: absolute;
  top: 0px;
  z-index: 1100;

  background-color: ${props => props.bgColor};

  display: flex;
  justify-content: center;
  align-items: center;
`;

const Logo = styled.img`
  height: 3vh;
  max-height: 50px;
`;

export default Header;
