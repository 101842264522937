import React, { useState, useEffect, useCallback, useMemo } from 'react';
import axios from 'axios';
import moment from 'moment';
import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import PvChart from '../../components/PvInfo/PvChart';
import PvData from '../../components/PvInfo/PvData';
import PvStage from '../../components/PvInfo/PvStage';
import PvMap from '../../components/PvInfo/PvMap';
import PvImage from '../../components/PvInfo/PvImage';
//
import { useProvided } from '../../stores/index';
import useAuth from '../../stores/useAuth';
import useAssetTheme from '../../stores/useAssetTheme';

const StagesFileName = {
  已簽訂租約: '租約',
  台電併聯審查核可: '併聯審查意見書',
  取得同意備案函: '同意備案函',
  簽訂電能購售契約: '電能購售契約',
  已併聯掛表: '併聯試運轉函',
  完成設備登記: '設備登記函',
  開始正式售電: '購售電能函'
};
const CancelFnList = [];
const PvDataPage = props => {
  const { currentSPV } = useProvided(useAuth);
  const { solaTheme } = useProvided(useAssetTheme);
  //view control
  const [isMarkerShown, setIsMarkerShown] = useState(false);
  const [currentClickedStage, setCurrentClickedStage] = useState(-1);
  const [open, setOpen] = useState(false);
  //const [stageContent, setStageContent] = useState([]);
  //data
  const [pvData, setPvData] = useState({});
  const [powerData, setPowerData] = useState({});
  const [pvImgs, setPvImg] = useState([]);
  const [pvCoord, setPvCoord] = useState({});
  const [pvStage, setPvStage] = useState([]);

  const handleExpand = clickedStage => () => {
    setCurrentClickedStage(clickedStage);
    setOpen(true);
  };
  const handleDownloadFile = useCallback(
    fileName => () => {
      axios
        .post(
          '/api/v1/pv/file',
          { filename: fileName },
          { headers: { Authorization: currentSPV.token }, responseType: 'blob' }
        )
        .then(res => {
          if (res && res.data) {
            if (res.status === false) {
              //download fail.
            } else {
              const url = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }));
              //
              window.open(url);
              //
              const link = document.createElement('a');
              link.href = url;
              link.download = fileName;
              link.click();
            }
          }
        })
        .catch(error => {
          console.error(error);
        });
    },
    [currentSPV]
  );
  const handleDialogToggle = () => {
    setOpen(m => !m);
  };
  const dialogContent = useMemo(() => {
    let rtnDom = null;

    if (pvStage && currentClickedStage && pvStage[currentClickedStage]) {
      const { files } = pvStage[currentClickedStage];

      if (files) {
        const hasMultiFile = files.length > 1;
        let filename = '';
        rtnDom = [];
        files.forEach((file, index) => {
          filename = `${StagesFileName[currentClickedStage]}${hasMultiFile ? index + 1 : ''}`;

          rtnDom.push(
            <FileItem key={index} title={file} onClick={handleDownloadFile(file)} className="item">
              {filename}
            </FileItem>
          );
        });
      }
    }

    return rtnDom;
  }, [pvStage, currentClickedStage, handleDownloadFile]);

  const loadPvData = useCallback(() => {
    const source = axios.CancelToken.source();
    CancelFnList.push(source.cancel);
    axios
      .get(`/api/v1/pv/project/${props.pvId}`, {
        headers: { Authorization: currentSPV.token },
        cancelToken: source.token
      })
      .then(res => {
        if (res.data.status === true) {
          setPvData(res.data.result.pvData);
          setPvCoord({
            lat: res.data.result.pvData.coordLat,
            lng: res.data.result.pvData.coordLng
          });
          setIsMarkerShown(true);
        } else {
          // TODO: handle error_code, msg
        }
      })
      .catch(err => {
        if (!axios.isCancel(err)) {
          console.error(err);
        }
      });
  }, [props.pvId, currentSPV]);

  const loadPowerData = useCallback(() => {
    // tomorrow
    let endDate = moment()
      .add(1, 'days')
      .format('YYYY-MM-DD');
    // date of year ago
    let startDate = moment()
      .subtract(1, 'year')
      .format('YYYY-MM-DD');

    const source = axios.CancelToken.source();
    CancelFnList.push(source.cancel);
    axios
      .get(`/api/v1/pv/values/` + props.pvId, {
        params: {
          start: startDate,
          end: endDate
        },
        headers: { Authorization: currentSPV.token },
        cancelToken: source.token
      })
      .then(res => {
        if (res.data.status === true) {
          setPowerData(res.data.result.powerData);
        } else {
          // TODO: handle error_code, msg
        }
      })
      .catch(err => {
        if (!axios.isCancel(err)) {
          console.error(err);
        }
      });
  }, [props.pvId, currentSPV]);

  const loadPvImg = useCallback(() => {
    const source = axios.CancelToken.source();
    CancelFnList.push(source.cancel);
    axios
      .get(`/api/v1/pv/img/` + props.pvId, {
        headers: { Authorization: currentSPV.token },
        cancelToken: source.token
      })
      .then(res => {
        if (res.data.status === true) {
          setPvImg(res.data.result.pvImgs);
        } else {
          // TODO: handle error_code, msg
        }
      })
      .catch(err => {
        if (!axios.isCancel(err)) {
          console.error(err);
        }
      });
  }, [props.pvId, currentSPV]);

  const loadPvStage = useCallback(() => {
    const source = axios.CancelToken.source();
    CancelFnList.push(source.cancel);
    axios
      .get(`/api/v1/pv/stage/` + props.pvId, {
        headers: { Authorization: currentSPV.token },
        cancelToken: source.token
      })
      .then(res => {
        if (res.data.status === true) {
          setPvStage(res.data.result.pvStage);
        } else {
          // TODO: handle error_code, msg
        }
      })
      .catch(err => {
        if (!axios.isCancel(err)) {
          console.error(err);
        }
      });
  }, [props.pvId, currentSPV]);

  useEffect(() => {
    loadPvData();
    loadPowerData();
    loadPvImg();
    loadPvStage();

    return () => {
      if (CancelFnList && CancelFnList.length > 0) {
        CancelFnList.forEach(fn => {
          fn();
        });
      }
    };
  }, [loadPvData, loadPowerData, loadPvImg, loadPvStage]);

  const dialogStyle = {
    titleBgColor: solaTheme.componentColors.PvDialogTitleBgColor,
    titleTxColor: solaTheme.componentColors.PvDialogTitleTxColor,
    itemBorderColor: solaTheme.componentColors.PvDialogItemBorderColor,
    itemHoverTxColor: solaTheme.componentColors.PvDialogItemHoverTxColor,
    itemHoverBgColor: solaTheme.componentColors.PvDialogItemHoverBgColor
  };

  return (
    <div>
      <PvData data={pvData} />
      <PvStage stage={pvData.stage} stageDetail={pvStage} onExpand={handleExpand} />
      <PvChart powerData={powerData} />
      <ColumnsContainer>
        <Column width="35%">
          <PvMap pvCoord={pvCoord} isMarkerShown={isMarkerShown} />
        </Column>
        <Column width="65%">
          <PvImage pvImgs={pvImgs} />
        </Column>
      </ColumnsContainer>
      <Dialog open={open} onClose={handleDialogToggle} style={{ paddingLeft: '240px' }}>
        <FileContainer {...dialogStyle}>
          <FileTitle className="title">點擊下載並開啟檔案</FileTitle>
          <FileContent>{dialogContent}</FileContent>
        </FileContainer>
      </Dialog>
    </div>
  );
};

const ColumnsContainer = styled.div`
  width: 100%;
  height: 450px;
  display: inline-flex;
`;
const Column = styled.div`
  width: ${p => p.width || '50%'};
  height: 100%;
`;

const FileContainer = styled.div`
  width: 200px;
  height: 300px;

  .title {
    background-color: ${p => p.titleBgColor};
    color: ${p => p.titleTxColor};
  }
  .item {
    border-bottom: 1px solid ${p => p.itemBorderColor};

    &:hover {
      color: ${p => p.itemHoverTxColor};
      background-color: ${p => p.itemHoverBgColor};
    }
  }
`;
const FileTitle = styled.div`
  width: 100%;
  text-align: center;
  padding: 10px 0px;
`;
const FileContent = styled.div`
  width: 100%;
  height: 250px;
  overflow-y: auto;
`;
const FileItem = styled.div`
  width: 100%;
  padding: 10px 0px;
  cursor: pointer;
  text-align: center;
`;

export default PvDataPage;
