import React from 'react';
import styled from 'styled-components';
import { useProvided } from '../../stores/index';
import { ReactComponent as PowerIcon } from '../../images/svg/power.svg';
import useAssetTheme from '../../stores/useAssetTheme';

const PvCount = ({ count }) => {
  const { solaTheme } = useProvided(useAssetTheme);
  const colors = {
    icon: solaTheme.componentColors.TitleTxColor,
    title: solaTheme.componentColors.TitleTxColor,
    value: solaTheme.componentColors.PvCountTextColor
  };

  return (
    <PanelPvCount color={colors}>
      <IconSvg>{React.createElement(PowerIcon)}</IconSvg>
      <Title className="title">電廠數</Title>
      <Value className="value">{count}</Value>
    </PanelPvCount>
  );
};

const PanelPvCount = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  padding-right: 25px;

  .svgBase {
    fill: ${p => p.color.icon || '#fff'};
  }
  .title {
    color: ${p => p.color.title};
  }
  .value {
    color: ${p => p.color.value};
  }
`;
const IconSvg = styled.div`
  margin-right: 5px;
  width: 18px;
  height: 18px;
`;
const Title = styled.div`
  margin-right: 10px;
  font-weight: bold;
  font-size: 17px;
`;
const Value = styled.div`
  font-size: 37px;
`;

export default PvCount;
