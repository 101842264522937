import React, { useState, useCallback, useEffect, useMemo } from 'react';
import axios from 'axios';
import moment from 'moment';
import styled from 'styled-components';

//common
import TitleContainer from '../../../components/Title/TitleContainer';
import TitleWithIcon from '../../../components/Title/TitleWithIcon';
import { PercentFormat, GenerateYearSelector } from '../../../commons/utils';
import useAssetTheme from '../../../stores/useAssetTheme';
//component
import JTable from '../../../components/Table/JTable';
import JTooltip from '../../../components/Tooltip/JTooltip';
import SwitchSelector from '../../../components/Selector/switchSelector';
import DropDownList from '../../../components/Selector/dropdownlist';

//icon
import { ReactComponent as ListWithDotIcon } from '../../../images/svg/list_with_dot.svg';

import { useProvided } from '../../../stores/index';
import useAuth from '../../../stores/useAuth';

const GenerateMonthColumns = isSimulate => {
  //sub-columns
  const monthItem = [];
  for (let i = 1; i <= 12; i++) {
    const fieldName = isSimulate ? `${i}_sim` : `${i}`;
    monthItem.push({
      title: `${i}月`,
      field: fieldName,
      render: row => <span>{PercentFormat(row[fieldName])}</span>,
      cellStyle: {
        width: '100px'
      }
    });
  }

  //final columns
  const rtnVal = [
    {
      title: '電廠',
      field: 'pvId',
      render: row => (
        <div>
          <div>{row.pvId && row.pvId + '-'}</div>
          <div>{row.pvName}</div>
        </div>
      ),
      cellStyle: {
        width: '200px'
      }
    },
    {
      title: '資產占比',
      field: 'weight',
      render: row => <span>{PercentFormat(row.weight)}</span>,
      headerStyle: {
        paddingRight: '20px'
      },
      cellStyle: {
        width: '80px',
        paddingRight: '20px'
      }
    },
    {
      title: '年平均發電達成率',
      subColumns: monthItem
    }
  ];

  return rtnVal;
};

const tableDesc = [
  { colName: '資產占比', colDesc: '投入該電廠的金額 ÷ 投入所有電廠的金額 × 100%。' },
  {
    colName: '#月',
    colDesc: '所選年份之 #月 的平均每日發電量 × 365 ÷ (電廠年度保證發電量 × 容量) × 100%。'
  }
];
const CancelFnList = [];
const AchieveRateCycle = () => {
  const { solaTheme } = useProvided(useAssetTheme);
  const [searchYear, setSearchYear] = useState(moment().format('YYYY'));
  const [cycleData, setCycleData] = useState([]);
  const [summaryData, setSummaryData] = useState([]);
  const [yearList, setYearList] = useState([]);

  const [simulate, setSimulate] = useState(true);
  const { currentSPV } = useProvided(useAuth);

  const handleYearChange = event => {
    if (event.target.value !== searchYear) {
      setSearchYear(event.target.value);
    }
  };
  const loadYearList = useCallback(() => {
    setYearList(GenerateYearSelector());
  }, []);
  const handleSimulateSwitch = () => {
    setSimulate(s => !s);
  };

  const tableColumns = useMemo(() => {
    return GenerateMonthColumns(simulate);
  }, [simulate]);

  //Data Loading.
  const loadAchieveRate = useCallback(() => {
    const source = axios.CancelToken.source();
    CancelFnList.push(source.cancel);
    axios
      .get(`/api/v1/assets/achieverate/cycle?year=${searchYear}`, {
        headers: { Authorization: currentSPV.token },
        cancelToken: source.token
      })
      .then(res => {
        if (res && res.data && res.data.status && res.data.result) {
          setCycleData(res.data.result.achieveRateCycle.achieveRate);
          setSummaryData(res.data.result.achieveRateCycle.summary);
        } else {
          setCycleData([]);
          setSummaryData([]);
        }
      })
      .catch(err => {
        if (!axios.isCancel(err)) {
          console.error(err);
        }
      });
  }, [currentSPV, searchYear]);

  const colors = {
    conditionContainerBgColor: solaTheme.componentColors.ConditionContainerBgColor,
    hrColor: solaTheme.componentColors.TitleContainerHrColor
  };

  //僅載入乙次
  useEffect(() => {
    loadYearList();
    loadAchieveRate();

    return () => {
      if (CancelFnList && CancelFnList.length > 0) {
        CancelFnList.forEach(fn => {
          fn();
        });
      }
    };
  }, [loadYearList, loadAchieveRate]);

  return (
    <div>
      <TitleContainer>
        <TitleWithIcon title="達成率分析(週期)" prefixIcon={ListWithDotIcon} />
      </TitleContainer>
      <ConditionContainer {...colors}>
        <JTooltip desc={tableDesc} />
        <DropDownList label="選擇年份" value={searchYear} options={yearList} changeFn={handleYearChange} />
        <SwitchSelector label="含推估收益" value={simulate} changeFn={handleSimulateSwitch} />
      </ConditionContainer>
      <JTable columns={tableColumns} data={cycleData} summaryData={summaryData} mainWidth="1500px" />
    </div>
  );
};

const ConditionContainer = styled.div`
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 15px;
  padding-right: 7px;
  background-color: ${p => p.conditionContainerBgColor};
  border-bottom: ${p => (p.transparent ? '0px' : `1px solid ${p.hrColor}`)};
`;

export default AchieveRateCycle;
