import React, { useState } from 'react';
import styled from 'styled-components';
import MaterialTable from 'material-table';
import Pagination from './pagination';
import EmptyMsg from '../../EmptyMsg';
//
import { useProvided } from '../../../stores/index';
import useAssetTheme from '../../../stores/useAssetTheme';

const Table = ({ columns, data, pageSize = 10, showHeader = true, showEmpty = true }) => {
  const { solaTheme } = useProvided(useAssetTheme);
  const [selectRow, setSelectRow] = useState(null);
  const [currentPageSize, setCurrentPageSize] = useState(10);

  if (columns && data && data.length) {
    return (
      <TableContainer>
        <MaterialTable
          components={{
            Pagination: (props) => {
              if (currentPageSize !== pageSize) {
                setCurrentPageSize(pageSize);
                return <Pagination props={props} pageRows={pageSize} />;
              } else {
                return <Pagination props={props} />;
              }
            },
          }}
          columns={columns}
          data={data}
          localization={{
            body: {
              emptyDataSourceMessage: '查無資料顯示',
            },
          }}
          style={{ backgroundColor: solaTheme.componentColors.ContentBgColor }}
          onRowClick={(evt, selectedRow) => setSelectRow(selectedRow)}
          options={{
            toolbar: false,
            paging: data.length > pageSize,
            pageSize: pageSize,
            header: showHeader,
            headerStyle: {
              color: solaTheme.componentColors.MTHeaderTxColor,
              backgroundColor: solaTheme.componentColors.MTHeaderBgColor,
              borderBottomColor: solaTheme.componentColors.MTHeaderBorderColor,
              fontSize: '14px',
              padding: '10px 14px 10px 14px',
            },
            rowStyle: (row) => {
              const isSelected = data.length > 1 && selectRow && row.tableData.id === selectRow.tableData.id;
              return {
                backgroundColor: isSelected
                  ? solaTheme.componentColors.MTRowSelectBgColor
                  : solaTheme.componentColors.MTRowBgColor,
                borderBottom: isSelected
                  ? `3px solid ${solaTheme.componentColors.MTRowSelectBorderColor}`
                  : `1px solid ${solaTheme.componentColors.MTRowBorderColor}`,
                color: solaTheme.componentColors.MTRowTxColor || '',
              };
            },
          }}
        />
      </TableContainer>
    );
  } else {
    if (showEmpty) {
      return <EmptyMsg msg="查無資料" />;
    } else {
      return null;
    }
  }
};

const TableContainer = styled.div`
  width: 98%;
  max-width: 100%;

  padding-left: 10px;
  padding-right: 10px;
`;

export default Table;
