import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { useProvided } from '../../../stores/index';
import useAssetTheme from '../../../stores/useAssetTheme';

const BarChart = ({ data, keys, indexBy, ...restProps }) => {
  const { solaTheme } = useProvided(useAssetTheme);

  let colors = null;
  if (keys && keys.length <= 2) {
    colors = [solaTheme.componentColors.BarColor1, solaTheme.componentColors.BarColor2];
  } else {
    colors = { scheme: 'nivo' };
  }

  return (
    <ResponsiveBar
      data={data}
      keys={keys}
      indexBy={indexBy}
      enableLabel={false}
      colors={colors}
      theme={solaTheme.nivoTheme}
      margin={{ top: 50, right: 150, bottom: 50, left: 90 }}
      legends={[
        {
          dataFrom: 'keys',
          anchor: 'bottom-right',
          direction: 'column',
          justify: false,
          translateX: 120,
          translateY: 0,
          itemsSpacing: 2,
          itemWidth: 100,
          itemHeight: 25,
          itemDirection: 'left-to-right',
          itemOpacity: 0.85,
          itemTextColor: solaTheme.nivoTheme.legends.text.fill,
          symbolSize: 20
        }
      ]}
      {...restProps}
    />
  );
};

export default BarChart;
