import React, { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import axios from 'axios';
import styled from 'styled-components';
import { toast } from 'react-toastify';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Email, LockOpen } from '@material-ui/icons';

import { useProvided } from '../../stores/index';
import useAssetTheme from '../../stores/useAssetTheme';

const ForgetPassword = () => {
  const { solaTheme } = useProvided(useAssetTheme);
  const [email, setEmail] = useState('');
  const [shouldRedirect, setShouldRedirect] = useState(false);

  const handleSendResetMail = () => {
    if (email) {
      axios
        .post('/api/v1/auth/forgetPwd', {
          email: email
        })
        .then(res => {
          if (res.data.status) {
            toast.info('已寄發重置密碼信件至您的信箱！');
            setShouldRedirect(true);
          } else {
            toast.error(res.data.msg);
          }
        })
        .catch(error => {
          console.error(error);
        });
    } else {
      toast.warn('請輸入電子信箱');
    }
  };

  const colors = {
    bg: solaTheme.componentColors.FPBgColor,
    tx: solaTheme.componentColors.FPTxColor,
    subtitle: solaTheme.componentColors.FPSubtitleColor
  };

  return (
    <ForgetPwdPanel bgColor={colors.bg} txColor={colors.tx}>
      {shouldRedirect ? <Redirect to="/login" /> : null}
      <TitlePanel>
        <LockOpen fontSize="large" />
        <span>忘記密碼</span>
      </TitlePanel>
      <Subtitle txColor={colors.subtitle}>(寄發重置後的密碼至您的電子信箱)</Subtitle>
      <ContentPanel>
        <div>
          <TextField
            id="email"
            type="email"
            fullWidth
            label="電子信箱"
            helperText="your@example.com"
            value={email}
            onChange={e => setEmail(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Email />
                </InputAdornment>
              )
            }}
          />
          <ButtonPanel>
            <ButtonRow>
              <Button
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                onClick={handleSendResetMail}
              >
                確認
              </Button>
            </ButtonRow>
            <ButtonRow>
              <Link to="/login">
                <Button>回登入頁</Button>
              </Link>
            </ButtonRow>
          </ButtonPanel>
        </div>
      </ContentPanel>
    </ForgetPwdPanel>
  );
};

const ForgetPwdPanel = styled.div`
  width: 60vw;
  min-width: 350px;
  max-width: 436px;
  height: 494px;
  opacity: 0.85;
  border-radius: 2px;
  background-color: ${p => p.bgColor};
  color: ${p => p.txColor};
`;
const TitlePanel = styled.div`
  width: 100%;
  height: 90px;
  padding-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Subtitle = styled.div`
  color: ${p => p.txColor};
  text-align: center;
  margin-bottom: 5vh;
`;
const ContentPanel = styled.div`
  height: 300px;
  max-height: 300px;
  padding: 0px 33px;
`;
const ButtonPanel = styled.div`
  margin-top: 30px;
`;
const ButtonRow = styled.div`
  text-align: right;
  margin-top: 10px;
`;

export default ForgetPassword;
