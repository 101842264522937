import React, { useState } from 'react';
import styled from 'styled-components';
import { compose, withProps } from 'recompose';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import EmptyMsg from '../EmptyMsg';
import TitleContainer from '../Title/TitleContainer';
import TitleWithIcon from '../Title/TitleWithIcon';
import { ReactComponent as MapIcon } from '../../images/svg/map_mark.svg';

const MyMapComponent = compose(
  withProps({
    googleMapURL: 'https://maps.google.com/maps/api/js?key=AIzaSyCQ3UTayHzAcoPXJUM3Ag_-FBxII1o6fNg',
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `350px` }} />,
    mapElement: <div style={{ height: `100%` }} />
  }),
  withScriptjs,
  withGoogleMap
)(props => (
  <GoogleMap
    defaultZoom={7}
    defaultCenter={{ lat: 23.6, lng: 121 }}
    mapTypeId="satellite"
    options={{
      mapTypeControl: false,
      fullscreenControl: false,
      streetViewControl: false,
      gestureHandling: 'cooperative'
    }}
    center={props.position}
    zoom={19}
  >
    {props.isMarkerShown && <Marker position={props.position} />}
  </GoogleMap>
));

const PvMap = ({ pvCoord, isMarkerShown }) => {
  const [key, setKey] = useState(0);

  const handleMarkerReset = () => {
    setKey(k => k + 1);
  };

  return (
    <div>
      <TitleContainer hideTop>
        <TitleWithIcon title="電廠位置" prefixIcon={MapIcon} />
      </TitleContainer>
      <MapContainer>
        {pvCoord ? (
          <div>
            <MyMapComponent key={key} position={pvCoord} isMarkerShown={isMarkerShown} />
            <ResetIcon onClick={handleMarkerReset}>
              <MyLocationIcon />
            </ResetIcon>
          </div>
        ) : (
          <EmptyMsg>建置中</EmptyMsg>
        )}
      </MapContainer>
    </div>
  );
};

const MapContainer = styled.div`
  padding: 20px;
  position: relative;
`;
const ResetIcon = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 3px;
  position: absolute;
  bottom: 130px;
  right: 30px;
  color: #666;
  cursor: pointer;
`;

export default PvMap;
