import React from 'react';
import styled from 'styled-components';
import ArrowUpward from '@material-ui/icons/ArrowUpward';

const ColHeader = ({ title, selected, sortDirection, fix }) => {
  return (
    <HeaderContainer fix={fix}>
      <HeaderTitle>{title}</HeaderTitle>
      {selected ? (
        <div
          style={
            sortDirection
              ? { color: '#fff', transform: 'rotate(0deg) translate(0px, 2px)' }
              : { color: '#fff', transform: 'rotate(180deg) translate(0px, 2px)' }
          }
        >
          <ArrowUpward />
        </div>
      ) : (
        <Space />
      )}
    </HeaderContainer>
  );
};

const HeaderContainer = styled.div`
  display: flex;
  justify-content: ${props => (props.fix ? 'flex-start' : 'flex-end')};
  align-items: center;
  .MuiSvgIcon-root {
    font-size: 1.2rem;
  }
`;
const HeaderTitle = styled.div`
  color: '#fff';
`;
const Space = styled.div`
  padding-right: 20px;
`;

export default ColHeader;
