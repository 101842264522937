import React, { useEffect, useState } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Analytics from 'react-router-ga';
import styled from 'styled-components';
//components
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import 'moment/locale/zh-tw';

//pages
import AppLayout from './AppLayout';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Login from './Login';
import ResetPassword from './ResetPassword';
import ForgetPassword from './ForgetPassword';
import Error from './Error';
//global context
import { useProvided } from '../stores/index';
import useAuth from '../stores/useAuth';
import useAssetTheme from '../stores/useAssetTheme';
//config
import keys from '../config/keys';
//bgImages
import BgMountainMorning from '../images/bgMountainMorning.jpg';
import BgMountainEvening from '../images/bgMountainEvening.jpg';
import BgOcean from '../images/bgOcean.jpg';
import BgSolarPanelMorning from '../images/bgSolarPanelMorning.jpg';
import BgSolarPanelEvening from '../images/bgSolarPanelEvening.jpg';
const bgImageList = [BgMountainMorning, BgMountainEvening, BgOcean, BgSolarPanelMorning, BgSolarPanelEvening];

function App() {
  const { currentSPV } = useProvided(useAuth);
  const { solaTheme } = useProvided(useAssetTheme);
  const theme = createMuiTheme({ ...solaTheme.muiTheme });
  const [bgImage, setBgImage] = useState(null);

  const PrivateRoute = ({ component, ...rest }) => {
    return (
      <Route
        {...rest}
        render={props =>
          currentSPV.userId !== 0 ? React.createElement(component, props) : <Redirect to="/login" />
        }
      />
    );
  };

  const PublicRoute = ({ component, ...rest }) => {
    return (
      <Route
        {...rest}
        render={props =>
          currentSPV.userId !== 0 ? <Redirect to="/app/dashboard" /> : React.createElement(component, props)
        }
      />
    );
  };

  useEffect(() => {
    const index = Math.floor(Math.random() * bgImageList.length);
    setBgImage(bgImageList[index]);
  }, []);

  return (
    <div>
      <solaTheme.globalStyle />
      <MuiThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils} locale="zh-tw">
          <BrowserRouter>
            <Analytics {...keys.gaProps}>
              <Header />
              <MainPage bg={bgImage}>
                <ScrollDiv>
                  <Switch>
                    <PrivateRoute path="/app/:menu/:page/:pvId" component={AppLayout} />
                    <PrivateRoute path="/app/:menu/:page" component={AppLayout} />
                    <PrivateRoute path="/app/:page" component={AppLayout} />
                    <PublicRoute
                      path="/resetPwd/:otp"
                      component={({ match }) => <ResetPassword otp={match.params.otp} />}
                    />
                    <PublicRoute exact path="/forgetPwd" component={ForgetPassword} />
                    <PublicRoute exact path="/login" component={Login} />
                    <Route exact path="/" render={() => <Redirect to="/login" />} />
                    <Route exact path="/app" render={() => <Redirect to="/app/dashboard" />} />
                    <Route component={Error} />
                  </Switch>
                </ScrollDiv>
              </MainPage>
              <Footer />
              <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar
                newestOnTop
                closeOnClick
                draggable={false}
                pauseOnHover
              />
            </Analytics>
          </BrowserRouter>
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    </div>
  );
}

export const MainPage = styled.div`
  width: 100vw;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;

  background-image: url(${props => props.bg || ''});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;
export const ScrollDiv = styled.div`
  overflow-x: auto;
`;

export default App;
