import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import bn from 'bignumber.js';
import styled from 'styled-components';
import { toast } from 'react-toastify';
//component
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import DropdownList from '../../components/Selector/dropdownlist';
import NgoItem from '../../components/Donation/ngoItem';
import TitleContainer from '../../components/Title/TitleContainer';
import TitleWithIcon from '../../components/Title/TitleWithIcon';
//icon
import { ReactComponent as NgoIcon } from '../../images/svg/ngo.svg';
//
import { NumberFormat } from '../../commons/utils';
import { useProvided } from '../../stores/index';
import useAuth from '../../stores/useAuth';
//
import Ngos from './ngoTestData';

const CancelFnList = [];
const Donation = () => {
  const { currentSPV } = useProvided(useAuth);

  //
  const [dialogProps, setDialogProps] = useState({ open: false, ngoId: null });
  const [userAssets, setUserAssets] = useState([]);
  const [ngoAssets, setNgoAssets] = useState({});
  const [donateData, setDonateData] = useState({});
  const [errMsg, setErrMsg] = useState('');

  //載入此SPV持有電廠的資訊
  const loadUserAssets = useCallback(() => {
    const source = axios.CancelToken.source();
    CancelFnList.push(source.cancel);
    axios
      .get(`/api/v1/donation/userAsset`, {
        headers: { Authorization: currentSPV.token },
        cancelToken: source.token,
      })
      .then((res) => {
        if (res && res.data && res.data.status) {
          const data = res.data.result.userAsset;
          data.forEach((d) => {
            d.key = d.id;
          });
          setUserAssets(data);
        }
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          console.error(err);
        }
      });
  }, [currentSPV]);
  //載入NGO組織持有電廠的資訊
  const loadNgoAssets = useCallback(() => {
    const source = axios.CancelToken.source();
    CancelFnList.push(source.cancel);
    axios
      .get(`/api/v1/donation/ngoAsset`, {
        headers: { Authorization: currentSPV.token },
        cancelToken: source.token,
      })
      .then((res) => {
        if (res && res.data && res.data.status) {
          const data = res.data.result.ngoAsset;
          setNgoAssets(data);
        }
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          console.error(err);
        }
      });
  }, [currentSPV]);
  useEffect(() => {
    loadUserAssets();
    loadNgoAssets();

    return () => {
      if (CancelFnList && CancelFnList.length > 0) {
        CancelFnList.forEach((fn) => {
          fn();
        });
      }
    };
  }, [loadUserAssets, loadNgoAssets]);

  //================================================================
  const handleDialogOpen = (ngoId) => () => {
    setDialogProps({ open: true, ngoId: ngoId });
  };
  const handleDialogClose = () => {
    setDonateData({});
    setErrMsg('');
    setDialogProps({ open: false, ngoId: null });
  };
  const handleDonateDataChange = (name) => (e) => {
    const tmp = { ...donateData };
    if (e && e.target) {
      tmp[name] = e.target.value;
    } else {
      tmp[name] = e;
    }

    //
    if (name === 'pvId') {
      const pv = userAssets.find((u) => u.id === tmp.pvId);
      if (pv) {
        tmp.remained = pv.remained;
      }
    }

    setDonateData(tmp);
    setErrMsg('');
  };

  const handleDonate = () => {
    console.log('donateData', donateData);
    //
    if (!donateData.pvId || !donateData.amount) {
      setErrMsg('請選擇電廠代碼，並輸入捐贈數量');
      return;
    }
    const remained = bn(donateData.remained).toNumber();
    const amount = bn(donateData.amount).toNumber();

    if (amount <= 0) {
      setErrMsg('請捐贈數量不可小於 0');
      return;
    }
    //
    if (amount > remained) {
      setErrMsg('捐贈數量不可超過剩餘持有數量');
      return;
    }

    //
    const postData = {
      giver: parseInt(currentSPV.userId, 10),
      receiver: dialogProps.ngoId,
      pvId: donateData.pvId,
      amount: amount,
    };
    axios
      .post('/api/v1/donation/donate', postData, { headers: { Authorization: currentSPV.token } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          toast.success('已捐贈成功');
          handleDialogClose();
          loadUserAssets();
          loadNgoAssets();
        } else {
          toast.warn(res.data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  //================================================================
  return (
    <div>
      <TitleContainer>
        <TitleWithIcon title="太陽能捐贈" prefixIcon={NgoIcon} />
      </TitleContainer>
      <InnerDiv>
        {Array.isArray(Ngos) && Ngos.length > 0
          ? Ngos.map((ngo, nIdx) => {
              const asset = ngoAssets[ngo.userId] || {};

              return (
                <NgoItem
                  key={nIdx}
                  img={ngo.img}
                  logo={ngo.logo}
                  label={ngo.label}
                  web={ngo.web}
                  solar={asset.solar || ngo.solar}
                  power={asset.power || ngo.power}
                  income={asset.income || ngo.income}
                  donateFn={handleDialogOpen(ngo.userId)}
                />
              );
            })
          : null}
      </InnerDiv>
      <Dialog open={dialogProps.open} onClose={handleDialogClose}>
        <DialogContent>
          <InputRow>
            <div>持有電廠</div>
            <DropdownList
              width="100%"
              placeholder="請選擇持有電廠"
              options={userAssets}
              value={donateData.pvId}
              changeFn={handleDonateDataChange('pvId')}
            />
          </InputRow>
          <InputRow>
            <div>剩餘額度</div>
            <div style={{ marginLeft: '25px', marginTop: '10px' }} className="value">
              {NumberFormat(donateData.remained)}
            </div>
          </InputRow>
          <InputRow>
            <div>捐贈數量</div>
            <TextField
              fullWidth
              type="number"
              value={donateData.amount}
              onChange={handleDonateDataChange('amount')}
              disabled={!donateData.pvId}
            />
          </InputRow>
          <InputRow>
            <div className="error">{errMsg}</div>
          </InputRow>
          <ButtonRow>
            <Button variant="contained" color="primary" onClick={handleDonate}>
              確認
            </Button>
            <Button color="primary" onClick={handleDialogClose}>
              關閉
            </Button>
          </ButtonRow>
        </DialogContent>
      </Dialog>
    </div>
  );
};

const InnerDiv = styled.div`
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
`;
const InputRow = styled.div`
  margin: 20px 0px;

  .label,
  .value,
  .icon {
    color: #b6b7b8 !important;
  }

  .frame {
    border: 1px solid #b6b7b8 !important;
    color: #b6b7b8 !important;
  }

  .error {
    color: #ff4743;
  }
`;
const ButtonRow = styled.div`
  margin-top: 10px;
  padding-left: 3%;
  padding-right: 3%;
  padding-bottom: 3%;
  text-align: right;
`;
const DialogContent = styled.div`
  width: 50vw;
  max-width: 420px;
  padding: 0px 20px;
  padding-bottom: 10px;
`;

export default Donation;
