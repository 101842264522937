import { createGlobalStyle } from 'styled-components';
import BgTrapzoidWhite from '../images/svg/bg_trapezoid_white.svg';
import WeatherIcon from '../images/svg/weather.svg';
import DayIcon from '../images/svg/w_day.svg';
import DayCloudyIcon from '../images/svg/w_day_cloudy.svg';
import CloudyIcon from '../images/svg/w_cloudy.svg';
import OvercastIcon from '../images/svg/w_overcast.svg';
import CloudyRainyIcon from '../images/svg/w_cloudy_rainy.svg';
import RainyIcon from '../images/svg/w_rainy.svg';
import ThunderRainyIcon from '../images/svg/w_thunder_rainy.svg';
import TreePng from '../images/tree_white.png';
import IrregularPic from '../images/irregular_pic_white.png';

//basic color.
//black
const Black = '#000000';
// const Black_1b = '#1b1d21';
// const Black_16 = '#16161a';
//blue
const Blue_a0 = '#a0c1cb';
const Blue_7a = '#7ab1c1';
const Blue_57 = '#5793f3';
const Blue_51 = '#519aaf';
const Blue_49 = '#4974a2';
const Blue_40 = '#407b8c';
const Blue_30 = '#305c69';
const Blue_20 = '#203d46';
//gray
const Gray_e3 = '#e3e3e4';
const Gray_e0 = '#e0e5e6';
const Gray_d5 = '#d5dadd';
const Gray_c1 = '#c1cbcf';
const Gray_bf = '#bfc6c9';
const Gray_bb = '#bbc7cb';
const Gray_b5 = '#b5b5b5';
const Gray_9f = '#9faaad';
const Gray_6c = '#6c7377';
// const Gray_69 = '#696a6d';
// const Gray_65 = '#656a71';
const Gray_62 = '#62676E';
const Gray_58 = '#585c60';
const Gray_49 = '#494d53';
const Gray_45 = '#454b53';
const Gray_41 = '#414447';
// const Gray_40 = '#40484a';
const Gray_37 = '#373e46';
const Gray_34 = '#34393f';
const Gray_33 = '#33383e';
// const Gray_31 = '#313235';
const Gray_2f = '#2f3033';
// const Gray_2e = '#2E323B';
const Gray_2d = '#2d3033';
const Gray_2c = '#2c3034';
const Gray_26 = '#26282b';
// const Gray_21 = '#212326';
const Gray_20 = '#202326';
// const Gray_19 = '#191b1d';
const Gray_17 = '#171a1c';
const Gray_1d = '#1d1f22';
//green
const Green = '#2bbc8f';
//red
const Red = '#ff4743';
//transparent
const Transparent = 'rgba(255,255,255,0)';
//white
const White = '#ffffff';
const White_f7 = '#f7f7f7';
const White_ef = '#efefef';
const White_ea = '#eaeaeb';
const White_e9 = '#e9e9e9';
const White_e3 = '#e3e3e3';
//yellow
const Yellow = '#f7b100';

export const WhiteTheme = {
  componentColors: {
    //AppLayout view.
    SideBgColor: 'rgba(37, 39, 42, 0.75)', //'#25272a',
    ContentBgColor: White_e3,
    //Header component.
    HeaderBgColor: White_f7,
    //Footer component.
    FooterBgColor: White_f7,
    FooterTxColor: Gray_45,
    //Avatar component.
    AvatarIconBgColor: Red,
    AvatarIconTxColor: White,
    AvatarNameTxColor: White,
    AvatarEmailTxColor: Gray_b5,
    AvatarDetailBgColor: Gray_26,
    AvatarDetailBgHoverColor: Gray_2f,
    AvatarDetailTxColor: Gray_b5,
    AvatarDetailTxHoverColor: Yellow,
    AvatarDetailHrColor: Gray_1d,
    //Menu component.
    MenuL1BgColor: Transparent,
    MenuL1TxColor: White,
    MenuL1TxSelectColor: Yellow,
    MenuL1TxHoverColor: Yellow,
    MenuL2BgColor: Gray_26,
    MenuL2BgHoverColor: Gray_2f,
    MenuL2TxColor: Gray_b5,
    MenuL2TxSelectColor: Yellow,
    MenuL2TxHoverColor: Yellow,
    MenuL2HrColor: Gray_1d,
    MenuL3BgColor: Gray_1d,
    MenuL3BgHoverColor: Gray_2f,
    MenuL3TxColor: Gray_b5,
    MenuL3TxSelectColor: Yellow,
    MenuL3TxHoverColor: Yellow,
    MenuL3HrColor: Gray_26,
    MenuCountBgSelectColor: Yellow,
    MenuCountTxColor: Gray_b5,
    MenuCountTxSelectColor: White,
    //TitleContainer
    TitleContainerHrColor: Gray_d5,
    TitleContainerBgColor: White_f7,
    //TitleWithICon
    TitleTxColor: Gray_33,
    //ConditionContainer
    ConditionContainerBgColor: White_ea,
    //HintWithICon
    HintTxColor: Gray_9f,
    //Tab
    TabTxColor: Gray_9f,
    TabTxSelectColor: Gray_33,
    TabTxSelectSecondColor: Gray_33,
    TabHrColor: Gray_9f,
    //Switch
    SwitchOffColor: Blue_a0,
    SwitchOnColor: Gray_33,
    SwitchOnTrackColor: Gray_bf,
    SwitchLabelColor: Gray_33,
    //Loading
    LoadingTxColor: Gray_33,
    //ProfitCard
    ProfitCardBgColor: White_f7,
    ProfitCardBgImg: BgTrapzoidWhite,
    ProfitCardTxColor: Gray_49,
    ProfitCardDollarIconColor: Gray_9f, //Dollar
    ProfitCardDollarTxColor: Gray_33,
    ProfitCardChartLinePast: Yellow, //Chart
    ProfitCardChartLineNow: Gray_e0,
    ProfitCardChartLineError: Red,
    ProfitCardChartLineBase: Gray_20,
    ProfitCardNoteTxColor: Yellow, //Note
    ProfitCardNoteTitleColor: White,
    ProfitCardNoteIconBase: Gray_9f,
    ProfitCardNoteIconHover: Yellow,
    ProfitCardClockTxColor: Gray_45, //Clock
    ProfitCardWeatherIcon: WeatherIcon, //Weather
    ProfitCardWeatherDayIcon: DayIcon,
    ProfitCardWeatherDayCloudyIcon: DayCloudyIcon,
    ProfitCardWeatherCloudyIcon: CloudyIcon,
    ProfitCardWeatherOvercastIcon: OvercastIcon,
    ProfitCardWeatherCloudyRainyIcon: CloudyRainyIcon,
    ProfitCardWeatherRainyIcon: RainyIcon,
    ProfitCardWeatherThunderRainyIcon: ThunderRainyIcon,
    //Dashboard-AccumulatedProfit
    DAPChartLineSimulate: Blue_a0,
    DAPChartLineBill: Yellow,
    //Dashboard-AssetInfo
    DAIIconColor: Gray_9f,
    DAIHrColor: Gray_bb,
    DAITxColor: Gray_33,
    DAITitleTxColor: Gray_33,
    DAISummaryTxColor: Gray_33,
    //Dashboard-PvCounter
    DPCCircleStroke: Gray_bb,
    DPCBarBgStroke: White_e3,
    DPCBarStroke: Yellow,
    DPCCenterPanelTxColor: Gray_33,
    DPCRunningTxColor: Gray_33,
    DPCTotalTxColor: Gray_33,
    //Dashboard-PowerGeneration
    DPGBatteryTxColor: Gray_33,
    DPGBatteryPicRect: 'rect-color-white',
    DPGBatteryPicFrame: 'frame-color-white',
    DPGHintTxColor: Gray_62,
    //Dashboard-CO2
    DCTitleTxColor: Gray_33,
    DCValueTxColor: Gray_33,
    DCUnitTxColor: Gray_33,
    DCBgImg: TreePng,
    //Dashboard-Income
    DITitleTxColor: Gray_33,
    DIValueTxColor: Gray_33,
    DIUnitTxColor: Gray_33,
    DIBgImg: IrregularPic,
    //Dashboard-AchieveRateList
    DAREmptyTxColor: Gray_9f,
    DARHrColor: Gray_bb,
    DARLeftTxColor: Gray_33,
    DARRightTxColor: Gray_33,
    DARRadiusBgColor: White,
    DARRateBgColor: Yellow,
    DARPgColor: Gray_bb,
    DArPgSelectColor: Gray_45,
    //Dashboard-BulletinList
    DBLEmptyTxColor: Gray_9f,
    DBLHrColor: Gray_bb,
    DBLTxColor: Gray_33,
    DBLPgColor: Gray_bb,
    DBLPgSelectColor: Gray_45,
    //Tip
    TipBgColor: Gray_33,
    TipTxColor: Yellow,
    TipTitleColor: White,
    TipHrColor: Gray_37,
    TipIconColor: Gray_9f,
    //JTable
    JTHeadThFix: Gray_e3,
    JTHeadTh: Gray_e3,
    JTHeadThFirst: Gray_e3,
    JTHeadThSecond: Gray_e3,
    JTBodyTdFix: Gray_e3,
    JTBodyTd: Gray_e3,
    JTFootTdFix: White_ea,
    JTFootTd: White_ea,
    JTTextColor: Black,
    JTTextSelectedColor: Blue_51,
    JTBorder: Gray_c1,
    JTBorderSelected: Yellow,
    //MTable
    MTHeaderTxColor: Gray_33,
    MTHeaderBgColor: White_e3,
    MTHeaderBorderColor: Gray_bb,
    MTRowTxColor: Gray_33,
    MTRowBgColor: White_e3,
    MTRowBorderColor: Gray_bb,
    MTRowSelectBgColor: White_ea,
    MTRowSelectBorderColor: Yellow,
    MTPaggingBtnBorderColor: Gray_bb,
    MTPaggingBtnTxColor: Gray_33,
    MTPaggingBgColor: White_e3,
    MTPaggingTxColor: Gray_9f,
    MTPaggingSelectTxColor: Gray_33,
    //PvData
    PvDialogTitleBgColor: Gray_20,
    PvDialogTitleTxColor: Blue_57,
    PvDialogItemBorderColor: Gray_33,
    PvDialogItemHoverTxColor: Yellow,
    PvDialogItemHoverBgColor: Gray_2f,
    //PvInfo-PvData
    PvStatusNoneColor: Gray_34,
    PvStatusPowerOnColor: Green,
    PvStatusPowerBuildColor: Yellow,
    PvStatusPowerMaintainColor: Red,
    PvDataTitleTxColor: Gray_33,
    PvDataValueTxColor: Gray_33,
    PvDataColumnBorderColor: Gray_bb,
    //PvInfo-PvStage
    PvStageBaseLineColor: Blue_a0,
    PvStageBasePointColor: Blue_a0,
    PvStagePassStageTxColor: Gray_33,
    PvStageCurrentStageTxColor: Gray_33,
    PvStageFutureStageTxColor: Gray_9f,
    //PvInfo-PvChart
    PvChartBgColor: White_e3,
    PvChartBarColor: Blue_51,
    //PvInfo-PvImage
    PvImageSelectBorderColor: Yellow,
    //Calendar
    CalendarUnknownEventColor: White,
    CalendarPvEventColor: Blue_51,
    CalendarSimulateEventColor: Gray_33,
    CalendarBillEventColor: Blue_49,
    CalendarHeaderTxColor: Gray_33,
    CalendarDaySameMonthBgColor: White_f7,
    CalendarDayOtherMonthBgColor: White_ef,
    CalendarDayTxColor: Gray_33,
    CalendarDaySelectColor: Gray_33,
    CalendarDetailBgColor: Gray_41,
    CalendarDetailItemBgColor: White_ef,
    CalendarDetailTitleColor: White_ef,
    //DateSelector
    DateSelectorTxColor: Gray_33,
    DateSelectorBgColor: White_e3,
    //Transaction-Filter
    FilterTxColor: Gray_33,
    FilterBgColor: Gray_d5,
    FilterBorderColor: Gray_d5,
    FilterSelectTxColor: White,
    FilterSelectBgColor: Gray_33,
    FilterSelectBorderColor: Gray_33,
    //TimeFilter
    TFilterTxColor: Gray_33,
    TFilterBgColor: Gray_d5,
    TFilterBorderColor: Gray_d5,
    TFilterSelectTxColor: White,
    TFilterSelectBgColor: Gray_33,
    TFilterSelectBorderColor: Gray_33,
    //nivo Bar color
    BarColor1: Blue_a0,
    BarColor2: Blue_51,
    BarColor3: Blue_40,
    BarColor4: Blue_30,
    BarColor5: Blue_20,
    //Portfolio-CumulativeIncome
    PCILineChartBillColor: Yellow,
    PCILineChartSimulateColor: Gray_6c,
    //UserAssets
    UAPieChartMainColor: Blue_51,
    UAPieChartColors: [Blue_7a, Blue_a0],
    UAPieChartSelectColor: Yellow,
    UAPieChartTxColor: Gray_33,
    UAPieListTxColor: Gray_33,
    UAPieListBorderColor: Gray_bf,
    UAPieListHoverBgColor: White_e9,
    UAPieListSelectBgColor: White_e9,
    UAPieListSelectBorderColor: Yellow,
    UAPieDetailTitleColor: Gray_33,
    UAPieDetailPvTxColor: Gray_33,
    UAPieDetailValueTxColor: Gray_33,
    UAPieDetailTotalTxColor: Gray_33,
    UABarChartTxColor: Gray_33,
    //Abstract
    PvCountTextColor: Gray_33,
    //DropDownList
    DDLBorderColor: Gray_33,
    DDLLabelTxColor: Gray_33,
    DDLValueTxColor: Gray_33,
    DDLItemBgColor: Gray_33,
    DDLItemTxColor: White,
    DDLItemBorderColor: Gray_62,
    DDLItemHoverBgColor: White_e9,
    DDLItemHoverTxColor: Gray_33,
    DDLItemSelectBgColor: Blue_51,
    DDLItemSelectTxColor: White,
    //EmptyMsg
    EmptyTxColor: Gray_33,
    //Account
    AccountCardBgColor: Gray_bb,
    AccountCardBorderColor: Gray_9f,
    AccountCardTitleColor: Gray_6c,
    AccountCardTxColor: Gray_33,
    AccountCardDescColor: Gray_6c,
    AccountDialogBgColor: Gray_2c,
    AccountDialogBorderColor: Gray_17,
    AccountDialogTitleColor: Yellow,
    AccountDialogStepIconBgColor: Yellow,
    AccountDialogStepIconTxColor: Black,
    //ChangeSpv
    CSTitleTxColor: Gray_33,
    CSItemBgColor: Gray_9f,
    CSItemTxColor: Black,
    CSItemSelectBgColor: Yellow,
    CSItemSelectTxColor: White,
    //Login
    LoginBgColor: Gray_2d,
    LoginTxColor: White,
    //ForgetPassword
    FPBgColor: Gray_2d,
    FPTxColor: White,
    FPSubtitleColor: Red,
    //ResetPassword
    RPBgColor: Gray_2d,
    RPTxColor: White,
    RPTitleTxColor: Yellow
  },
  muiTheme: {
    palette: {
      type: 'dark',
      primary: { main: Yellow, contrastText: White },
      typography: { useNextVariants: true }
    },
    overrides: {
      MuiButton: {
        root: {
          fontFamily: `'Roboto', 'Noto Sans TC', sans-serif`
        }
      },
      MuiButtonBase: {
        root: {
          alignItems: 'stretch'
        }
      },
      MuiExpansionPanel: {
        root: {
          backgroundColor: Transparent,
          margin: '0px',
          padding: '0px',
          border: '0px',
          borderBottom: `1px solid ${Gray_26}`,
          position: 'static',
          '&$expanded': {
            margin: '0px',
            padding: '0px',
            border: '0px'
          }
        }
      },
      MuiExpansionPanelSummary: {
        root: {
          margin: '0px',
          padding: '0px 0px 0px 30px',
          border: '0px',
          '&$expanded': {
            minHeight: '48px'
          }
        },
        content: {
          margin: '0px',
          padding: '0px',
          border: '0px',
          '&$expanded': {
            margin: '0px'
          }
        },
        expandIcon: {
          marginRight: '5px'
        }
      },
      MuiExpansionPanelDetails: {
        root: {
          margin: '0px',
          padding: '0px',
          border: '0px',
          display: 'block'
        }
      },
      MuiTooltip: {
        tooltipPlacementTop: {
          backgroundColor: Gray_33,
          color: White,
          fontSize: '18px'
        },
        tooltipPlacementBottom: {
          backgroundColor: Transparent,
          minWidth: '500px',
          padding: '0px'
        }
      },
      MuiSelect: {
        root: {
          minWidth: '150px',
          padding: '3px 10px',
          border: '1px solid ' + Gray_58,
          color: Gray_58
        },
        select: {
          minWidth: '150px'
        }
      },
      MuiTableCell: {
        root: {
          borderBottomColor: Gray_bb
        },
        body: {
          color: Gray_33
        }
      },
      MuiTableSortLabel: {
        root: {
          '&$active': {
            color: Yellow
          }
        }
      },
      MuiPaper: {
        root: {
          backgroundColor: Gray_17
        },
        elevation1: {
          boxShadow: 'none'
        },
        elevation2: {
          boxShadow: 'none'
        }
      },
      MuiOutlinedInput: {
        root: {
          height: '35px'
        }
      }
    }
  },
  nivoTheme: {
    axis: {
      ticks: {
        text: {
          fill: Gray_33 //Gray_6c
        }
      }
    },
    crosshair: {
      line: {
        stroke: Yellow
      }
    },
    tooltip: {
      container: {
        padding: '0px'
      }
    },
    //The nivo chart do not have 'legends' setting here, it is added by developer.
    legends: {
      text: {
        fill: Gray_33
      }
    }
  },
  globalStyle: createGlobalStyle`
    @import url('https://fonts.googleapis.com/css?family=Montserrat|Noto+Sans+TC&display=swap');

    body {
      font-family: 'Montserrat', 'Noto Sans TC', 'Microsoft YaHei', sans-serif !important;
      margin: 0;
      padding: 0;
      border: 0;
    };

    a {
      text-decoration: none;
      color: ${Gray_6c};
    };

    a:hover {
      color: ${Yellow};
    }

    ::-webkit-scrollbar {
      width: 0px;
      height: 5px;
    };
    ::-webkit-scrollbar-thumb {
        background: ${Gray_b5};
    };

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active
    {
      /* background transparent */
      -webkit-box-shadow: 0 0 0px 1000px ${Transparent} inset;
      transition: background-color 5000s ease-in-out 0s;
      /* white font */
      -webkit-text-fill-color: #fff;
    }

    .MuiPickersCalendarHeader-iconButton {
      background-color: ${Transparent} !important;
    }
    .MuiPickersCalendarHeader-iconButton > * {
      background-color: ${Transparent} !important;
    }
  `
};
