import React from 'react';
import styled from 'styled-components';
import LoadingIcon from '../../images/svg/loading.svg';
import { useProvided } from '../../stores/index';
import useAssetTheme from '../../stores/useAssetTheme';

const Loading = () => {
  const { solaTheme } = useProvided(useAssetTheme);

  return (
    <PanelLoading>
      <img alt="loading" src={LoadingIcon} />
      <div id="loadingMsg" style={{ color: solaTheme.componentColors.LoadingTxColor }}>
        資料更新中
      </div>
    </PanelLoading>
  );
};

const PanelLoading = styled.div`
  height: 360px;
  padding: 0px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export default Loading;
