import React from 'react';
import styled from 'styled-components';
import BigNumber from 'bignumber.js';
//icon
import NgoSvg from '../../images/svg/ngo.svg';

const BN = BigNumber.clone({ DECIMAL_PLACES: 16, ROUNDING_MODE: 1 });
const NgoItem = ({ img, logo, label, sublabel, web, solar, power, income, donateFn }) => {
  return (
    <Div>
      <ImgDiv>
        <Img src={img} />
        {logo ? <Icon src={logo} /> : null}
      </ImgDiv>
      <InnerDiv>
        <TitleRow>
          <TitleIcon alt="icon" src={NgoSvg} />
          <TitleLabel title={label}>
            {label && label.length > 18 ? label.substring(0, 18) + '...' : label}
          </TitleLabel>
        </TitleRow>
        <ButtonRow>
          <Button
            onClick={() => {
              let win = window.open(web, '_blank');
              win.focus();
            }}
          >
            瞭解組織
          </Button>
          <YButton onClick={donateFn}>捐贈</YButton>
        </ButtonRow>
        <InfoRow>
          <Item>
            <Label>{'已募集  /  KWp'}</Label>
            <Value>{BN(solar).toFormat(1)}</Value>
          </Item>
          <Item>
            <Label>{'累積發電量  /  度'}</Label>
            <Value>{BN(power).toFormat(1)}</Value>
          </Item>
          <Item>
            <Label>{'總收入  /  元'}</Label>
            <Value>{BN(income).toFormat(1)}</Value>
          </Item>
        </InfoRow>
      </InnerDiv>
    </Div>
  );
};

const Div = styled.div`
  width: 31%;
  min-width: 320px;

  background-color: #26262b;
  border: 1px solid #e3e3e3;
  border-radius: 8px;
  margin: 1%;
`;
const InnerDiv = styled.div`
  padding: 0 8px;
`;

const ImgDiv = styled.div`
  width: 100%;
  height: 200px;
  position: relative;
`;
const Img = styled.img`
  width: 100%;
  height: 200px;
  border-radius: 6px 6px 0 0;
`;
const Icon = styled.img`
  position: absolute;
  bottom: 10px;
  right: 10px;

  width: 60px;
  height: 60px;
  border: 3px solid rgba(255, 255, 255, 0.2);
  border-radius: 50%;
`;

const TitleRow = styled.div`
  display: flex;
  padding: 8px 0px;
`;
const TitleIcon = styled.img`
  width: 24px;
  height: 24px;
`;
const TitleLabel = styled.div`
  font-size: 16px;
  color: #aaabad;
  margin: 0px 8px;
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  padding: 8px 0px;
  border-bottom: 1px solid #37373e;
`;
const Button = styled.div`
  color: grey;
  border: 1px solid grey;
  border-radius: 30px;
  padding: 1px 1em 1px 1em;
  margin-right: 0.5em;
  font-size: 14px;
  cursor: pointer;
`;
const YButton = styled.div`
  color: #f7b100;
  border: 1px solid #f7b100;
  border-radius: 30px;
  padding: 1px 1em 1px 1em;
  margin-right: 0.5em;
  font-size: 14px;
  cursor: pointer;
`;

const InfoRow = styled.div`
  display: flex;
  padding: 15px 0px;
`;
const Item = styled.div`
  font-family: Montserrat;
  margin-right: 12px;
`;
const Label = styled.div`
  font-size: 12px;
  color: #707070;
`;
const Value = styled.div`
  font-size: 24px;
  color: #f7b100;
`;

export default NgoItem;
