import React from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';

import DayIcon from '../../images/svg/wDark_day.svg';
import DayCloudyIcon from '../../images/svg/wDark_day_cloudy.svg';
import CloudyIcon from '../../images/svg/wDark_cloudy.svg';
import OvercastIcon from '../../images/svg/wDark_overcast.svg';
import CloudyRainyIcon from '../../images/svg/wDark_cloudy_rainy.svg';
import RainyIcon from '../../images/svg/wDark_rainy.svg';
import ThunderRainyIcon from '../../images/svg/wDark_thunder_rainy.svg';
//
import wDayIcon from '../../images/svg/w_day.svg';
import wDayCloudyIcon from '../../images/svg/w_day_cloudy.svg';
import wCloudyIcon from '../../images/svg/w_cloudy.svg';
import wOvercastIcon from '../../images/svg/w_overcast.svg';
import wCloudyRainyIcon from '../../images/svg/w_cloudy_rainy.svg';
import wRainyIcon from '../../images/svg/w_rainy.svg';
import wThunderRainyIcon from '../../images/svg/w_thunder_rainy.svg';

function Dev() {
  return (
    <div>
      <Typography variant="h1" color="primary">
        Develop...
      </Typography>
      <Typography variant="h5" color="primary">
        網頁開發中。
      </Typography>
      <div style={{ marginTop: '100px' }}>
        <IconPanel alt="day" title="晴天" src={DayIcon} />
        <IconPanel alt="dayCloudy" title="晴時多雲" src={DayCloudyIcon} />
        <IconPanel alt="cloudy" title="多雲、霧" src={CloudyIcon} />
        <IconPanel alt="overcast" title="陰天" src={OvercastIcon} />
        <IconPanel alt="cloudyRainy" title="多雲陣雨、陣雨有霧" src={CloudyRainyIcon} />
        <IconPanel alt="rainy" title="雨天" src={RainyIcon} />
        <IconPanel alt="thunderRainy" title="雷雨" src={ThunderRainyIcon} />
      </div>
      <div style={{ marginTop: '100px' }}>
        <IconPanelWhite alt="day" title="晴天" src={wDayIcon} />
        <IconPanelWhite alt="dayCloudy" title="晴時多雲" src={wDayCloudyIcon} />
        <IconPanelWhite alt="cloudy" title="多雲、霧" src={wCloudyIcon} />
        <IconPanelWhite alt="overcast" title="陰天" src={wOvercastIcon} />
        <IconPanelWhite alt="cloudyRainy" title="多雲陣雨、陣雨有霧" src={wCloudyRainyIcon} />
        <IconPanelWhite alt="rainy" title="雨天" src={wRainyIcon} />
        <IconPanelWhite alt="thunderRainy" title="雷雨" src={wThunderRainyIcon} />
      </div>
    </div>
  );
}

const IconPanel = styled.img`
  margin: 10px;
  padding: 10px;
  width: 48px;
  height: 48px;
  background-color: #2c3034;
`;
const IconPanelWhite = styled.img`
  margin: 10px;
  padding: 10px;
  width: 48px;
  height: 48px;
  background-color: #f7f7f7;
`;

export default Dev;
