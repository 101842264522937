import React, { useState, useMemo, useCallback, useEffect } from 'react';
import axios from 'axios';
import bn from 'bignumber.js';
import moment from 'moment';
import styled from 'styled-components';

//common
import TitleContainer from '../../../components/Title/TitleContainer';
import TitleWithIcon from '../../../components/Title/TitleWithIcon';
import { PercentFormat } from '../../../commons/utils';
import useAssetTheme from '../../../stores/useAssetTheme';
//component
import JTable from '../../../components/Table/JTable';
import SwitchSelector from '../../../components/Selector/switchSelector';
import DateSelector from '../../../components/Selector/dateSelector';
import JTooltip from '../../../components/Tooltip/JTooltip';
//icon
import { ReactComponent as BarChartIcon } from '../../../images/svg/bar_chart.svg';

import { useProvided } from '../../../stores/index';
import useAuth from '../../../stores/useAuth';

const GenerateColumns = simulate => {
  const rtnVal = [];
  const subTitles = ['近一週', '近一月', '近一季', '近一年'];
  const subFields = ['week', 'month', 'quarter', 'year'];

  rtnVal.push({
    title: '電廠',
    field: 'pvId',
    render: row => (
      <div>
        <div>{row.pvId && row.pvId + '-'}</div>
        <div>{row.pvName}</div>
      </div>
    ),
    cellStyle: {
      width: '200px'
    }
  });
  rtnVal.push({
    title: '資產占比',
    field: 'weight',
    render: row => <span>{PercentFormat(row.weight)}</span>,
    headerStyle: {
      paddingRight: '30px'
    },
    cellStyle: {
      width: '80px',
      paddingRight: '30px'
    }
  });

  const innerDom = [];
  for (let i = 0; i < subTitles.length; i++) {
    const fieldName = simulate ? subFields[i] + 'Sim' : subFields[i];
    innerDom.push({
      title: subTitles[i],
      field: fieldName,
      render: row => <span>{PercentFormat(row[fieldName])}</span>,
      cellStyle: {
        width: '100px'
      }
    });
  }

  rtnVal.push({
    title: '年平均報酬率',
    subColumns: innerDom
  });

  return rtnVal;
};
const tableDesc = [
  { colName: '資產占比', colDesc: '投入該電廠的金額 ÷ 投入所有電廠的金額 × 100%。' },
  {
    colName: '近一週',
    colDesc: '所選日期回推一週之該段時間平均每日收益 × 365 ÷ 投資金額 × 100%。'
  },
  {
    colName: '近一月',
    colDesc: '所選日期回推一月之該段時間平均每日收益 × 365 ÷ 投資金額 × 100%。'
  },
  {
    colName: '近一季',
    colDesc: '所選日期回推一季之該段時間平均每日收益 × 365 ÷ 投資金額 × 100%。'
  },
  {
    colName: '近一年',
    colDesc: '所選日期回推一年之該段時間平均每日收益 × 365 ÷ 投資金額 × 100%。'
  }
];
const CancelFnList = [];
const PerformanceAnalysisRecent = () => {
  const { solaTheme } = useProvided(useAssetTheme);
  const { currentSPV } = useProvided(useAuth);
  const [selectDate, setSelectDate] = useState(moment().format('YYYY-MM-DD'));
  const [recentData, setRecentData] = useState([]);
  const [summaryData, setSummaryData] = useState([]);
  const [simulate, setSimulate] = useState(true);

  const handleDatePickerChange = newDate => {
    if (selectDate !== newDate) {
      setSelectDate(newDate);
    }
  };
  const handleSimulateSwitch = () => {
    setSimulate(s => !s);
  };
  const tableColumns = useMemo(() => {
    return GenerateColumns(simulate);
  }, [simulate]);
  const loadData = useCallback(() => {
    const source = axios.CancelToken.source();
    CancelFnList.push(source.cancel);
    axios
      .get(`/api/v1/assets/performance/recent?date=${selectDate}`, {
        headers: { Authorization: currentSPV.token },
        cancelToken: source.token
      })
      .then(res => {
        if (res && res.data && res.data.status) {
          const recent = res.data.result.recent;

          const tmpSummary = [
            {
              pvId: null,
              pvName: '合計',
              weight: recent.reduce((t, item) => {
                return bn(t)
                  .plus(item.weight)
                  .toNumber();
              }, 0),
              week: recent.reduce((t, item) => {
                return bn(item.weight)
                  .multipliedBy(item.week)
                  .plus(t)
                  .toNumber();
              }, 0),
              weekSim: recent.reduce((t, item) => {
                return bn(item.weight)
                  .multipliedBy(item.weekSim)
                  .plus(t)
                  .toNumber();
              }, 0),
              month: recent.reduce((t, item) => {
                return bn(item.weight)
                  .multipliedBy(item.month)
                  .plus(t)
                  .toNumber();
              }, 0),
              monthSim: recent.reduce((t, item) => {
                return bn(item.weight)
                  .multipliedBy(item.monthSim)
                  .plus(t)
                  .toNumber();
              }, 0),
              quarter: recent.reduce((t, item) => {
                return bn(item.weight)
                  .multipliedBy(item.quarter)
                  .plus(t)
                  .toNumber();
              }, 0),
              quarterSim: recent.reduce((t, item) => {
                return bn(item.weight)
                  .multipliedBy(item.quarterSim)
                  .plus(t)
                  .toNumber();
              }, 0),
              year: recent.reduce((t, item) => {
                return bn(item.weight)
                  .multipliedBy(item.year)
                  .plus(t)
                  .toNumber();
              }, 0),
              yearSim: recent.reduce((t, item) => {
                return bn(item.weight)
                  .multipliedBy(item.yearSim)
                  .plus(t)
                  .toNumber();
              }, 0)
            }
          ];

          setRecentData(recent);
          setSummaryData(tmpSummary);
        } else {
          setRecentData([]);
          setSummaryData([]);
        }
      })
      .catch(err => {
        if (!axios.isCancel(err)) {
          console.error(err);
        }
      });
  }, [currentSPV, selectDate]);
  const colors = {
    conditionContainerBgColor: solaTheme.componentColors.ConditionContainerBgColor,
    hrColor: solaTheme.componentColors.TitleContainerHrColor
  };
  useEffect(() => {
    loadData();

    return () => {
      if (CancelFnList && CancelFnList.length > 0) {
        CancelFnList.forEach(fn => {
          fn();
        });
      }
    };
  }, [loadData]);

  return (
    <div>
      <TitleContainer>
        <TitleWithIcon title="績效分析(近期)" prefixIcon={BarChartIcon} />
      </TitleContainer>
      <ConditionContainer {...colors}>
        <JTooltip desc={tableDesc} />
        <DateSelectorBlock>
          <DateSelector selectDate={selectDate} dateChangeFn={handleDatePickerChange} navButton={false} />
        </DateSelectorBlock>

        <SwitchSelector label="含推估收益" value={simulate} changeFn={handleSimulateSwitch} />
      </ConditionContainer>
      <JTable columns={tableColumns} data={recentData} summaryData={summaryData} />
    </div>
  );
};

const ConditionContainer = styled.div`
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 15px;
  padding-right: 7px;
  background-color: ${p => p.conditionContainerBgColor};
  border-bottom: ${p => (p.transparent ? '0px' : `1px solid ${p.hrColor}`)};
`;
const DateSelectorBlock = styled.div`
  width: 80%;
  text-align: cetner;
`;

export default PerformanceAnalysisRecent;
