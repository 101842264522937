import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
//
import { useProvided } from '../../stores/index';
import useAssetTheme from '../../stores/useAssetTheme';

const OneLayerMenuItem = props => {
  const { solaTheme } = useProvided(useAssetTheme);
  const { menu, routePage, onHandlePanel } = props;
  const isPageSelected = routePage === menu.page;

  const titleContainerStyle = {
    txColor: solaTheme.componentColors.MenuL1TxColor,
    txSelectColor: solaTheme.componentColors.MenuL1TxSelectColor,
    txHoverColor: solaTheme.componentColors.MenuL1TxHoverColor
  };

  return (
    <ExpansionPanel expanded={false} onChange={onHandlePanel('')}>
      <ExpansionPanelSummary>
        <Link to={menu.path} style={{ width: '100%' }}>
          <MenuTitleContainer selected={isPageSelected} styles={titleContainerStyle}>
            <MenuTitleIcon>{menu.icon}</MenuTitleIcon>
            <MenuTitleName>{menu.name}</MenuTitleName>
          </MenuTitleContainer>
        </Link>
      </ExpansionPanelSummary>
    </ExpansionPanel>
  );
};

const MenuTitleContainer = styled.div`
  width: 100%;
  height: 70px;
  color: ${p => (p.selected ? p.styles.txSelectColor : p.styles.txColor)};

  display: flex;
  align-items: center;

  margin-left: 0px;

  &:hover {
    color: ${p => p.styles.txHoverColor};
  }

  &:hover .svgHover {
    fill: ${p => p.styles.txHoverColor};
  }

  .svgSelect {
    fill: ${p => (p.selected ? p.styles.txSelectColor : p.styles.txColor)};
  }
`;
const MenuTitleIcon = styled.div`
  width: 15px;
  height: 15px;
  margin-right: 10px;
`;
const MenuTitleName = styled.div`
  font-size: 17px;
`;

export default OneLayerMenuItem;
