import React, { useState } from 'react';
import styled from 'styled-components';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
//
import { useProvided } from '../../stores/index';
import useAssetTheme from '../../stores/useAssetTheme';

const DropDownList = ({ label, value, options, width, changeFn }) => {
  const { solaTheme } = useProvided(useAssetTheme);
  const [open, setOpen] = useState(false);

  const ddlColors = {
    border: solaTheme.componentColors.DDLBorderColor,
    label: solaTheme.componentColors.DDLLabelTxColor,
    value: solaTheme.componentColors.DDLValueTxColor,
    itemBg: solaTheme.componentColors.DDLItemBgColor,
    itemTx: solaTheme.componentColors.DDLItemTxColor,
    itemBorder: solaTheme.componentColors.DDLItemBorderColor,
    itemHoverBg: solaTheme.componentColors.DDLItemHoverBgColor,
    itemHoverTx: solaTheme.componentColors.DDLItemHoverTxColor,
    itemSelectBg: solaTheme.componentColors.DDLItemSelectBgColor,
    itemSelectTx: solaTheme.componentColors.DDLItemSelectTxColor
  };

  const getValueName = () => {
    const item = options.find(o => o.key === value);
    if (item) {
      return item.name;
    } else {
      return '請選擇';
    }
  };

  const handleItemsToggle = () => {
    setOpen(o => !o);
  };
  const handleItemsClose = () => {
    setOpen(false);
  };

  const handleItemClick = selectValue => () => {
    if (selectValue && selectValue !== value) {
      changeFn({ target: { value: selectValue } });
    }
  };

  return (
    <Container colors={ddlColors}>
      <Label className="label">{label}</Label>
      <Frame
        className="frame"
        tabIndex="1"
        width={width}
        onClick={handleItemsToggle}
        onBlur={handleItemsClose}
      >
        <Value className="value">{getValueName()}</Value>
        <Icon className="icon">
          <KeyboardArrowDown />
        </Icon>
        <Items open={open} width={width}>
          {options && options.length > 0
            ? options.map((m, index) => {
                let class_name = 'item';
                let event_param = m.key;
                if (m.key === value) {
                  class_name = 'item-select';
                  event_param = null;
                }

                return (
                  <Item
                    key={index}
                    className={class_name}
                    value={m.key}
                    onClick={handleItemClick(event_param)}
                  >
                    {m.name}
                  </Item>
                );
              })
            : null}
        </Items>
      </Frame>
    </Container>
  );
};

const Container = styled.div`
  margin: 10px 15px;
  display: flex;
  align-items: center;
  position: relative;

  .frame {
    border: 1px solid ${p => p.colors.border};
  }
  .label,
  .icon {
    color: ${p => p.colors.label};
  }
  .value {
    color: ${p => p.colors.value};
  }
  .item {
    background-color: ${p => p.colors.itemBg};
    color: ${p => p.colors.itemTx};
    border-bottom: 1px solid ${p => p.colors.itemBorder}

    &:hover {
      background-color: ${p => p.colors.itemHoverBg};
      color: ${p => p.colors.itemHoverTx};
    }
  }
  .item-select {
    background-color: ${p => p.colors.itemSelectBg};
    color: ${p => p.colors.itemSelectTx};
  }
`;
const Label = styled.div`
  margin-right: 8px;
`;
const Frame = styled.div`
  width: ${p => p.width || '150px'};
  height: 30px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: default;

  position: relative;
  z-index: 100;
`;
const Value = styled.div`
  margin-left: 10px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
`;
const Icon = styled.div`
  width: 24px;
  height: 24px;
`;
const Items = styled.div`
  width: ${p => p.width || '150px'};
  max-height: 200px;
  overflow-y: auto;
  cursor: default;

  display: ${p => (p.open ? 'block' : 'none')};
  position: absolute;
  top: 40px;
  right: 0px;
  z-index: 200;
`;
const Item = styled.div`
  height: 30px;
  max-height: 30px;
  font-size: 14px;
  display: flex;
  align-items: center;
  padding: 3px 10px;
  white-space: nowrap;
  overflow: hidden;
`;

export default DropDownList;
