import { useState } from 'react';
import { Container } from './index';

//custome hook for shared Auth
const useAuth = () => {
  //init value.
  const initCurrentSPV = {
    userId: window.sessionStorage.getItem('userId') || 0,
    userName: window.sessionStorage.getItem('userName') || '',
    userList: JSON.parse(window.sessionStorage.getItem('userList')) || null,
    managerId: window.sessionStorage.getItem('managerId') || 0,
    managerEmail: window.sessionStorage.getItem('managerEmail') || '',
    token: window.sessionStorage.getItem('token') || '',
    guest: false
  };

  //declare.
  const [currentSPV, setCurrentSPV] = useState({ ...initCurrentSPV });

  //actions.
  const changeCurrentSPV = spv => {
    setCurrentSPV(spv);
  };
  const logoutSPV = () => {
    //setCurrentSPV({ ...initCurrentSPV });
    window.location.replace('/login'); //back to login page. TODO: 待優化
  };

  //global shared variables or functions.
  return { currentSPV, changeCurrentSPV, logoutSPV };
};

//remenber use Container to wrap
export default Container(useAuth);
