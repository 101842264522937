import { createGlobalStyle } from 'styled-components';
import BgTrapzoid from '../images/svg/bg_trapezoid.svg';
import WeatherIcon from '../images/svg/weather.svg';
import DarkDayIcon from '../images/svg/wDark_day.svg';
import DarkDayCloudyIcon from '../images/svg/wDark_day_cloudy.svg';
import DarkCloudyIcon from '../images/svg/wDark_cloudy.svg';
import DarkOvercastIcon from '../images/svg/wDark_overcast.svg';
import DarkCloudyRainyIcon from '../images/svg/wDark_cloudy_rainy.svg';
import DarkRainyIcon from '../images/svg/wDark_rainy.svg';
import DarkThunderRainyIcon from '../images/svg/wDark_thunder_rainy.svg';
import TreePng from '../images/tree_dark.png';
import IrregularPic from '../images/svg/irregular_pic.svg';

//basic color.
//black
const Black = '#000000';
// const Black_1b = '#1b1d21';
const Black_16 = '#16161a';
//blue
const Blue_a0 = '#a0c1cb';
const Blue_7a = '#7ab1c1';
const Blue_57 = '#5793f3';
const Blue_51 = '#519aaf';
//Gray
const Gray_bd = '#bdbdbd';
const Gray_b5 = '#b5b5b5';
const Gray_a0 = '#a0a7ad';
const Gray_6c = '#6c7377';
// const Gray_69 = '#696a6d';
// const Gray_65 = '#656a71';
const Gray_62 = '#62676E';
const Gray_58 = '#585c60';
const Gray_40 = '#40484a';
const Gray_3c = '#3c3325';
const Gray_37 = '#373e46';
const Gray_33 = '#33373b';
const Gray_31 = '#313235';
const Gray_2f = '#2f3033';
const Gray_2d = '#2d3033';
const Gray_2c = '#2c3034';
const Gray_27 = '#272a2c';
const Gray_26 = '#26282b';
// const Gray_21 = '#212326';
const Gray_20 = '#202326';
const Gray_19 = '#191b1d';
const Gray_17 = '#171a1c';
const Gray_1d = '#1d1f22';
//green
const Green = '#2bbc8f';
//red
const Red = '#ff4743';
//Transparent
const Transparent = 'rgba(255,255,255,0)';
//white
const White = '#ffffff';
const White_e9 = '#e9e9e9';
//Yellow
const Yellow = '#f7b100';
const Yellow_c4 = '#c45300';
const Yellow_94 = '#946f22';
const Yellow_75 = '#753100';
const Yellow_3a = '#3a1800';
const Yellow_13 = '#130800';

export const DarkTheme = {
  componentColors: {
    //AppLayout view.
    SideBgColor: 'rgba(37, 39, 42, 0.75)', //'#25272a',
    ContentBgColor: Gray_20,
    //Header component.
    HeaderBgColor: Gray_27,
    //Footer component.
    FooterBgColor: Gray_27,
    FooterTxColor: Gray_b5,
    //Avatar component.
    AvatarIconBgColor: Red,
    AvatarIconTxColor: White,
    AvatarNameTxColor: White,
    AvatarEmailTxColor: Gray_a0,
    AvatarDetailBgColor: Gray_26,
    AvatarDetailBgHoverColor: Gray_2f,
    AvatarDetailTxColor: Gray_b5,
    AvatarDetailTxHoverColor: Yellow,
    AvatarDetailHrColor: Gray_1d,
    //Menu component.
    MenuL1BgColor: Transparent,
    MenuL1TxColor: White,
    MenuL1TxSelectColor: Yellow,
    MenuL1TxHoverColor: Yellow,
    MenuL2BgColor: Gray_26,
    MenuL2BgHoverColor: Gray_2f,
    MenuL2TxColor: Gray_b5,
    MenuL2TxSelectColor: Yellow,
    MenuL2TxHoverColor: Yellow,
    MenuL2HrColor: Gray_1d,
    MenuL3BgColor: Gray_1d,
    MenuL3BgHoverColor: Gray_2f,
    MenuL3TxColor: Gray_b5,
    MenuL3TxSelectColor: Yellow,
    MenuL3TxHoverColor: Yellow,
    MenuL3HrColor: Gray_26,
    MenuCountBgSelectColor: Yellow,
    MenuCountTxColor: Gray_b5,
    MenuCountTxSelectColor: White,
    //TitleBarContainer
    TitleContainerHrColor: Gray_17,
    TitleContainerBgColor: Gray_20,
    //TitleWithICon
    TitleTxColor: White,
    //ConditionContainer
    ConditionContainerBgColor: Gray_20,
    //HintWithIcon
    HintTxColor: Gray_58,
    //Tab
    TabTxColor: Gray_58,
    TabTxSelectColor: Yellow,
    TabTxSelectSecondColor: White,
    TabHrColor: Gray_17,
    //Switch
    SwitchOffColor: Gray_bd,
    SwitchOnColor: Yellow,
    SwitchOnTrackColor: Yellow_94,
    SwitchLabelColor: Yellow,
    //Loading
    LoadingTxColor: Gray_6c,
    //ProfitCard
    ProfitCardBgColor: Gray_2c,
    ProfitCardBgImg: BgTrapzoid,
    ProfitCardTxColor: Yellow,
    ProfitCardDollarIconColor: Gray_40, //Dollar
    ProfitCardDollarTxColor: Yellow,
    ProfitCardChartLinePast: Yellow, //Chart
    ProfitCardChartLineNow: Gray_40,
    ProfitCardChartLineError: Red,
    ProfitCardChartLineBase: Gray_20,
    ProfitCardNoteTxColor: Yellow, //Note
    ProfitCardNoteTitleColor: White,
    ProfitCardNoteIconBase: null,
    ProfitCardNoteIconHover: null,
    ProfitCardClockTxColor: White, //Clock
    ProfitCardWeatherIcon: WeatherIcon, //Weather
    ProfitCardWeatherDayIcon: DarkDayIcon,
    ProfitCardWeatherDayCloudyIcon: DarkDayCloudyIcon,
    ProfitCardWeatherCloudyIcon: DarkCloudyIcon,
    ProfitCardWeatherOvercastIcon: DarkOvercastIcon,
    ProfitCardWeatherCloudyRainyIcon: DarkCloudyRainyIcon,
    ProfitCardWeatherRainyIcon: DarkRainyIcon,
    ProfitCardWeatherThunderRainyIcon: DarkThunderRainyIcon,
    //Dashboard-AccumulatedProfit
    DAPChartLineSimulate: Gray_b5,
    DAPChartLineBill: Yellow,
    //Dashboard-AssetInfo
    DAIIconColor: Gray_40,
    DAIHrColor: Gray_17,
    DAITxColor: Yellow,
    DAITitleTxColor: Gray_6c,
    DAISummaryTxColor: Yellow,
    //Dashboard-PvCounter
    DPCCircleStroke: Gray_1d,
    DPCBarBgStroke: Gray_3c,
    DPCBarStroke: Yellow,
    DPCCenterPanelTxColor: Yellow,
    DPCRunningTxColor: Yellow,
    DPCTotalTxColor: Gray_6c,
    //Dashboard-PowerGeneration
    DPGBatteryTxColor: White,
    DPGBatteryPicRect: 'rect-color-dark',
    DPGBatteryPicFrame: 'frame-color-dark',
    DPGHintTxColor: Gray_6c,
    //Dashboard-CO2
    DCTitleTxColor: Gray_6c,
    DCValueTxColor: Yellow,
    DCUnitTxColor: White,
    DCBgImg: TreePng,
    //Dashboard-Income
    DITitleTxColor: Gray_6c,
    DIValueTxColor: Yellow,
    DIUnitTxColor: White,
    DIBgImg: IrregularPic,
    //Dashboard-AchieveRateList
    DAREmptyTxColor: Gray_6c,
    DARHrColor: Gray_17,
    DARLeftTxColor: Gray_6c,
    DARRightTxColor: White,
    DARRadiusBgColor: Black,
    DARRateBgColor: Yellow,
    DARPgColor: Gray_19,
    DArPgSelectColor: Yellow,
    //Dashboard-BulletinList
    DBLEmptyTxColor: Gray_6c,
    DBLHrColor: Gray_17,
    DBLTxColor: Gray_6c,
    DBLPgColor: Gray_19,
    DBLPgSelectColor: Yellow,
    //Tip
    TipBgColor: Black,
    TipTxColor: Yellow,
    TipTitleColor: White,
    TipHrColor: Gray_37,
    TipIconColor: Gray_58,
    //JTable
    JTHeadThFix: Gray_20,
    JTHeadTh: Gray_20,
    JTHeadThFirst: Gray_20,
    JTHeadThSecond: Gray_20,
    JTBodyTdFix: Gray_20,
    JTBodyTd: Gray_20,
    JTFootTdFix: Gray_31,
    JTFootTd: Gray_31,
    JTTextColor: White,
    JTTextSelectedColor: Yellow,
    JTBorder: Gray_17,
    JTBorderSelected: Yellow,
    //MTable
    MTHeaderTxColor: Gray_6c,
    MTHeaderBgColor: Gray_20,
    MTHeaderBorderColor: Gray_17,
    MTRowBgColor: Gray_20,
    MTRowBorderColor: Gray_17,
    MTRowSelectBgColor: Gray_2d,
    MTRowSelectBorderColor: Yellow,
    MTPaggingBtnBorderColor: Gray_6c,
    MTPaggingBtnTxColor: Gray_6c,
    MTPaggingBgColor: Gray_20,
    MTPaggingTxColor: Gray_6c,
    MTPaggingSelectTxColor: Yellow,
    //PvData
    PvDialogTitleBgColor: Gray_17,
    PvDialogTitleTxColor: Blue_57,
    PvDialogItemBorderColor: Gray_33,
    PvDialogItemHoverTxColor: Yellow,
    PvDialogItemHoverBgColor: Gray_2f,
    //PvInfo-PvData
    PvStatusNoneColor: Gray_6c,
    PvStatusPowerOnColor: Green,
    PvStatusPowerBuildColor: Yellow,
    PvStatusPowerMaintainColor: Red,
    PvDataTitleTxColor: Gray_6c,
    PvDataValueTxColor: White,
    PvDataColumnBorderColor: Gray_17,
    //PvInfo-PvStage
    PvStageBaseLineColor: Gray_31,
    PvStageBasePointColor: Gray_31,
    PvStagePassStageTxColor: Gray_6c,
    PvStageCurrentStageTxColor: White,
    PvStageFutureStageTxColor: Gray_6c,
    //PvInfo-PvChart
    PvChartBgColor: Black_16,
    PvChartBarColor: Yellow,
    //PvInfo-PvImage
    PvImageSelectBorderColor: Yellow,
    //Calendar
    CalendarUnknownEventColor: White,
    CalendarPvEventColor: Blue_57,
    CalendarSimulateEventColor: Green,
    CalendarBillEventColor: Yellow,
    CalendarHeaderTxColor: Gray_6c,
    CalendarDaySameMonthBgColor: Gray_1d,
    CalendarDayOtherMonthBgColor: Gray_26,
    CalendarDayTxColor: White,
    CalendarDaySelectColor: Yellow,
    CalendarDetailBgColor: Gray_1d,
    CalendarDetailItemBgColor: Gray_26,
    CalendarDetailTitleColor: Gray_6c,
    //DateSelector
    DateSelectorTxColor: Gray_6c,
    DateSelectorBgColor: Gray_20,
    //Transaction-Filter
    FilterTxColor: Gray_6c,
    FilterBgColor: Gray_20,
    FilterBorderColor: Gray_6c,
    FilterSelectTxColor: Yellow,
    FilterSelectBgColor: Gray_20,
    FilterSelectBorderColor: Yellow,
    //TimeFilter
    TFilterTxColor: Gray_6c,
    TFilterBgColor: Gray_20,
    TFilterBorderColor: Gray_6c,
    TFilterSelectTxColor: Yellow,
    TFilterSelectBgColor: Gray_20,
    TFilterSelectBorderColor: Yellow,
    //nivo Bar color
    BarColor1: Yellow,
    BarColor2: Yellow_c4,
    BarColor3: Yellow_75,
    BarColor4: Yellow_3a,
    BarColor5: Yellow_13,
    //Portfolio-CumulativeIncome
    PCILineChartBillColor: Yellow,
    PCILineChartSimulateColor: Gray_6c,
    //UserAssets
    UAPieChartMainColor: Blue_51,
    UAPieChartColors: [Blue_7a, Blue_a0],
    UAPieChartSelectColor: Yellow,
    UAPieChartTxColor: Gray_33,
    UAPieListTxColor: White_e9,
    UAPieListBorderColor: Gray_6c,
    UAPieListHoverBgColor: Gray_26,
    UAPieListSelectBgColor: Gray_26,
    UAPieListSelectBorderColor: Yellow,
    UAPieDetailTitleColor: White,
    UAPieDetailPvTxColor: Yellow,
    UAPieDetailValueTxColor: Yellow,
    UAPieDetailTotalTxColor: White,
    UABarChartTxColor: Gray_20,
    //Abstract
    PvCountTextColor: Yellow,
    //DropDownList
    DDLBorderColor: Gray_6c,
    DDLLabelTxColor: Gray_6c,
    DDLValueTxColor: Gray_6c,
    DDLItemBgColor: Gray_33,
    DDLItemTxColor: White,
    DDLItemBorderColor: Gray_62,
    DDLItemHoverBgColor: White_e9,
    DDLItemHoverTxColor: Gray_33,
    DDLItemSelectBgColor: Blue_51,
    DDLItemSelectTxColor: White,
    //EmptyMsg
    EmptyTxColor: White,
    //Account
    AccountCardBgColor: Gray_2c,
    AccountCardBorderColor: Gray_40,
    AccountCardTitleColor: White_e9,
    AccountCardTxColor: Yellow,
    AccountCardDescColor: Gray_6c,
    AccountDialogBgColor: Gray_2c,
    AccountDialogBorderColor: Gray_17,
    AccountDialogTitleColor: Yellow,
    AccountDialogStepIconBgColor: Yellow,
    AccountDialogStepIconTxColor: Black,
    //ChangeSpv
    CSTitleTxColor: White,
    CSItemBgColor: Gray_a0,
    CSItemTxColor: Black,
    CSItemSelectBgColor: Yellow,
    CSItemSelectTxColor: White,
    //Login
    LoginBgColor: Gray_2d,
    LoginTxColor: White,
    //ForgetPassword
    FPBgColor: Gray_2d,
    FPTxColor: White,
    FPSubtitleColor: Red,
    //ResetPassword
    RPBgColor: Gray_2d,
    RPTxColor: White,
    RPTitleTxColor: Yellow
  },
  muiTheme: {
    palette: {
      type: 'dark',
      primary: { main: Yellow, contrastText: White },
      typography: { useNextVariants: true }
    },
    overrides: {
      MuiButton: {
        root: {
          fontFamily: `'Roboto', 'Noto Sans TC', sans-serif`
        }
      },
      MuiButtonBase: {
        root: {
          alignItems: 'stretch'
        }
      },
      MuiExpansionPanel: {
        root: {
          backgroundColor: Transparent,
          margin: '0px',
          padding: '0px',
          border: '0px',
          borderBottom: `1px solid ${Gray_26}`,
          position: 'static',
          '&$expanded': {
            margin: '0px',
            padding: '0px',
            border: '0px'
          }
        }
      },
      MuiExpansionPanelSummary: {
        root: {
          margin: '0px',
          padding: '0px 0px 0px 30px',
          border: '0px',
          '&$expanded': {
            minHeight: '48px'
          }
        },
        content: {
          margin: '0px',
          padding: '0px',
          border: '0px',
          '&$expanded': {
            margin: '0px'
          }
        },
        expandIcon: {
          marginRight: '5px'
        }
      },
      MuiExpansionPanelDetails: {
        root: {
          margin: '0px',
          padding: '0px',
          border: '0px',
          display: 'block'
        }
      },
      MuiTooltip: {
        tooltipPlacementTop: {
          backgroundColor: Black,
          color: White,
          fontSize: '18px'
        },
        tooltipPlacementBottom: {
          backgroundColor: Transparent,
          minWidth: '500px',
          padding: '0px'
        }
      },
      MuiSelect: {
        root: {
          minWidth: '150px',
          padding: '3px 10px',
          border: '1px solid ' + Gray_58,
          color: Gray_58
        },
        select: {
          minWidth: '150px'
        }
      },
      MuiTableCell: {
        root: {
          borderBottomColor: Gray_17
        },
        body: {
          color: White
        }
      },
      MuiTableSortLabel: {
        root: {
          '&$active': {
            color: Yellow
          }
        }
      },
      MuiPaper: {
        root: {
          backgroundColor: Gray_20
        },
        elevation1: {
          boxShadow: 'none'
        },
        elevation2: {
          boxShadow: 'none'
        }
      },
      MuiOutlinedInput: {
        root: {
          height: '35px'
        }
      }
    }
  },
  nivoTheme: {
    axis: {
      ticks: {
        text: {
          fill: White //Gray_6c
        }
      }
    },
    crosshair: {
      line: {
        stroke: Yellow
      }
    },
    tooltip: {
      container: {
        padding: '0px'
      }
    },
    //The nivo chart do not have 'legends' setting here, it is added by developer.
    legends: {
      text: {
        fill: White
      }
    }
  },
  globalStyle: createGlobalStyle`
    @import url('https://fonts.googleapis.com/css?family=Montserrat|Noto+Sans+TC&display=swap');

    body {
      font-family: 'Montserrat', 'Noto Sans TC', 'Microsoft YaHei', sans-serif !important;
      margin: 0;
      padding: 0;
      border: 0;
    };

    a {
      text-decoration: none;
      color: ${Gray_6c};
    };

    a:hover {
      color: ${Yellow};
    }

    ::-webkit-scrollbar {
      width: 0px;
      height: 5px;
    };
    ::-webkit-scrollbar-thumb {
        background: ${Gray_b5};
    };

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active
    {
      /* background transparent */
      -webkit-box-shadow: 0 0 0px 1000px ${Transparent} inset;
      transition: background-color 5000s ease-in-out 0s;
      /* white font */
      -webkit-text-fill-color: #fff;
    }

    .MuiPickersCalendarHeader-iconButton {
      background-color: ${Transparent} !important;
    }
    .MuiPickersCalendarHeader-iconButton > * {
      background-color: ${Transparent} !important;
    }
  `
};
