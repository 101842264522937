import React, { useMemo } from 'react';
import styled from 'styled-components';
import TitleContainer from '../Title/TitleContainer';
import TitleWithIcon from '../Title/TitleWithIcon';
import EmptyMsg from '../EmptyMsg';
import ChartTooltip from '../Tooltip/ChartTooltip';
import { ReactComponent as ListIcon } from '../../images/svg/list_with_dot_vertical.svg';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
//
import { useProvided } from '../../stores/index';
import useAssetTheme from '../../stores/useAssetTheme';

const PvChart = ({ powerData }) => {
  const { solaTheme } = useProvided(useAssetTheme);

  const chartDom = useMemo(() => {
    if (powerData && powerData.data && powerData.data.length > 0) {
      return (
        <ChartContainer bgColor={solaTheme.componentColors.PvChartBgColor}>
          <ResponsiveContainer width="97%" aspect={2.5}>
            <BarChart
              data={powerData.data}
              margin={{
                top: 50,
                right: 5,
                left: 15,
                bottom: 60
              }}
            >
              <XAxis dataKey="date" tick={{ angle: -45, fontSize: 12 }} tickMargin={30} />
              <YAxis hide={true} />
              <Tooltip content={<CustomTooltip />} />
              <Bar dataKey="value" fill={solaTheme.componentColors.PvChartBarColor} />
            </BarChart>
          </ResponsiveContainer>
        </ChartContainer>
      );
    } else {
      return <EmptyMsg msg="查無資料" />;
    }
  }, [powerData, solaTheme.componentColors]);

  return (
    <div>
      <TitleContainer hideTop>
        <TitleWithIcon title="發電數據" prefixIcon={ListIcon} />
      </TitleContainer>
      {chartDom}
    </div>
  );
};

const ChartContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 19px 15px;
  background-color: ${p => p.bgColor};
`;

const CustomTooltip = ({ active, payload, label }) => {
  if (active) {
    return (
      <ChartTooltip
        width="200px"
        iconColor={null}
        header={label}
        contents={[{ title: '發電量', value: payload[0].value, unit: '度' }]}
      />
    );
  } else {
    return null;
  }
};

export default PvChart;
