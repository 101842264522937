import React from 'react';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import AvatarTitle from './AvatarTitle';
import AvatarDetail from './AvatarDetail';
import { ReactComponent as SyncIcon } from '../../images/svg/sync.svg';
import { ReactComponent as UserIcon } from '../../images/svg/user.svg';
import { ReactComponent as LogoutIcon } from '../../images/svg/logout.svg';

import { useProvided } from '../../stores/index';
import useAuth from '../../stores/useAuth';
import useAssetTheme from '../../stores/useAssetTheme';

const Avatar = () => {
  const { currentSPV, logoutSPV } = useProvided(useAuth);
  const { changeTheme } = useProvided(useAssetTheme);

  const handleLogout = () => {
    window.sessionStorage.removeItem('token');
    window.sessionStorage.removeItem('userId');
    window.sessionStorage.removeItem('userName');
    window.sessionStorage.removeItem('userList');
    window.sessionStorage.removeItem('managerId');
    window.sessionStorage.removeItem('managerEmail');
    logoutSPV();
  };

  return (
    <ExpansionPanel>
      <ExpansionPanelSummary>
        <AvatarTitle userName={currentSPV.userName} email={currentSPV.managerEmail} />
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        {currentSPV.userList.length > 0 ? (
          <AvatarDetail name="更換使用者" to="/app/changeSpv" icon={SyncIcon} />
        ) : null}
        <AvatarDetail name="切換深/淺主題色" icon={SyncIcon} action={changeTheme} />
        <AvatarDetail name="使用者資訊" to="/app/account" icon={UserIcon} />
        <AvatarDetail name="登出" icon={LogoutIcon} action={handleLogout} />
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default Avatar;
