import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { Link as RouteLink } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';

import { useProvided } from '../../stores/index';
import useAuth from '../../stores/useAuth';
import useAssetTheme from '../../stores/useAssetTheme';

function Login() {
  const { solaTheme } = useProvided(useAssetTheme);
  const { changeCurrentSPV } = useProvided(useAuth);
  const [email, setEmail] = useState('');
  const [pwd, setPwd] = useState('');
  const [googleAuthCode, setGoogleAuthCode] = useState('');
  const [tempLoginInfo, setTempLoginInfo] = useState({});
  const [openAuthCodeDialog, setOpenAuthCodeDialog] = useState(false);

  const handleLoginClick = () => {
    if (email && pwd) {
      axios
        .post('/api/v1/auth/local', { email: email, pwd: pwd })
        .then(res => {
          const rsps = res.data;

          if (rsps && rsps.status === true) {
            if (rsps.result.users.length > 0) {
              if (rsps.result.manager) {
                if (rsps.result.manager.google2FAStatus) {
                  setTempLoginInfo({ ...rsps.result });
                  setOpenAuthCodeDialog(true);
                } else {
                  setLoginSPV(rsps.result);
                }
                return;
              }
            } else {
              toast.warn('此帳號目前沒有任何權限！');
            }
          }
          toast.warn(res.data.msg);
        })
        .catch(e => {
          console.error(e);
          toast.error('error occur, check broswer console.');
        });
    } else {
      toast.error('請填寫帳號、密碼。');
    }
  };
  const handleGoogle2FAClick = () => {
    axios
      .post(
        `/api/v1/auth/google2fa/verify`,
        { token: googleAuthCode },
        { headers: { Authorization: `Bearer ${tempLoginInfo.token}` } }
      )
      .then(res => {
        if (res.data.status === true && res.data.result.tokenValidates === true) {
          setLoginSPV(tempLoginInfo);
        } else {
          toast.error('你輸入的動態密碼驗證失敗！請重新輸入。');
        }
      })
      .catch(e => {
        console.log(e);
        toast.error('error occur, check broswer console.');
      });
  };
  const handleGoogel2FAClose = () => {
    setOpenAuthCodeDialog(false);
  };
  const setLoginSPV = loginInfo => {
    //
    const userId = loginInfo.currentUser;
    const userName = loginInfo.users.find(item => item.id === userId).name;
    const userList = loginInfo.users;
    const managerId = loginInfo.manager.id;
    const managerEmail = loginInfo.manager.email;
    const token = `Bearer ${loginInfo.token}`;

    //use this when user refresh page, but do not logout system.
    window.sessionStorage.setItem('token', token);
    window.sessionStorage.setItem('userId', userId);
    window.sessionStorage.setItem('userName', userName);
    window.sessionStorage.setItem('managerId', managerId);
    window.sessionStorage.setItem('managerEmail', managerEmail);
    window.sessionStorage.setItem('userList', JSON.stringify(userList));

    //global variable update.
    changeCurrentSPV({ userId, userName, userList, managerId, managerEmail, token });
  };

  const colors = {
    bg: solaTheme.componentColors.LoginBgColor,
    tx: solaTheme.componentColors.LoginTxColor
  };

  return (
    <LoginPanel colors={colors}>
      <TitlePanel>
        <CircleIcon />
        <span>登入 SOLA</span>
      </TitlePanel>
      <ContentPanel>
        <div>
          <InputLabel>電子信箱</InputLabel>
          <TextField
            data-testid="email"
            type="email"
            value={email}
            variant="outlined"
            fullWidth
            placeholder="please enter your account"
            onChange={event => setEmail(event.target.value)}
          />
          <InputLabel>密碼驗證</InputLabel>
          <TextField
            data-testid="pwd"
            type="password"
            value={pwd}
            variant="outlined"
            fullWidth
            placeholder="please enter your password"
            onChange={event => setPwd(event.target.value)}
          />
        </div>
        <ButtonPanel>
          <ButtonRow>
            <LoginButton
              data-testid="btn-login"
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              onClick={handleLoginClick}
            >
              登入
            </LoginButton>
          </ButtonRow>
          <ButtonRow>
            <RouteLink to="/forgetPwd">
              <Link data-testid="btn-forget-password" component="button" color="textSecondary">
                忘記密碼?
              </Link>
            </RouteLink>
          </ButtonRow>
        </ButtonPanel>
        <Dialog open={openAuthCodeDialog} fullWidth>
          <DialogTitle>Google Authenticator驗證</DialogTitle>
          <DialogContent>
            <DialogContentText>輸入Google Authenticator動態密碼</DialogContentText>
            <TextField
              data-testid="auth-code"
              autoFocus
              margin="dense"
              label="Auth Code"
              variant="outlined"
              fullWidth
              value={googleAuthCode}
              onChange={event => setGoogleAuthCode(event.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button data-testid="btn-2fa" variant="contained" color="primary" onClick={handleGoogle2FAClick}>
              驗證
            </Button>
            <Button
              data-testid="btn-2fa-cancel"
              variant="outlined"
              color="primary"
              onClick={handleGoogel2FAClose}
            >
              取消
            </Button>
          </DialogActions>
        </Dialog>
      </ContentPanel>
    </LoginPanel>
  );
}

const LoginPanel = styled.div`
  width: 60vw;
  min-width: 350px;
  max-width: 436px;
  height: 494px;
  opacity: 0.85;
  border-radius: 2px;
  background-color: ${p => p.colors.bg};
  color: ${p => p.colors.tx};
`;
const LoginButton = withStyles({
  root: {
    height: '45px',
    paddingTop: '5px'
  },
  label: {
    fontSize: '20px'
  }
})(Button);

const TitlePanel = styled.div`
  height: 50px;
  max-height: 50px;
  padding-left: 15px;
  border-bottom: 1px solid #33373b;
  display: flex;
  align-items: center;
`;
const ContentPanel = styled.div`
  height: 400px;
  max-height: 400px;
  padding: 0px 33px;
`;
const ButtonPanel = styled.div`
  margin-top: 40px;
`;
const ButtonRow = styled.div`
  text-align: right;
  margin-top: 10px;
`;
const CircleIcon = styled.div`
  width: 11px;
  height: 11px;
  border-radius: 5px;
  background-color: #f7b100;
  margin-right: 10px;
`;
const InputLabel = styled.div`
  margin: 30px 0px 15px 0px;
`;

export default Login;
