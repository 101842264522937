import React, { useState } from 'react';
import styled from 'styled-components';
import { PercentFormatFixedOne } from '../../commons/utils';
//
import { useProvided } from '../../stores/index';
import useAssetTheme from '../../stores/useAssetTheme';

const AchieveRateList = ({ data }) => {
  const { solaTheme } = useProvided(useAssetTheme);
  const [pageNumber, setPageNumber] = useState(1);
  const PAGE_SIZE = 6;
  const TOTAL_COUNT = data ? data.length : 0;
  let rateDOM = null;
  let dotDOM = null;

  const handleDotClick = pageIndex => () => {
    setPageNumber(pageIndex);
  };
  const contentStyle = {
    emptyColor: solaTheme.componentColors.DAREmptyTxColor,
    hrColor: solaTheme.componentColors.DARHrColor,
    leftTxColor: solaTheme.componentColors.DARLeftTxColor,
    rightTxColor: solaTheme.componentColors.DARRightTxColor,
    radiusColor: solaTheme.componentColors.DARRadiusBgColor,
    rateColor: solaTheme.componentColors.DARRateBgColor
  };

  const paggingStyle = {
    pgColor: solaTheme.componentColors.DARPgColor,
    pgSelectColor: solaTheme.componentColors.DArPgSelectColor
  };

  if (TOTAL_COUNT > 0) {
    // draw rate div.
    rateDOM = [];
    let startIndex = (pageNumber - 1) * PAGE_SIZE;
    const endIndex = startIndex + (PAGE_SIZE - 1);
    while (startIndex <= endIndex) {
      const d = data[startIndex];

      rateDOM.push(
        <Row key={startIndex} className="row">
          <LeftColumn className="left">
            {d.pvId} {d.pvName}
          </LeftColumn>
          <MiddleColumn>
            <RadiusDiv className="radius">
              <RateDiv width={d.rate * 100} className="rate" />
            </RadiusDiv>
          </MiddleColumn>
          <RightColumn className="right">{PercentFormatFixedOne(d.rate)}</RightColumn>
        </Row>
      );

      if (startIndex < TOTAL_COUNT - 1) {
        startIndex++;
      } else {
        break;
      }
    }

    //draw dot div.
    dotDOM = [];
    const dotNumber = TOTAL_COUNT % PAGE_SIZE === 0 ? TOTAL_COUNT / PAGE_SIZE : TOTAL_COUNT / PAGE_SIZE + 1;
    for (let i = 1; i <= dotNumber; i++) {
      if (i === pageNumber) {
        dotDOM.push(<YellowDot key={i} className="yellowDot" />);
      } else {
        dotDOM.push(<GrayDot key={i} className="grayDot" onClick={handleDotClick(i)} />);
      }
    }
  } else {
    rateDOM = <EmptyPanel className="empty">無資料</EmptyPanel>;
    dotDOM = <YellowDot className="yellowDot" />;
  }

  return (
    <TwoColumnContainer>
      <ContentRow styles={contentStyle}>{rateDOM}</ContentRow>
      <PageRow styles={paggingStyle}>{dotDOM}</PageRow>
    </TwoColumnContainer>
  );
};

const TwoColumnContainer = styled.div`
  width: 100%;
  height: 420px;
  padding-left: 15px;
`;
const ContentRow = styled.div`
  width: 100%;
  min-height: 370px;

  .empty {
    color: ${p => p.styles.emptyColor};
  }
  .row {
    border-bottom: 1px solid ${p => p.styles.hrColor};
  }
  .left {
    color: ${p => p.styles.leftTxColor};
  }
  .right {
    color: ${p => p.styles.rightTxColor};
  }
  .radius {
    background-color: ${p => p.styles.radiusColor};
  }
  .rate {
    background-color: ${p => p.styles.rateColor};
  }
`;
const EmptyPanel = styled.div`
  font-size: 20px;
  padding-left: 20px;
  padding-top: 20px;
`;
const Row = styled.div`
  width: 92%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const LeftColumn = styled.div`
  width: 40%;
`;
const MiddleColumn = styled.div`
  width: 40%;
`;
const RightColumn = styled.div`
  width: 12%;
  text-align: right;
`;
const RadiusDiv = styled.div`
  width: 90%;
  height: 5px;
  border-radius: 10px;

  display: flex;
  align-items: center;
  padding: 2px;
`;
const RateDiv = styled.div`
  width: ${props => props.width || '0'}%;
  height: 3px;
  border-radius: 5px;
`;
const PageRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;

  .yellowDot {
    background-color: ${p => p.styles.pgSelectColor};
  }
  .grayDot {
    background-color: ${p => p.styles.pgColor};
  }
`;
const YellowDot = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 7px;
  margin: 10px;
  cursor: pointer;
`;
const GrayDot = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 7px;
  margin: 10px;
  cursor: pointer;
`;

export default AchieveRateList;
