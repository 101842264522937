import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import TitleContainer from '../../components/Title/TitleContainer';
import TitleWithIcon from '../../components/Title/TitleWithIcon';
import { ReactComponent as BarChartIcon } from '../../images/svg/bar_chart.svg';
import LineChart from '../../components/Chart/LineChart';
import ChartTooltip from '../../components/Tooltip/ChartTooltip';
import EmptyMsg from '../../components/EmptyMsg';
import { useProvided } from '../../stores/index';
import useAuth from '../../stores/useAuth';
import useAssetTheme from '../../stores/useAssetTheme';
import moment from 'moment';

const EventDetailChart = ({ dataRow, closeFn, selectDate }) => {
  const { currentSPV } = useProvided(useAuth);
  const { solaTheme } = useProvided(useAssetTheme);
  const chartColors = [
    solaTheme.componentColors.DAPChartLineSimulate,
    solaTheme.componentColors.DAPChartLineBill,
  ];
  const [chartData, setChartData] = useState({});

  const boxStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1200,
    height: 600,
    background: 'white',
    border: '2px solid #000',
    boxShadow: 24,
  };

  useEffect(() => {
    if (dataRow.pvId && selectDate) {
      axios
        .get(`/api/v1/event/getMQTTInsolationChartData?date=${selectDate}&pvId=${dataRow.pvId}`, {
          headers: { Authorization: currentSPV.token },
        })
        .then((res) => {
          if (res && res.data && res.data.status) {
            setChartData(res.data.result.data);
          } else {
            toast.error('load data failed');
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [currentSPV, dataRow.pvId, selectDate]);

  return (
    <Box style={boxStyle}>
      {dataRow && (
        <TitleContainer>
          <TitleWithIcon
            title={dataRow.pvName + ' ( ' + dataRow.pvId + ' ) 電廠瞬時發電曲線圖'}
            prefixIcon={BarChartIcon}
            size={16}
          />
        </TitleContainer>
      )}
      <ChartContainer>
        {chartData && chartData.data ? (
          <LineChart
            data={chartData.data}
            margin={{ top: 50, right: 135, bottom: 50, left: 90 }}
            width={1180}
            height={500}
            axisLeft={{
              orient: 'right',
              tickSize: 5,
              tickPadding: 5,
              legend: '發電量(度)',
              legendPosition: 'middle',
              legendOffset: -40,
            }}
            axisBottom={{
              orient: 'bottom',
              tickSize: 5,
              tickPadding: 3,
              legend: '時間',
              legendPosition: 'middle',
              legendOffset: 40,
              format: (value) => FiltTickValue(value),
            }}
            theme={{ textColor: '#000000' }}
            lineWidth={1}
            enableArea={true}
            areaBaselineValue={0}
            enableGridX={false}
            enableSlices="x"
            sliceTooltip={({ slice }) => GenerateToolTipDom(slice, chartColors)}
            colors={chartColors}
            legends={[
              {
                anchor: 'bottom-right',
                direction: 'column',
                justify: false,
                translateX: 120,
                translateY: 0,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 25,
                itemDirection: 'left-to-right',
                itemOpacity: 0.85,
                itemTextColor: '#000000',
                symbolSize: 20,
              },
            ]}
          />
        ) : (
          <EmptyMsg msg="畫圖中..." />
        )}
      </ChartContainer>
      <ButtonRow>
        <Button color="primary" onClick={closeFn}>
          關閉
        </Button>
      </ButtonRow>
    </Box>
  );
};

const ChartContainer = styled.div`
  height: 450px;
`;
const ButtonRow = styled.div`
  padding-top: 3%;
  padding-right: 1.5%;
  display: flex;
  justify-content: flex-end;
`;

const FiltTickValue = (value) => {
  let visible = moment(value).format('mm') % 60 === 0;
  return `${visible ? moment(value).format('HH:mm') : ''}`;
};

const GenerateToolTipDom = (slice, chartColors) => {
  const date = slice.points[0].data.xFormatted;
  let insolationTitle = '純衛星推估發電量';
  let correctionTitle = '實際發電量';
  let insolationData = 0;
  let correctionData = 0;
  let insolationColor = chartColors[0];
  let correctionColor = chartColors[1];

  const insolationItem = slice.points.find((i) => i.serieId === insolationTitle);
  if (insolationItem) {
    insolationData = insolationItem.data.y;
  } else {
    insolationData = null;
  }

  const correctionItem = slice.points.find((c) => c.serieId === correctionTitle);
  if (correctionItem) {
    correctionData = correctionItem.data.y;
  } else {
    correctionData = null;
  }

  return (
    <ChartTooltip
      width="200px"
      center={slice.x}
      iconColor={null}
      header={date}
      contents={[
        { title: correctionTitle, value: correctionData, unit: '度', color: correctionColor },
        { title: insolationTitle, value: insolationData, unit: '度', color: insolationColor },
      ]}
    />
  );
};
export default EventDetailChart;
