import React from 'react';
import styled from 'styled-components';
import LineChart from '../Chart/LineChart';
import { IntegerFormat } from '../../commons/utils';
//
import { useProvided } from '../../stores/index';
import useAssetTheme from '../../stores/useAssetTheme';

const FindMinValue = (source, currentMinValue) => {
  if (source && source.data) {
    source.data.forEach(d => {
      if (d.y !== null && d.y < currentMinValue) {
        currentMinValue = d.y;
      }
    });
  }

  return currentMinValue;
};

const AccumulatedProfit = ({ data }) => {
  const { solaTheme } = useProvided(useAssetTheme);
  if (data) {
    const LineStyle = [
      solaTheme.componentColors.DAPChartLineBill,
      solaTheme.componentColors.DAPChartLineSimulate,
      solaTheme.componentColors.DAPChartLineBill
    ];
    const tooltipStyle = {
      bgColor: solaTheme.componentColors.TipBgColor,
      titleTxColor: solaTheme.componentColors.TipTitleColor,
      simulateTxColor: solaTheme.componentColors.DAPChartLineSimulate,
      billTxColor: solaTheme.componentColors.DAPChartLineBill
    };

    //find the min value from data.
    let minValue = 99999999999;
    minValue = FindMinValue(data[0], minValue);
    minValue = FindMinValue(data[1], minValue);

    return (
      <ChartContainer>
        <LineChart
          animate={false}
          axisLeft={null}
          axisTop={null}
          axisRight={{
            orient: 'right',
            tickSize: 0,
            tickPadding: 8,
            format: value => IntegerFormat(value / 10000, '', '萬')
          }}
          axisBottom={{
            orient: 'bottom',
            tickSize: 0,
            tickPadding: 8,
            tickRotation: -40
            //format: value => value.replace('-', '年') + '月'
          }}
          colors={LineStyle}
          data={data}
          enableArea={true}
          areaBaselineValue={minValue}
          enableGridX={false}
          enableGridY={false}
          enableCrosshair={true}
          enableSlices="x"
          margin={{ top: 10, right: 40, bottom: 80, left: 40 }}
          xScale={{ type: 'point' }}
          yScale={{ type: 'linear', stacked: false, min: minValue, max: 'auto' }}
          xFormat={value => value.replace('-', '年') + '月'}
          yFormat={value => IntegerFormat(value, '', '元')}
          sliceTooltip={({ slice }) => GetLineChartSliceToolTip(slice, tooltipStyle)}
          legends={[]}
        />
      </ChartContainer>
    );
  } else {
    return null;
  }
};

const ChartContainer = styled.div`
  width: 95%;
  height: 80%;
  max-width: 480px;
  max-height: 300px;
  margin-left: 15px;
`;
const SliceTooltipContainer = styled.div`
  position: relative;
  left: ${props => (props.x > 100 ? '-100px' : '0px')}
  background-color: ${p => p.bgColor || '#000'}
  padding: 8px;
  border-radius: 5px;
`;

const GetLineChartSliceToolTip = (slice, styles) => {
  const date = slice.points[0].data.xFormatted;
  let bill = 0;
  let simulate = 0;

  const billItem = slice.points.find(p => p.serieId === 'bill');
  const simulateItem = slice.points.find(p => p.serieId === 'simulate');

  if (billItem && simulateItem) {
    bill = billItem.data.yFormatted;
    simulate = simulateItem.data.yFormatted;
    if (bill === simulate) {
      simulate = 0;
    }
  } else if (billItem) {
    bill = billItem.data.yFormatted;
  } else if (simulateItem) {
    simulate = simulateItem.data.yFormatted;
  } else {
    //do nothing.
  }

  return (
    <SliceTooltipContainer x={slice.x} bgColor={styles.bgColor}>
      <div style={{ color: styles.titleTxColor }}>
        <b>{date}</b>
      </div>
      <div>
        {bill ? <div style={{ color: styles.billTxColor }}>實際累積收益： {bill}</div> : null}
        {simulate ? <div style={{ color: styles.simulateTxColor }}>推估累積收益： {simulate}</div> : null}
      </div>
    </SliceTooltipContainer>
  );
};

export default AccumulatedProfit;
