import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

function Error() {
  return (
    <ErrorPanel>
      <Typography variant="h1" color="primary">
        404
      </Typography>
      <Typography variant="h5" color="primary">
        喔喔！您所瀏覽的頁面似乎已不存在。
      </Typography>
      <Typography variant="h6" color="primary">
        請您回到首頁後，重新操作。
      </Typography>
      <ButtonRow>
        <Button variant="contained" color="primary" component={Link} to="/app" size="large">
          回首頁
        </Button>
      </ButtonRow>
    </ErrorPanel>
  );
}

const ErrorPanel = styled.div`
  width: 500px;
  height: 494px;
  max-width: 500px;
  max-height: 494px;
  opacity: 0.85;
  border-radius: 2px;
  background-color: #2d3033;
  padding: 5px 20px;
`;

const ButtonRow = styled.div`
  width: 100%;
  margin-top: 30px;
`;

export default Error;
