import React, { useState, useMemo, useCallback, useEffect } from 'react';
import axios from 'axios';
import bn from 'bignumber.js';
import moment from 'moment';
import styled from 'styled-components';

//common
import { PercentFormat, GenerateYearSelector } from '../../../commons/utils';
import TitleContainer from '../../../components/Title/TitleContainer';
import TitleWithIcon from '../../../components/Title/TitleWithIcon';
import useAssetTheme from '../../../stores/useAssetTheme';
//component
import JTable from '../../../components/Table/JTable';
import DropDownList from '../../../components/Selector/dropdownlist';
import SwitchSelector from '../../../components/Selector/switchSelector';
import JTooltip from '../../../components/Tooltip/JTooltip';
//icon
import { ReactComponent as BarChartIcon } from '../../../images/svg/bar_chart.svg';
import { useProvided } from '../../../stores/index';
import useAuth from '../../../stores/useAuth';

const GenerateMonthColumns = isSimulate => {
  //sub-columns
  const monthItem = [];
  for (let i = 1; i <= 12; i++) {
    const fieldName = isSimulate ? `${i}M_sim` : `${i}M`;

    monthItem.push({
      title: `${i}月`,
      field: fieldName,
      render: row => <span>{PercentFormat(row[fieldName])}</span>,
      cellStyle: {
        width: '100px'
      }
    });
  }

  //final columns
  const rtnVal = [
    {
      title: '電廠',
      field: 'pvId',
      render: row => (
        <div>
          <div>{row.pvId && row.pvId + '-'}</div>
          <div>{row.pvName}</div>
        </div>
      ),
      cellStyle: {
        width: '200px'
      }
    },
    {
      title: '資產占比',
      field: 'weight',
      render: row => <span>{PercentFormat(row.weight)}</span>,
      headerStyle: {
        paddingRight: '20px'
      },
      cellStyle: {
        width: '80px',
        paddingRight: '20px'
      }
    },
    {
      title: '年平均報酬率',
      subColumns: monthItem
    }
  ];

  return rtnVal;
};
const tableDesc = [
  { colName: '資產占比', colDesc: '投入該電廠的金額 ÷ 投入所有電廠的金額 × 100%。' },
  {
    colName: '#月',
    colDesc: '所選年份之 #月 的平均每日收益 × 365 ÷ 投資金額 × 100%。'
  }
];
const CancelFnList = [];
const PerformanceAnalysisCycle = () => {
  const { currentSPV } = useProvided(useAuth);
  const { solaTheme } = useProvided(useAssetTheme);
  //view control
  const [searchYear, setSearchYear] = useState(moment().format('YYYY'));
  const [searchSim, setSearchSim] = useState(true);
  //data
  const [yearList, setYearList] = useState([]);
  const [cycleData, setCycleData] = useState([]);
  const [summaryData, setSummaryData] = useState([]);

  const handleYearChange = event => {
    if (event.target.value !== searchYear) {
      setSearchYear(event.target.value);
    }
  };
  const handleSimChange = () => {
    setSearchSim(s => !s);
  };
  const tableColumns = useMemo(() => {
    return GenerateMonthColumns(searchSim);
  }, [searchSim]);

  const loadYearList = useCallback(() => {
    setYearList(GenerateYearSelector());
  }, []);
  const loadData = useCallback(() => {
    const source = axios.CancelToken.source();
    CancelFnList.push(source.cancel);
    axios
      .get(`/api/v1/assets/performance/cycle?year=${searchYear}`, {
        headers: { Authorization: currentSPV.token },
        cancelToken: source.token
      })
      .then(res => {
        if (res && res.data && res.data.status) {
          const cycle = res.data.result.cycle;
          const tmpSummary = {};

          tmpSummary.pvId = null;
          tmpSummary.pvName = '合計';
          tmpSummary.weight = cycle.reduce((t, item) => {
            return bn(t)
              .plus(item.weight)
              .toNumber();
          }, 0);

          for (let i = 1; i <= 12; i++) {
            const month = i + 'M';

            tmpSummary[month] = cycle.reduce((t, item) => {
              return bn(item.weight)
                .multipliedBy(item[month] || 0)
                .plus(t)
                .toNumber();
            }, 0);
            tmpSummary[month + '_sim'] = cycle.reduce((t, item) => {
              return bn(item.weight)
                .multipliedBy(item[month + '_sim'] || 0)
                .plus(t)
                .toNumber();
            }, 0);
          }

          setCycleData(cycle);
          setSummaryData([{ ...tmpSummary }]);
        } else {
          setCycleData([]);
          setSummaryData([]);
        }
      })
      .catch(err => {
        if (!axios.isCancel(err)) {
          console.error(err);
        }
      });
  }, [currentSPV, searchYear]);

  const colors = {
    conditionContainerBgColor: solaTheme.componentColors.ConditionContainerBgColor,
    hrColor: solaTheme.componentColors.TitleContainerHrColor
  };
  useEffect(() => {
    loadYearList();
    loadData();

    return () => {
      if (CancelFnList && CancelFnList.length > 0) {
        CancelFnList.forEach(fn => {
          fn();
        });
      }
    };
  }, [loadYearList, loadData]);

  return (
    <div>
      <TitleContainer>
        <TitleWithIcon title="績效分析(週期)" prefixIcon={BarChartIcon} />
      </TitleContainer>
      <ConditionContainer {...colors}>
        <JTooltip desc={tableDesc} />
        <DropDownList label="選擇年份" value={searchYear} options={yearList} changeFn={handleYearChange} />
        <SwitchSelector label="含推估收益" value={searchSim} changeFn={handleSimChange} />
      </ConditionContainer>
      <JTable columns={tableColumns} data={cycleData} summaryData={summaryData} mainWidth="1500px" />
    </div>
  );
};

const ConditionContainer = styled.div`
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 15px;
  padding-right: 7px;
  background-color: ${p => p.conditionContainerBgColor};
  border-bottom: ${p => (p.transparent ? '0px' : `1px solid ${p.hrColor}`)};
`;

export default PerformanceAnalysisCycle;
