import React, { useState, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import ToolTip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
// import Button from '@material-ui/core/Button';
// import Dialog from '@material-ui/core/Dialog';
import { IntegerFormat } from '../../commons/utils';
import EventDetailChart from './detailChart';

//
import { useProvided } from '../../stores/index';
import useAssetTheme from '../../stores/useAssetTheme';

const EventDetail = ({ dataPv, dataSimulateAndBillDetail, selectDate }) => {
  const { solaTheme } = useProvided(useAssetTheme);

  const colors = {
    panelBgColor: solaTheme.componentColors.CalendarDetailBgColor,
    itemBgColor: solaTheme.componentColors.CalendarDetailItemBgColor,
    date: solaTheme.componentColors.CalendarDayTxColor,
    pv: solaTheme.componentColors.CalendarPvEventColor,
    simulate: solaTheme.componentColors.CalendarSimulateEventColor,
    bill: solaTheme.componentColors.CalendarBillEventColor,
    title: solaTheme.componentColors.CalendarDetailTitleColor,
  };

  const [modalProps, setModalProps] = useState({ open: false, mode: '', record: null });

  const handleModalOpen = useCallback(
    (mode, record) => () => {
      setModalProps({ open: true, mode: mode, record: record });
    },
    []
  );
  const handleModalClose = useCallback(() => {
    setModalProps({ open: false, mode: '', record: null });
  }, []);

  const modalDOM = useMemo(() => {
    let dom = null;
    switch (modalProps.mode) {
      case 'chart':
        dom = (
          <EventDetailChart dataRow={modalProps.record} closeFn={handleModalClose} selectDate={selectDate} />
        );
        break;
      default:
        dom = <Button onClick={handleModalClose}>關閉</Button>;
        break;
    }
    return dom;
  }, [modalProps, handleModalClose, selectDate]);

  //
  const pvDOM = [];
  if (dataPv && dataPv.length > 0) {
    //content
    const pvInnerDOM = [];
    dataPv.forEach((pv, index) => {
      pvInnerDOM.push(
        <ToolTip
          disableFocusListener
          disableTouchListener
          title={pv.pvName}
          placement="top-start"
          key={index}
        >
          <DetailItem className="item">
            {pv.pvId}：{pv.name}
          </DetailItem>
        </ToolTip>
      );
    });

    pvDOM.push(
      <DetailTitle key="pvTitle" className="title">
        電廠事件：
      </DetailTitle>
    );
    pvDOM.push(
      <DetailPanel key="pvContent" color={colors.pv}>
        {pvInnerDOM}
      </DetailPanel>
    );
  }
  //
  const simulateDOM = [];
  if (dataSimulateAndBillDetail && dataSimulateAndBillDetail.length > 0) {
    //content
    const simulateInnerDOM = [];
    dataSimulateAndBillDetail.forEach((sim, index) => {
      simulateInnerDOM.push(
        <Grid item xs={3}>
          <DetailItem key={sim.pvId} className="item" onClick={handleModalOpen('chart', sim)} value={sim}>
            <DetailColor color={colors.date}>
              {sim.pvName}
              <br />
              {sim.pvId}
              <br />
            </DetailColor>
            <DetailColor color={colors.simulate}>
              推估收益：{sim.profit ? IntegerFormat(sim.profit, '', '元') : '--'}
              <br />
            </DetailColor>
            <DetailColor color={colors.bill}>
              實際收益：{sim.billProfit ? IntegerFormat(sim.billProfit, '', '元') : '--'}
              <br />
            </DetailColor>
            <DetailColor color={colors.date}>
              監控狀態：{sim.pvStatus === 'Y' ? '正常' : sim.pvStatus === 'N' ? '異常' : '--'}
            </DetailColor>
          </DetailItem>
        </Grid>
      );
    });

    simulateDOM.push(<DetailPanel key="simulateContent">{simulateInnerDOM}</DetailPanel>);
  }

  return (
    <DetailCotainer
      panelBgColor={colors.panelBgColor}
      itemBgColor={colors.itemBgColor}
      titleColor={colors.title}
    >
      {pvDOM}
      {simulateDOM}
      <Modal open={modalProps.open}>{modalDOM}</Modal>
    </DetailCotainer>
  );
};

const DetailCotainer = styled.div`
  width: 100%;
  background-color: ${(p) => p.panelBgColor};
  border-radius: 10px;
  padding: 20px;

  .title {
    color: ${(p) => p.titleColor};
  }
  .item {
    background-color: ${(p) => p.itemBgColor};
  }
`;
const DetailPanel = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;
const DetailColor = styled.div`
  color: ${(props) => props.color || '#fff'};
`;
const DetailTitle = styled.div``;
const DetailItem = styled.div`
  margin: 3px;
  padding: 5px 15px;
  border-radius: 10px;
  cursor: default;
`;

export default EventDetail;
