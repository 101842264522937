import React, { useState, useCallback, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { toast } from 'react-toastify';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import TitleContainer from '../../components/Title/TitleContainer';
import TitleWithIcon from '../../components/Title/TitleWithIcon';
import { NumberFormat } from '../../commons/utils';
import { ReactComponent as DocumentIcon } from '../../images/svg/document.svg';
//
import { useProvided } from '../../stores/index';
import useAuth from '../../stores/useAuth';
import useAssetTheme from '../../stores/useAssetTheme';

const CancelFnList = [];
const Account = () => {
  const { currentSPV } = useProvided(useAuth);
  const { solaTheme } = useProvided(useAssetTheme);
  //display data.
  const [accountData, setAccountData] = useState({});
  const [googleAuthData, setGoogleAuthData] = useState({});
  //input field.
  const [oldPwd, setOldPwd] = useState('');
  const [newPwd, setNewPwd] = useState('');
  const [confirmPwd, setConfirmPwd] = useState('');
  const [google2faCode, setGoogle2faCode] = useState('');
  //control view.
  const [google2faStatus, setGoogle2faStatus] = useState(null);
  const [dialogPwdOpen, setDialogPwdOpen] = useState(false);
  const [dialog2faOpen, setDialog2faOpen] = useState(false);

  const handleDialogPwdOpen = () => {
    setDialogPwdOpen(m => !m);
  };

  const handleDialog2faOpen = () => {
    setDialog2faOpen(m => !m);
  };

  const handlePwdModify = () => {
    if (!(oldPwd && newPwd && confirmPwd)) {
      toast.error('請正確輸入每個欄位！');
      return;
    }

    if (newPwd !== confirmPwd) {
      toast.warn('新密碼與確認密碼不符，請重新輸入！');
      setConfirmPwd('');
      return;
    }

    axios
      .post(
        '/api/v1/auth/modifyPwd',
        { managerId: currentSPV.managerId, oldPwd: oldPwd, newPwd: newPwd, authToken: google2faCode },
        { headers: { Authorization: currentSPV.token } }
      )
      .then(res => {
        if (res && res.data && res.data.status) {
          //修改成功
          setOldPwd('');
          setNewPwd('');
          setConfirmPwd('');
          setGoogle2faCode('');
          handleDialogPwdOpen();
        } else {
          toast.error('修改密碼失敗！');
        }
      })
      .catch(err => {
        console.error(err);
      });
  };

  const handleGoogleAuthToggle = () => {
    if (!google2faCode) {
      toast.error('請輸入 Google 動態密碼！');
      return;
    }

    const enable = !google2faStatus;
    axios
      .post(
        '/api/v1/auth/google2fa/toggle',
        { token: google2faCode, enable: enable },
        { headers: { Authorization: currentSPV.token } }
      )
      .then(res => {
        if (res && res.data && res.data.result.tokenValidates) {
          setGoogle2faStatus(enable);
          setGoogle2faCode('');
          handleDialog2faOpen();
          toast.info('驗證成功');
        } else {
          toast.info('驗證失敗，請再試一次！');
        }
      })
      .catch(err => {
        console.error(err);
      });
  };

  //load.
  const loadAccountData = useCallback(() => {
    const source = axios.CancelToken.source();
    CancelFnList.push(source.cancel);
    axios
      .get(`/api/v1/user/account`, {
        headers: { Authorization: currentSPV.token },
        cancelToken: source.token
      })
      .then(res => {
        if (res && res.data) {
          setAccountData({ ...res.data.result.account });
        }
      })
      .catch(err => {
        if (!axios.isCancel(err)) {
          console.error(err);
        }
      });
  }, [currentSPV]);
  const loadGoogle2FAStatus = useCallback(() => {
    const source = axios.CancelToken.source();
    CancelFnList.push(source.cancel);
    axios
      .get(`/api/v1/auth/google2fa/status`, {
        headers: { Authorization: currentSPV.token },
        cancelToken: source.token
      })
      .then(res => {
        if (res && res.data) {
          setGoogle2faStatus(res.data.result.enable);
        }
      })
      .catch(err => {
        if (!axios.isCancel(err)) {
          console.error(err);
        }
      });
  }, [currentSPV]);
  const loadGoogle2FA = useCallback(() => {
    const source = axios.CancelToken.source();
    CancelFnList.push(source.cancel);
    axios
      .get(`/api/v1/auth/google2fa`, {
        headers: { Authorization: currentSPV.token },
        cancelToken: source.token
      })
      .then(res => {
        if (res && res.data) {
          setGoogleAuthData({ ...res.data.result });
        }
      })
      .catch(err => {
        if (!axios.isCancel(err)) {
          console.error(err);
        }
      });
  }, [currentSPV]);

  //
  useEffect(() => {
    loadAccountData();
    loadGoogle2FA();
    loadGoogle2FAStatus();

    return () => {
      if (CancelFnList && CancelFnList.length > 0) {
        CancelFnList.forEach(fn => {
          fn();
        });
      }
    };
  }, [loadAccountData, loadGoogle2FA, loadGoogle2FAStatus]);

  const accColors = {
    bg: solaTheme.componentColors.AccountCardBgColor,
    border: solaTheme.componentColors.AccountCardBorderColor,
    label: solaTheme.componentColors.AccountCardTitleColor,
    value: solaTheme.componentColors.AccountCardTxColor
  };
  const funcColors = {
    bg: solaTheme.componentColors.AccountCardBgColor,
    border: solaTheme.componentColors.AccountCardBorderColor,
    desc: solaTheme.componentColors.AccountCardDescColor
  };
  const diagColors = {
    bg: solaTheme.componentColors.AccountDialogBgColor,
    border: solaTheme.componentColors.AccountDialogBorderColor,
    title: solaTheme.componentColors.AccountDialogTitleColor
  };
  const iconColors = {
    bg: solaTheme.componentColors.AccountDialogStepIconBgColor,
    tx: solaTheme.componentColors.AccountDialogStepIconTxColor
  };

  return (
    <PageContainer>
      <AccountPanel colors={accColors}>
        <TitleContainer hideTop>
          <TitleWithIcon title="基本資料" prefixIcon={DocumentIcon} />
        </TitleContainer>
        <div>
          {GenerateInfoList([
            { label: '帳戶名稱', value: accountData.name || '' },
            { label: '聯絡電話', value: accountData.telephone || '' },
            { label: '聯絡地址', value: accountData.address || '' },
            { label: '出金銀行帳戶', value: accountData.cashoutAccount || '' },
            { label: '已存入總金額', value: NumberFormat(accountData.totalCashIn || 0, '', '元') },
            { label: '已提領總金額', value: NumberFormat(accountData.totalCashOut || 0, '', '元') }
          ])}
        </div>
      </AccountPanel>
      <FunctionPanel colors={funcColors}>
        <FunctionCard className="card">
          <TitleContainer hideTop>
            <TitleWithIcon title="修改密碼" prefixIcon={DocumentIcon} />
          </TitleContainer>
          <FunctionDesc className="desc">該密碼將用於用戶登錄，請記住密碼。</FunctionDesc>
          <ButtonRow>
            <Button variant="contained" color="primary" size="large" fullWidth onClick={handleDialogPwdOpen}>
              修改密碼
            </Button>
          </ButtonRow>
        </FunctionCard>
        <FunctionCard className="card">
          <TitleContainer hideTop>
            <TitleWithIcon
              title={`Google Authenticator兩步驗證 (${google2faStatus ? '啟用' : '停用'})`}
              prefixIcon={DocumentIcon}
            />
          </TitleContainer>
          <FunctionDesc className="desc">
            透過Google Authenticator由行動裝置進行兩步驟驗證，將會增加您賬戶的安全性。
          </FunctionDesc>
          <ButtonRow>
            <Button variant="contained" color="primary" size="large" fullWidth onClick={handleDialog2faOpen}>
              設定 Google 2FA
            </Button>
          </ButtonRow>
        </FunctionCard>
      </FunctionPanel>
      <Dialog open={dialogPwdOpen} onClose={handleDialogPwdOpen}>
        <DialogContent colors={diagColors}>
          <DialogTite className="title">修改密碼</DialogTite>
          <TextFieldRow>
            <TextField
              type="password"
              fullWidth
              label="舊密碼"
              value={oldPwd}
              onChange={e => setOldPwd(e.target.value)}
            />
          </TextFieldRow>
          <TextFieldRow>
            <TextField
              type="password"
              fullWidth
              label="新密碼"
              value={newPwd}
              onChange={e => setNewPwd(e.target.value)}
            />
          </TextFieldRow>
          <TextFieldRow>
            <TextField
              type="password"
              fullWidth
              label="確認新密碼"
              value={confirmPwd}
              onChange={e => setConfirmPwd(e.target.value)}
            />
          </TextFieldRow>
          {google2faStatus === true ? (
            <TextFieldRow>
              <TextField
                type="text"
                fullWidth
                label="Google Auth Code"
                value={google2faCode}
                onChange={e => setGoogle2faCode(e.target.value)}
              />
            </TextFieldRow>
          ) : null}
          <ButtonRow>
            <Button variant="contained" color="primary" onClick={handlePwdModify}>
              確認
            </Button>
            <Button color="primary" onClick={handleDialogPwdOpen}>
              關閉
            </Button>
          </ButtonRow>
        </DialogContent>
      </Dialog>
      <Dialog open={dialog2faOpen} onClose={handleDialog2faOpen}>
        <DialogContent colors={diagColors}>
          <DialogTite className="title">設定Google Authenticator兩步驗證</DialogTite>
          {google2faStatus ? (
            <GoogleAuthPanel>
              <GoogleAuthDesc>輸入Google Authenticator動態密碼，關閉此功能</GoogleAuthDesc>
              <TextFieldRow>
                <TextField
                  label="Google Auth Code"
                  variant="filled"
                  type="text"
                  fullWidth
                  value={google2faCode}
                  onChange={e => setGoogle2faCode(e.target.value)}
                />
              </TextFieldRow>
            </GoogleAuthPanel>
          ) : (
            <GoogleAuthPanel colors={iconColors}>
              <GoogleAuthDesc>
                <GoogleAuthStep className="step">第一步</GoogleAuthStep>
                <div>在您的手機上安裝 Google Authenticator App.</div>
              </GoogleAuthDesc>
              <GoogleAuthDesc>
                <GoogleAuthStep className="step">第二步</GoogleAuthStep>
                <div>用 Google Authenticator App 掃描二維碼，或手動添加密文</div>
              </GoogleAuthDesc>
              {googleAuthData ? (
                <GoogleAuthImage>
                  <img alt="google_auth_qr_code" src={googleAuthData.data_url} />
                  <div>密文: {googleAuthData.google2FASecretBase32}</div>
                </GoogleAuthImage>
              ) : null}
              <GoogleAuthDesc>
                <GoogleAuthStep className="step">第三步</GoogleAuthStep>
                <div>輸入收到的動態密碼，開通兩步驗證</div>
              </GoogleAuthDesc>
              <TextFieldRow>
                <TextField
                  label="Google Auth Code"
                  variant="filled"
                  type="text"
                  fullWidth
                  value={google2faCode}
                  onChange={e => setGoogle2faCode(e.target.value)}
                />
              </TextFieldRow>
            </GoogleAuthPanel>
          )}
          <ButtonRow>
            <Button variant="contained" color="primary" onClick={handleGoogleAuthToggle}>
              確認
            </Button>
            <Button color="primary" onClick={handleDialog2faOpen}>
              關閉
            </Button>
          </ButtonRow>
        </DialogContent>
      </Dialog>
    </PageContainer>
  );
};

const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: inline-flex;
`;

const AccountPanel = styled.div`
  width: 60%;
  margin: 20px 10px 20px 20px;
  background-color: ${p => p.colors.bg};
  border: 2px solid ${p => p.colors.border};

  .label {
    color: ${p => p.colors.label};
  }
  .value {
    color: ${p => p.colors.value};
  }
  .row {
    border-bottom: 1px solid ${p => p.colors.border};
  }
`;
const InfoRow = styled.div`
  width: 90%;
  min-height: 35px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  margin: 10px auto;
`;
const GenerateInfoList = data => {
  let dom = null;
  if (data) {
    dom = [];
    data.forEach((d, index) => {
      dom.push(
        <InfoRow key={index} className="row">
          <div className="label">{d.label}</div>
          <div className="value">{d.value}</div>
        </InfoRow>
      );
    });
  }
  return dom;
};

const FunctionPanel = styled.div`
  width: 40%;
  min-height: 90%;
  height: 560px;
  max-height: 90%;
  margin: 20px 20px 20px 10px;
  overflow-y: auto;

  .card {
    background-color: ${p => p.colors.bg};
    border: 2px solid ${p => p.colors.border};
  }
  .desc {
    color: ${p => p.colors.desc};
  }
`;
const FunctionCard = styled.div`
  width: 99%;
  padding-bottom: 20px;
  margin-bottom: 20px;
`;
const FunctionDesc = styled.div`
  padding: 20px;
`;
const ButtonRow = styled.div`
  margin-top: 10px;
  padding-left: 3%;
  padding-right: 3%;
  padding-bottom: 3%;
  text-align: right;
`;
const TextFieldRow = styled.div`
  margin: 20px 0px;
`;
const DialogContent = styled.div`
  width: 50vw;
  max-width: 420px;
  padding: 20px 20px 0px 20px;
  background-color: ${p => p.colors.bg};

  .title {
    color: ${p => p.colors.title};
    border-bottom: 1px solid ${p => p.colors.border};
  }
`;
const DialogTite = styled.div`
  font-size: 1.2rem;
  padding-bottom: 5px;
`;
const GoogleAuthPanel = styled.div`
  margin-top: 20px;

  .step {
    color: ${p => p.colors.tx};
    background-color: ${p => p.colors.bg};
  }
`;
const GoogleAuthDesc = styled.div`
  font-size: 14px;
  margin-top: 20px;
  display: flex;
  align-items: flex-start;
`;
const GoogleAuthImage = styled.div`
  font-size: 14px;
  text-align: center;
  margin-top: 20px;
`;
const GoogleAuthStep = styled.div`
  min-width: 50px;
  margin-right: 5px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default Account;
