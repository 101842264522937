import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import moment from 'moment';

//component
import TitleContainer from '../../components/Title/TitleContainer';
import TitleWithIcon from '../../components/Title/TitleWithIcon';
import Calendar from '../../components/Calendar';
import DateSelector from '../../components/Selector/dateSelector';
//icon
import { ReactComponent as CalendarIcon } from '../../images/svg/calendar.svg';

import { useProvided } from '../../stores/index';
import useAuth from '../../stores/useAuth';
import useAssetTheme from '../../stores/useAssetTheme';

const getCalendarRange = (selectDate) => {
  const beginDate = moment(selectDate, 'YYYY-MM-DD').startOf('month');
  const prevMonthDayCount = beginDate.isoWeekday() - 1;
  beginDate.subtract(prevMonthDayCount, 'days');

  const endDate = moment(selectDate, 'YYYY-MM-DD').endOf('month');
  const nextMonthDayCount = 7 - endDate.isoWeekday();
  endDate.add(nextMonthDayCount, 'days');

  return { begin: beginDate.format('YYYY-MM-DD'), end: endDate.format('YYYY-MM-DD') };
};
const CancelFnList = [];
const EventCalendar = () => {
  const { currentSPV } = useProvided(useAuth);
  const { solaTheme } = useProvided(useAssetTheme);
  const [selectDate, setSelectDate] = useState(moment().format('YYYY-MM-DD'));
  const [anchorDate, setAnchorDate] = useState(moment().format('YYYY-MM-DD'));
  const [calendarRange, setCalendarRange] = useState({});
  const [dataPv, setDataPv] = useState([]);
  const [dataSimulate, setDataSimulate] = useState([]);
  const [dataSimulateAndBillDetail, setdataSimulateAndBillDetail] = useState([]);
  const [dataBill, setDataBill] = useState([]);

  const handleDateChange = (newDate) => () => {
    if (selectDate !== newDate) {
      setSelectDate(newDate);
    }
  };
  const handleDatePickerChange = (newDate) => {
    if (selectDate !== newDate) {
      setSelectDate(newDate);
      setAnchorDate(newDate);
      setCalendarRange(getCalendarRange(newDate));
    }
  };

  const loadPvData = useCallback(() => {
    const source = axios.CancelToken.source();
    CancelFnList.push(source.cancel);
    axios
      .get(`/api/v1/event/pv?begin=${calendarRange.begin}&end=${calendarRange.end}`, {
        headers: { Authorization: currentSPV.token },
        cancelToken: source.token,
      })
      .then((res) => {
        if (res && res.data) {
          setDataPv(res.data.result.events);
        }
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          console.error(err);
        }
      });
  }, [currentSPV, calendarRange]);

  const loadSimulateData = useCallback(() => {
    const source = axios.CancelToken.source();
    CancelFnList.push(source.cancel);
    axios
      .get(`/api/v1/event/simulate?begin=${calendarRange.begin}&end=${calendarRange.end}`, {
        headers: { Authorization: currentSPV.token },
        cancelToken: source.token,
      })
      .then((res) => {
        if (res && res.data) {
          setDataSimulate(res.data.result.events);
        }
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          console.error(err);
        }
      });
  }, [currentSPV, calendarRange]);

  const loadBillData = useCallback(() => {
    const source = axios.CancelToken.source();
    CancelFnList.push(source.cancel);
    axios
      .get(`/api/v1/event/bill?begin=${calendarRange.begin}&end=${calendarRange.end}`, {
        headers: { Authorization: currentSPV.token },
        cancelToken: source.token,
      })
      .then((res) => {
        if (res && res.data) {
          setDataBill(res.data.result.events);
        }
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          console.error(err);
        }
      });
  }, [currentSPV, calendarRange]);

  const loadSimulateAndBillDetailData = useCallback(() => {
    const source = axios.CancelToken.source();
    CancelFnList.push(source.cancel);
    axios
      .get(`/api/v1/event/simulateAndBillDetail?date=${selectDate}`, {
        headers: { Authorization: currentSPV.token },
        cancelToken: source.token,
      })
      .then((res) => {
        if (res && res.data) {
          setdataSimulateAndBillDetail(res.data.result.events);
        }
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          console.error(err);
        }
      });
  }, [currentSPV, selectDate]);

  useEffect(() => {
    setCalendarRange(getCalendarRange(moment().format('YYYY-MM-DD')));
  }, []);

  useEffect(() => {
    loadPvData();
    loadSimulateData();
    loadBillData();
    loadSimulateAndBillDetailData();

    return () => {
      if (CancelFnList && CancelFnList.length > 0) {
        CancelFnList.forEach((fn) => {
          fn();
        });
      }
    };
  }, [loadPvData, loadSimulateData, loadBillData, loadSimulateAndBillDetailData]);

  const colors = {
    pv: solaTheme.componentColors.CalendarPvEventColor,
    simulate: solaTheme.componentColors.CalendarSimulateEventColor,
    bill: solaTheme.componentColors.CalendarBillEventColor,
  };

  return (
    <div>
      <TitleContainer>
        <TitleWithIcon title="事件日曆" prefixIcon={CalendarIcon} />
        <HeaderDescContainer>
          <HeaderDescItem color={colors.pv}>● 電廠重要公告訊息</HeaderDescItem>
          <HeaderDescItem color={colors.simulate}>● 依據每日發電量試算</HeaderDescItem>
          <HeaderDescItem color={colors.bill}>● 依據實際電費單配發</HeaderDescItem>
        </HeaderDescContainer>
      </TitleContainer>
      <DateSelectorBlock>
        <DateSelector selectDate={selectDate} dateChangeFn={handleDatePickerChange} />
      </DateSelectorBlock>
      <Calendar
        selectDate={selectDate}
        anchorDate={anchorDate}
        rangeBeginDate={calendarRange.begin}
        rangeEndDate={calendarRange.end}
        dataPv={dataPv}
        dataSimulate={dataSimulate}
        dataBill={dataBill}
        dataSimulateAndBillDetail={dataSimulateAndBillDetail}
        dateChangeFn={handleDateChange}
      />
    </div>
  );
};

const HeaderDescContainer = styled.div`
    display: flex 
    justify-content: space-around
    align-items: center
`;
const HeaderDescItem = styled.div`
  margin: 0px 10px;
  color: ${(props) => props.color || '#fff'};
`;
const DateSelectorBlock = styled.div`
  margin-top: 15px;
`;

export default EventCalendar;
