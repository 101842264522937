import React from 'react';
import styled from 'styled-components';
//
import { useProvided } from '../../stores/index';
import useAssetTheme from '../../stores/useAssetTheme';

function Footer() {
  const { solaTheme } = useProvided(useAssetTheme);
  return (
    <FooterBar bgColor={solaTheme.componentColors.FooterBgColor}>
      <Copyright txColor={solaTheme.componentColors.FooterTxColor}>
        Copyright © SOLA Inc. All Rights Reserved
      </Copyright>
    </FooterBar>
  );
}

const FooterBar = styled.div`
  width: 100vw;
  height: 5vh;
  max-height: 55px;

  position: absolute;
  bottom: 0px;
  z-index: 1100;

  background-color: ${props => props.bgColor};

  display: flex;
  justify-content: center;
  align-items: center;
`;

const Copyright = styled.div`
  color: ${props => props.txColor};
  font-size: 13px;
  font-weight: 300;
`;

export default Footer;
